import React from 'react';
import { Card, CardBody, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Button, Alert } from "reactstrap";
import ReactDatetime from "react-datetime";
import moment from 'moment';
import './styles/ModalTaskForm.css';

export class ModalTaskForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            task: {
                ...props.initialData,
                dataInicial: props.initialData?.dataInicial ? moment(props.initialData.dataInicial) : moment(),
                dataFinal: props.initialData?.dataFinal ? moment(props.initialData.dataFinal) : moment().add(7, 'days'),
                isTemplate: props.initialData?.isTemplate || 0 
            }
        };
    }

    handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        const val = type === 'checkbox' ? (checked ? 1 : 0) : value;
        
        this.setState(prevState => ({
            task: {
                ...prevState.task,
                [name]: val
            }
        }));

        if (name === 'unit') {
            this.setState(prevState => ({
                task: {
                    ...prevState.task,
                    lesson: ''  
                }
            }));
        }
    }

    getFilteredLessons = () => {
        const { units } = this.props;
        const { task } = this.state;
        
        if (!task.unit) return [];
        
        const selectedUnit = units.find(unit => unit.id === parseInt(task.unit));
        return selectedUnit ? selectedUnit.lessons : [];
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const taskData = {
            ...this.state.task,
            isTemplate: this.state.task.isTemplate 
        };
        this.props.onSubmit(taskData);
    }

    render() {
        const { task } = this.state;
        const { units, translate = {}, error } = this.props;
        const filteredLessons = this.getFilteredLessons();
        
        return (
            <Card className="task-form-card bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                    <Form onSubmit={this.handleSubmit}>
                        {error && <Alert color="danger">{error}</Alert>}
                        
                        <FormGroup className="task-form-group">
                            <label className="task-form-label">{translate['nome'] || 'Nome'}</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-caps-small" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="text"
                                    name="name"
                                    value={task.name}
                                    onChange={this.handleChange}
                                    required
                                />
                            </InputGroup>
                        </FormGroup>

                        <div className="row">
                            <div className="col-md-6">
                                <FormGroup className="task-form-group">
                                    <label className="task-form-label">{translate['data_inicial'] || 'Data Inicial'}</label>
                                    <ReactDatetime
                                        className="task-datetime"
                                        inputProps={{ 
                                            placeholder: translate['data_inicial'],
                                            className: 'task-input'
                                        }}
                                        timeFormat={false}
                                        dateFormat="DD/MM/YYYY"
                                        value={task.dataInicial}
                                        onChange={date => this.setState(prevState => ({
                                            task: { ...prevState.task, dataInicial: moment(date) }
                                        }))}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-md-6">
                                <FormGroup className="task-form-group">
                                    <label className="task-form-label">{translate['data_final'] || 'Data Final'}</label>
                                    <ReactDatetime
                                        className="task-datetime"
                                        inputProps={{ 
                                            placeholder: translate['data_final'],
                                            className: 'task-input'
                                        }}
                                        timeFormat={false}
                                        dateFormat="DD/MM/YYYY"
                                        value={task.dataFinal}
                                        onChange={date => this.setState(prevState => ({
                                            task: { ...prevState.task, dataFinal: moment(date) }
                                        }))}
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <FormGroup className="task-form-group">
                            <label className="task-form-label">{translate['observacao'] || 'Observação'}</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-align-left-2" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="textarea"
                                    name="obs"
                                    value={task.obs}
                                    onChange={this.handleChange}
                                />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup className="task-form-group">
                            <label className="task-form-label">{translate['tipo_de_envio'] || 'Tipo de Envio'}</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-send" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="select"
                                    name="type_send"
                                    value={task.type_send}
                                    onChange={this.handleChange}
                                >
                                    <option value="link">Link</option>
                                    <option value="arquivo">Arquivo</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>

                        {/* Add Task Type Field */}
                        <FormGroup className="task-form-group">
                            <label className="task-form-label">{translate['tipo_de_tarefa'] || 'Tipo de Tarefa'}</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-collection" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="select"
                                    name="type"
                                    value={task.type}
                                    onChange={this.handleChange}
                                    required
                                >
                                    <option value="">Selecione um tipo</option>
                                    {this.props.taskTypes?.map((type) => (
                                        <option key={type.id} value={type.id}>{type.name}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup className="task-form-group">
                            <label className="task-form-label">Unit</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-building" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="select"
                                    name="unit"
                                    value={task.unit}
                                    onChange={this.handleChange}
                                >
                                    <option value="">Selecione uma unit</option>
                                    {units?.map((unit) => (
                                        <option key={unit.id} value={unit.id}>{unit.name}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>

                        <FormGroup className="task-form-group">
                            <label className="task-form-label">Lesson</label>
                            <InputGroup className="task-input-group">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText><i className="ni ni-book-bookmark" /></InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    className="task-input"
                                    type="select"
                                    name="lesson"
                                    value={task.lesson}
                                    onChange={this.handleChange}
                                    disabled={!task.unit} 
                                >
                                    <option value="">Selecione uma lesson</option>
                                    {filteredLessons.map((lesson) => (
                                        <option key={lesson.id} value={lesson.id}>{lesson.name}</option>
                                    ))}
                                </Input>
                            </InputGroup>
                        </FormGroup>

                        <div className="task-form-footer">
                            <div className="d-flex justify-content-between align-items-center">
                                <FormGroup className="template-checkbox mb-0">
                                    <div className="custom-control custom-checkbox">
                                        <Input
                                            type="checkbox"
                                            id="isTemplate"
                                            className="custom-control-input"
                                            checked={this.state.task.isTemplate === 1}
                                            onChange={(e) => this.setState(prevState => ({
                                                task: {
                                                    ...prevState.task,
                                                    isTemplate: e.target.checked ? 1 : 0
                                                }
                                            }))}
                                        />
                                        <label className="custom-control-label" htmlFor="isTemplate">
                                            {translate['salvar_como_template'] || 'Salvar como Template'}
                                        </label>
                                    </div>
                                    {this.state.task.isTemplate === 1 && (
                                        <small className="form-text text-muted mt-1">
                                            <i className="fas fa-info-circle mr-1"></i>
                                            Esta tarefa será salva como template
                                        </small>
                                    )}
                                </FormGroup>
                                
                                <div className="task-buttons-container">
                                    <Button 
                                        className="task-button mr-2" 
                                        color="secondary" 
                                        onClick={this.props.onCancel}
                                        type="button"
                                    >
                                        {translate['cancelar'] || 'Cancelar'}
                                    </Button>
                                    <Button 
                                        className="task-button" 
                                        color="primary" 
                                        type="submit"
                                    >
                                        {this.props.isEditing ? 
                                            (translate['atualizar_task'] || 'Atualizar Tarefa') : 
                                            (translate['criar_task'] || 'Criar Tarefa')
                                        }
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </Form>
                </CardBody>
            </Card>
        );
    }
}

export default ModalTaskForm;