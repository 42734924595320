import React from "react";
import { Button } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";

export class ModalTaskDelete extends React.Component {
    render() {
        const { isOpen, onClose, onConfirm } = this.props;

        return (
            <SlidingPane
                className="some-custom-class"
                overlayClassName="some-custom-overlay-class"
                isOpen={isOpen}
                title="Confirmação de Exclusão"
                width="400px"
                from="right"
                onRequestClose={onClose}
            >
                <div className="p-4">
                    <div className="text-center">
                        <i className="fas fa-exclamation-triangle fa-3x text-danger mb-3"></i>
                        <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
                        <p>Se você prosseguir, todos os dados da tarefa serão removidos</p>
                        <div className="mt-4">
                            <Button 
                                className="btn-white mr-2" 
                                color="default" 
                                type="button" 
                                onClick={onConfirm}
                            >
                                Ok, Remova
                            </Button>
                            <Button 
                                className="btn-white mr-2" 
                                color="default" 
                                type="button" 
                                onClick={onClose}
                            >
                                Cancelar
                            </Button>
                        </div>
                    </div>
                </div>
            </SlidingPane>
        );
    }
}

export default ModalTaskDelete;