import React from 'react';
import { Form, FormGroup, Input, Button, Label, Row, Col } from 'reactstrap';
import InputMask from 'react-input-mask';

export const ModalDetailedTimeForm = ({ 
    isEdit, 
    value,
    date = '',
    dateTime = '',  
    onChange,
    onDateChange,
    onDateTimeChange, 
    onSubmit, 
    onCancel, 
    loading
}) => (
    <div className="p-4">
        <div className="text-center">
            <i className={`fas ${isEdit ? 'fa-edit' : 'fa-plus-circle'} fa-3x text-primary mb-3`}></i>
            <h4 className="heading mt-4">
                {isEdit ? 'Editar Registro de Tempo' : 'Novo Registro de Tempo'}
            </h4>
            <Form className="mt-4">
                <FormGroup>
                    <Label>Data e Horário</Label>           
                    <Row className="mb-3">
                        <Col xs="6">
                            <InputMask 
                                type="text" 
                                placeholder="DD/MM/YYYY" 
                                value={date} 
                                onChange={onDateChange} 
                                className="form-control text-center" 
                                mask="99/99/9999"
                                autoFocus
                            />
                        </Col>
                        <Col xs="6">
                            <InputMask 
                                type="text" 
                                placeholder="HH:mm" 
                                value={dateTime} 
                                onChange={onDateTimeChange} 
                                className="form-control text-center" 
                                mask="99:99"
                            />
                        </Col>
                    </Row>
                    <Label>Tempo de Estudo</Label>
                    <InputMask 
                        type="text" 
                        placeholder="HH:mm:ss" 
                        value={value} 
                        onChange={onChange} 
                        className="form-control text-center" 
                        mask="99:99:99"
                    />
                </FormGroup>
            </Form>
            <div className="mt-4">
                <Button
                    className="btn-white mr-2"
                    color="primary"
                    onClick={onSubmit}
                    disabled={loading}
                >
                    {loading ? 
                        (isEdit ? 'Salvando...' : 'Adicionando...') : 
                        (isEdit ? 'Salvar' : 'Adicionar')
                    }
                </Button>
                <Button 
                    className="btn-white" 
                    color="secondary" 
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
            </div>
        </div>
    </div>
);