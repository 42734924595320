import React from "react";

//import moment from "moment";
//import $ from 'jquery';

import {Container,Card,CardHeader,Row,Col,FormGroup,InputGroup,InputGroupAddon,InputGroupText,Input,Button} from "reactstrap";
import $ from 'jquery';
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import api from "./../../Api.js";
import Detailed from "./dashboard_classroom/detailed/Detailed.jsx";
import Minimal from "./dashboard_classroom/Minimal.jsx";
import ProgressUnit from "./dashboard_classroom/ProgressUnit.jsx";
import ModalBasicChartUser from "./modal/ModalBasicChartUser.jsx";

import { connect } from 'react-redux';


class Dashboard_classroom extends React.Component {

	constructor(props){

		super(props);
		this.state = {typeView:1,loadClassRoom:false,classRoom:{},loadProgress:false,openTimes:[],units:[],unitProgress:"",userPosition:[]};
		this.openUser = this.openUser.bind(this);
		this.closeUser = this.closeUser.bind(this);
		this.maxArrayReturn = this.maxArrayReturn.bind(this);
		this.getUnitsCourse = this.getUnitsCourse.bind(this);
		this.getDataClass = this.getDataClass.bind(this);
		this.postProgress = this.postProgress.bind(this);
		//this.simulatePush = this.simulatePush.bind(this);

		this.nextUserChart = this.nextUserChart.bind(this);
		this.previousUserChart = this.previousUserChart.bind(this);

		this.altDefault = 55;
		this.altMultiplayer = 26;

		window.dashboard_classroom = this;

	}

	//Par abrir a aba de um usuario
	openUser(userId){

		var openTimes = this.state.openTimes;
		openTimes[userId] = true;
		this.setState({openTimes:openTimes});

	}

	//Para fechar a aba de um usuario
	closeUser(userId){

		var openTimes = this.state.openTimes;
		openTimes[userId] = false;
		this.setState({openTimes:openTimes});

	}

	//Para pegar e retornar a a lesson que ele mais tem
	maxArrayReturn(userID){

		var users = this.state.progress.users;
		var user = users.filter(function(u){ return parseInt(u.id) === parseInt(userID); })[0];
		var maxLessons = 0;

		for(var i = 0; i < user.progress.length; i++){

			if(user.progress[i] !== null){

				if(user.progress[i].length > maxLessons){ maxLessons = user.progress[i].length; }

			}

		}

		return maxLessons;

	}

	//Para pegar os dados de progresso de uma unit
	async getDataProgress(unit,all){

		await this.setState({loadProgres:false});
		
		//console.log('getDataProgress: '+unit);
		if(unit === undefined){ unit = this.props.match.params.unit; }
		if(all === undefined){ all = ""; }else{ all = "&all=1"; }

		//this.props.match.params.classroom,unit,all
		var a = await api.getProgressUnitClassroom(this.props.match.params.classroom,unit,all).then((res) => {
 
	        var obj = {};
	        obj.unit = res.data.unit;
	        obj.users = res.data.users;
	        obj.lessons = res.data.lessons;
	        obj.lessonsAll = res.data.lessonsAll;

	       	this.setState({unitProgress:unit,progress:obj,loadProgres:true});

	       //	console.log(obj);

	    }).catch(error => { 

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response); 
	        return false; 

	    });

	    return a;

	}

	//Para pegar o progresso em todas as units
	async getProgressAllUnits(){

		await this.setState({loadProgres:false});

		//this.props.match.params.classroom,unit,all
		var a = await api.getProgressAllUnits(this.props.match.params.classroom).then((res) => {
 
			this.setState({progressAllUnits:res.data.progress,loadProgres:true});
 
	    }).catch(error => { 

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response); 
	        return false; 

	    });

	    return a;

	}

	//Para pegar os dados da turma
	async getDataClass(){

		var a = await api.getClassRoom(this.props.match.params.classroom).then(async (res) => {

			await this.setState({classRoom:res.data,loadClassRoom:true});
	        return true;

	    }).catch(error => { 

	        console.log(error.data);
	        console.log(error);
	        console.log(error.response); 
	        return false; 

	    });

	    return a;

	}

	//Para pegar as units do curso
	async getUnitsCourse(){

		var units = await this.props.getUnitsCourse(this.state.classRoom.course);
		//console.log(units);
		this.setState({units:units});

		return true;

	}

	componentDidUpdate(){}

	//Para verificar o progresso do usuario na unit
	progressUnitUser(user,unit){

		//primeiro busco a unit
		var unitPos = this.state.units.map(function(x){ return x.id; }).indexOf(unit);
		unit = this.state.units[unitPos];

		var userPoss = this.state.progress.users.map(function(x){ return x.id; }).indexOf(user);
		user = this.state.progress.users[userPoss];
 
		//busco todas as lessons dessa unit
		var lessonsAll = [];

		for (var i = 0; i < this.state.progress.lessonsAll.length; i++){
			
			if(parseInt(this.state.progress.lessonsAll[i].unit) === parseInt(unit.id)){

				lessonsAll.push(this.state.progress.lessonsAll[i]);

			}

		}

		var totalLessons = lessonsAll.length;
		var progressUser = 0;

		//verifico se ele tem pelo menos um progresso em todas as units de cima
		for (i = 0; i < lessonsAll.length; i++){

			 if(user.progress[lessonsAll[i].id].length > 0){ progressUser++; }

		}

		//console.log('progressUser:'+progressUser+' totalLessons:'+totalLessons);
		//console.log(lessonsAll);

		var calculo = (progressUser * 100) / totalLessons;
		if(isNaN(calculo)){ calculo = 0; }

		return calculo;

	}

	componentWillUnmount(){

		window.classroomdash = false;
		window.socket.off('postProgress');
		window.socket.off('infoPosition_dash_board');

	}

	//Para postar o progresso inviado pelo socket io
	postProgress(data){

		//console.log(data);

		if(parseInt(data.classRoom) !== parseInt(this.props.match.params.classroom)){ return false; }

		var progress = this.state.progress;
		var elementPos = progress.users.map(function(x) {return x.id; }).indexOf(data.user);
 		progress.users[elementPos].progress[data.lesson].push(data);

 		$('.rrasta[user='+data.user+'][lesson='+data.lesson+']').removeClass('flash');
 		setTimeout(function(){ $('.rrasta[user='+data.user+'][lesson='+data.lesson+']').addClass('flash') }, 100);
 		this.setState({progress:progress});

	}

	async componentDidMount(){

		//console.log('deu did mount');

		//Pega os dados da turma
		await this.getDataClass();

		//Pega todas as units desse curso
		await this.getUnitsCourse();

		//Verifica se é none, se form, acessa a primeira unit
		if(this.props.match.params.unit === "none"){

			this.props.history.push('/classroom/dashboard/'+this.props.match.params.classroom+'/'+this.state.units[0].id)
 			await this.getDataProgress(this.state.units[0].id);

		}else{

			await this.getDataProgress();

		}

	
		if(window.socket.io.connecting[0]._callbacks['$postProgress'] === undefined){}

		window.socket.on('postProgress', function (data){

			this.postProgress(data);

		}.bind(this));

		

		if(window.socket.io.connecting[0]._callbacks['$infoPosition_dash_board'] === undefined){}

			window.socket.on('infoPosition_dash_board', function (data){
 				
 				var userPosition;

				if(this.state.userPosition[data.user] === undefined){

					userPosition = this.state.userPosition;
					userPosition[data.user] = data.pathname;
					this.setState({userPosition:userPosition});

				}else{

					if(this.state.userPosition[data.user] !== data.pathname){

						userPosition = this.state.userPosition;
						userPosition[data.user] = data.pathname;
						this.setState({userPosition:userPosition});

					}

				}

			}.bind(this));

	}

	//Para abrir o mini modal de chart do usuario
	openChartUser(user){

		this.setState({photoUserChart:user.photo,openModalChartUser:true,nameUserChart:user.name+' '+user.lastName,idUserChart:user.id});

	}

	nextUserChart(){

		var index = this.state.progress.users.map(function(x) {return x.id; }).indexOf(this.state.idUserChart);
		var user;

		if(this.state.progress.users[(index+1)] === undefined){ 

			user = this.state.progress.users[0];

		}else{ user = this.state.progress.users[(index+1)]; }
		
		this.openChartUser(user)
		//this.setState({openModalChartUser:true,nameUserChart:user.name+' '+user.lastName,idUserChart:user.id});

	}

	previousUserChart(){

		var index = this.state.progress.users.map(function(x) {return x.id; }).indexOf(this.state.idUserChart);
		var user;

		if(this.state.progress.users[(index-1)] === undefined){ 

			user = this.state.progress.users[ (this.state.progress.users.length - 1) ];

		}else{ user = this.state.progress.users[(index-1)]; }
		
		this.openChartUser(user);
		//this.setState({openModalChartUser:true,nameUserChart:user.name+' '+user.lastName,idUserChart:user.id});


	}
	 
	render(){

		var stySelect = {};
		if(this.state.typeView === 3){ stySelect.display = "none"; }

		const buttonHeader = <React.Fragment>
				
			<Button onClick={ () =>  this.setState({typeView:1}) } className="btn-neutral" color="default" data-calendar-view="basicWeek" size="sm" > 
			{ 
				(parseInt(this.state.typeView) !== 1) && 
				<i className="far fa-square"></i> 

			}
			{ 
				(parseInt(this.state.typeView) === 1) && 
				<i className="far fa-check-square"></i> 
			}
			<font> {this.props.translate['detalhada']}</font></Button>
	    	<Button onClick={ () =>  this.setState({typeView:2}) } className="btn-neutral" color="default" data-calendar-view="basicWeek" size="sm" >
	    	{ 
				(parseInt(this.state.typeView) !== 2) && 
				<i className="far fa-square"></i> 

			}
			{ 
				(parseInt(this.state.typeView) === 2) && 
				<i className="far fa-check-square"></i> 
			}
			<font> {this.props.translate['simplificada']}</font>
	    	</Button>

	    	<Button onClick={ () =>  { this.setState({typeView:3}); this.getProgressAllUnits(); } } className="btn-neutral" color="default" data-calendar-view="basicWeek" size="sm" >
	    	{ 
				(parseInt(this.state.typeView) !== 3) && 
				<i className="far fa-square"></i> 

			}
			{ 
				(parseInt(this.state.typeView) === 3) && 
				<i className="far fa-check-square"></i> 
			}
			<font> {this.props.translate['progresso_nas_units']}</font>
	    	</Button>

		</React.Fragment>;
  	
		return(<React.Fragment> 
	    <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
	    
	    <SimpleHeader name={this.props.translate['progresso_da_turma']} way={[{name:this.props.translate['turmas'],url:"/classroom"},{name:this.state.classRoom.name,url:this.props.match.url}]} buttons={buttonHeader} />
	    
	    <Container className="mt--6" fluid>
		      	<Card className="bg-secondary">
		        <CardHeader className="border-0">
		          <Row>
		            <Col xs="6"><h3 className="mb-0">Dashboard</h3></Col>
		            <Col className="text-right" xs="6" style={stySelect}>
		             	<FormGroup style={{width:'250px',float:'right',marginBottom:'0px'}}>	                    
		             		<InputGroup className="input-group-merge input-group-alternative">
								<InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-badge" /></InputGroupText></InputGroupAddon>
								<Input id="nivelAcesso" type="select" onChange={ (event) => {this.props.history.push('/classroom/dashboard/'+this.props.match.params.classroom+'/'+event.target.value); this.getDataProgress(event.target.value); } } value={ this.props.match.params.unit }>
								{
									this.state.units.map((value,index) => {
										return(<option key={value.id} value={value.id}>{value.name}</option>)
									})
								}
								</Input>
		                    </InputGroup>
		              	</FormGroup>
		            </Col>
		          </Row>
		        </CardHeader>
		        {
		        	(this.state.typeView === 1) &&
		        	<Detailed openChartUser={ (user) => this.openChartUser(user) } unit={this.props.match.params.unit} state={this.state} maxArrayReturn={ (id) => this.maxArrayReturn(id) } openUser={ (e) => this.openUser(e) } closeUser={ (e) => this.closeUser(e) }/>

		        }
		        {

		        	(this.state.typeView === 2) &&
		        	<Minimal openChartUser={ (user) => this.openChartUser(user) } unit={this.props.match.params.unit} state={this.state} maxArrayReturn={ (id) => this.maxArrayReturn(id) } openUser={ (e) => this.openUser(e) } closeUser={ (e) => this.closeUser(e) }/>

		        }
		        {

		        	(this.state.typeView === 3) &&
		        	<ProgressUnit openChartUser={ (user) => this.openChartUser(user) } unit={this.props.match.params.unit} progressUnitUser={this.progressUnitUser} state={this.state} maxArrayReturn={ (id) => this.maxArrayReturn(id) } openUser={ (e) => this.openUser(e) } closeUser={ (e) => this.closeUser(e) }/>

		        }
   			</Card>
	    </Container>

		<ModalBasicChartUser classroom={this.props.match.params.classroom} unit={this.state.unitProgress} photoUserChart={this.state.photoUserChart} nextUserChart={ () => this.nextUserChart() } previousUserChart={ () => this.previousUserChart() } name={this.state.nameUserChart} idUser={this.state.idUserChart} state={this.state} setState={ (data) => this.setState(data) } /> 

		</React.Fragment>);

	}

}

 
export default connect(state =>({

	languages: state.language,
	translate: state.translate,
	accesslevel: state.accesslevel,
	permission: state.permission,
	user: state.user,
  
}))(Dashboard_classroom);