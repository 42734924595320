import React from 'react';
import { connect } from 'react-redux';
import {
    Button,
    Table,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Form,
    FormGroup,
    Label,
    Input,
    Alert,
    UncontrolledTooltip,
    Card,
    CardHeader,
    Row,
    Col,
    Container
} from 'reactstrap';
import ClipLoader from 'react-spinners/ClipLoader';
import api from '../../Api';
import NotificationAlert from "react-notification-alert";
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";

class TaskTypes extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            taskTypes: [],
            dialog: { alias: "", name: "" },
            error: "",
            openDialogCreate: false,
            loading: false,
            openDialogRemoveTaskType: false,
            removeTypeId: null,
            editId: null,
        };
        this.getAllTypeTasks = this.getAllTypeTasks.bind(this);
        this.toggleDialogCreate = this.toggleDialogCreate.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.editTypeTask = this.editTypeTask.bind(this);
        this.confirmDeleteTypeTask = this.confirmDeleteTypeTask.bind(this);
        this.deleteTypeTaskConfirmed = this.deleteTypeTaskConfirmed.bind(this);
    }

    async componentDidMount() {
        await this.getAllTypeTasks();
    }

    async getAllTypeTasks() {
        try {
            const response = await api.getAllTypeTasks();
            this.setState({ taskTypes: response.data });
        } catch (error) {
            this.setState({ error: "Erro ao carregar os tipos de tarefa." });
        }
    }

    toggleDialogCreate() {
        this.setState(prevState => ({
            openDialogCreate: !prevState.openDialogCreate,
            dialog: { alias: "", name: "" },
            error: "",
            editId: null,
        }));
    }

    handleChange(e) {
        const { name, value } = e.target;
        this.setState(prevState => ({
            dialog: {
                ...prevState.dialog,
                [name]: value
            }
        }));
    }

    async handleSubmit(e) {
        e.preventDefault();
        this.setState({ loading: true });

        const { editId, dialog } = this.state;

        try {
            if (editId) {
                // Atualiza um tipo de task existente
                const response = await api.updateTypeTask(editId, dialog);
                this.setState(prevState => ({
                    taskTypes: prevState.taskTypes.map(type =>
                        type.id === editId ? response.data.taskType : type
                    ),
                    openDialogCreate: false,
                    loading: false,
                    dialog: { alias: "", name: "" },
                    editId: null,
                    error: ""
                }), () => {
                    this.showNotification('success', 'Tipo de tarefa atualizado com sucesso.');
                });
            } else {
                // Cria um novo tipo de task
                const response = await api.createTypeTask(dialog);
                this.setState(prevState => ({
                    taskTypes: [...prevState.taskTypes, response.data.taskType],
                    openDialogCreate: false,
                    loading: false,
                    dialog: { alias: "", name: "" },
                    error: ""
                }), () => {
                    this.showNotification('success', 'Tipo de tarefa criado com sucesso.');
                });
            }
        } catch (error) {
            const errorMessage = editId
                ? "Erro ao atualizar o tipo de tarefa."
                : "Erro ao criar o tipo de tarefa.";
            this.setState({ error: errorMessage, loading: false }, () => {
                this.showNotification('danger', errorMessage);
            });
        }
    }

    editTypeTask(id) {
        const taskType = this.state.taskTypes.find(type => type.id === id);
        if (taskType) {
            this.setState({
                dialog: { alias: taskType.alias, name: taskType.name },
                openDialogCreate: true,
                editId: id,
                error: ""
            });
        }
    }

    confirmDeleteTypeTask(id) {
        this.setState({
            openDialogRemoveTaskType: true,
            removeTypeId: id
        });
    }

    async deleteTypeTaskConfirmed() {
        const { removeTypeId } = this.state;
        if (removeTypeId !== null) {
            try {
                await api.deleteTypeTask(removeTypeId);
                this.setState(prevState => ({
                    taskTypes: prevState.taskTypes.filter(type => type.id !== removeTypeId),
                    openDialogRemoveTaskType: false,
                    removeTypeId: null,
                    error: ""
                }), () => {
                    this.showNotification('success', 'Tipo de tarefa removido com sucesso.');
                });
            } catch (error) {
                this.setState({ error: "Erro ao remover o tipo de tarefa.", openDialogRemoveTaskType: false, removeTypeId: null }, () => {
                    this.showNotification('danger', 'Erro ao remover o tipo de tarefa.');
                });
            }
        }
    }

    showNotification(type, message) {
        let options = {
            place: "tr",
            message: (
                <div>
                    <div className="alert-text">
                        <span className="alert-title" data-notify="title">
                            {type === 'success' ? 'Sucesso!' : 'Erro!'}
                        </span>
                        <span data-notify="message">{message}</span>
                    </div>
                </div>
            ),
            type: type,
            icon: type === 'success' ? "ni ni-check-bold" : "ni ni-fat-remove",
            autoDismiss: 7
        };
        this.refs.notificationAlert.notificationAlert(options);
    }

    render() {
        const { translate, permission } = this.props;
        const { taskTypes, dialog, error, openDialogCreate, openDialogRemoveTaskType, removeTypeId, loading } = this.state;

        return (
            <React.Fragment>
                <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
                <SimpleHeader
                    name={translate['tipos_de_tarefa']}
                    way={[{ name: translate['tipos_de_tarefa'], url: "/tasktypes" }]}
                    buttons={<React.Fragment></React.Fragment>}
                />
                <Container className="mt--6" fluid>
                    <Card>
                        <CardHeader className="border-0">
                            <Row>
                                <Col xs="6">
                                    <h3 className="mb-0">{translate['lista_de_tipos_de_tarefa']}</h3>
                                </Col>
                                {
                                    (permission && permission.indexOf(26) !== -1) &&
                                    <React.Fragment>
                                        <Col className="text-right" xs="6">
                                            <Button
                                                className="btn-neutral btn-round btn-icon"
                                                color="default"
                                                onClick={this.toggleDialogCreate}
                                                size="sm"
                                            >
                                                <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span>
                                                <span className="btn-inner--text">{translate['criar_tipo_de_tarefa']}</span>
                                            </Button>
                                        </Col>
                                    </React.Fragment>
                                }
                            </Row>
                        </CardHeader>

                        {error && <Alert color="danger" className="m-3">{error}</Alert>}

                        <Table className="align-items-center table-flush" responsive>
                            <thead className="thead-light">
                                <tr>
                                    <th>ID</th>
                                    <th>Alias</th>
                                    <th>Name</th>
                                    <th>{translate['acoes']}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    taskTypes.map((type) => {
                                        const editKey = `edit_key_${type.id}`;
                                        const deleteKey = `delete_key_${type.id}`;
                                        return (
                                            <tr key={type.id}>
                                                <td>{type.id}</td>
                                                <td>{type.alias}</td>
                                                <td>{type.name}</td>
                                                <td className="table-actions">
                                                    <font
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => this.editTypeTask(type.id)}
                                                        className="table-action"
                                                        id={editKey}
                                                    >
                                                        <i className="far fa-edit"></i>
                                                    </font>
                                                    <UncontrolledTooltip delay={0} target={editKey}>
                                                        {translate['editar_tipo_de_tarefa']}
                                                    </UncontrolledTooltip>

                                                    <font
                                                        style={{ cursor: "pointer", marginLeft: '10px' }}
                                                        onClick={() => this.confirmDeleteTypeTask(type.id)}
                                                        className="table-action"
                                                        id={deleteKey}
                                                    >
                                                        <i className="far fa-trash-alt"></i>
                                                    </font>
                                                    <UncontrolledTooltip delay={0} target={deleteKey}>
                                                        {translate['remover_tipo_de_tarefa']}
                                                    </UncontrolledTooltip>
                                                </td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                        {
                            (taskTypes.length === 0) &&
                            <center><h3 style={{ margin: '30px 0px' }}>{translate['nenhum_tipo_de_tarefa_foi_encontrado']}</h3></center>
                        }
                    </Card>
                </Container>

                <Modal
                    className="modal-dialog-centered modal-danger"
                    contentClassName="bg-gradient-danger"
                    isOpen={openDialogRemoveTaskType}
                    toggle={() => this.setState({ openDialogRemoveTaskType: false, removeTypeId: null })}
                >
                    <div className="modal-header">
                        <h6 className="modal-title" id="modal-title-notification">{translate['sua_atencao_e_requirida']}</h6>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({ openDialogRemoveTaskType: false, removeTypeId: null })}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <div className="py-3 text-center">
                            <i className="fas fa-exclamation-triangle fa-x3" style={{ fontSize: '45px' }}></i>
                            <h4 className="heading mt-4">{translate['voce_deve_ler_isto']}</h4>
                            <p>{translate['se_voce_prosseguir_todos_os_dados_do_tipo_de_tarefa_serao_removidos_essa_acao_e_irreversivel']}.</p>
                        </div>
                    </div>
                    <div className="modal-footer">
                        <Button
                            className="btn-white"
                            color="default"
                            type="button"
                            onClick={this.deleteTypeTaskConfirmed}
                        >
                            {translate['ok_remova']}
                        </Button>
                        <Button
                            className="text-white ml-auto"
                            color="link"
                            data-dismiss="modal"
                            type="button"
                            onClick={() => this.setState({ openDialogRemoveTaskType: false, removeTypeId: null })}
                        >
                            {translate['cancelar_e_fechar']}
                        </Button>
                    </div>
                </Modal>

                <Modal
                    isOpen={openDialogCreate}
                    toggle={this.toggleDialogCreate}
                    className="modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            {this.state.editId ? translate['editar_tipo_de_tarefa'] : translate['criar_tipo_de_tarefa']}
                        </h5>
                        <button
                            aria-label="Close"
                            className="close"
                            data-dismiss="modal"
                            type="button"
                            onClick={this.toggleDialogCreate}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <ModalBody>
                        <Form onSubmit={this.handleSubmit}>
                            {error && <Alert color="danger">{error}</Alert>}
                            <FormGroup>
                                <Label for="alias">{translate['alias']}</Label>
                                <Input
                                    type="text"
                                    name="alias"
                                    id="alias"
                                    value={dialog.alias}
                                    onChange={this.handleChange}
                                    required
                                    className="rounded"
                                    placeholder={translate['insira_alias']}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label for="name">{translate['nome']}</Label>
                                <Input
                                    type="text"
                                    name="name"
                                    id="name"
                                    value={dialog.name}
                                    onChange={this.handleChange}
                                    required
                                    className="rounded"
                                    placeholder={translate['insira_nome']}
                                />
                            </FormGroup>
                            <div className="text-center">
                                <Button
                                    color="primary"
                                    type="submit"
                                    disabled={loading}
                                    className="rounded"
                                >
                                    {translate['salvar']}
                                </Button>{' '}
                                <Button
                                    color="secondary"
                                    onClick={this.toggleDialogCreate}
                                    className="rounded"
                                >
                                    {translate['cancelar']}
                                </Button>
                            </div>
                        </Form>
                    </ModalBody>
                </Modal>
            </React.Fragment>
        );
    }
}

    export default connect(state => ({
        translate: state.translate,
        permission: state.permission,
    }))(TaskTypes);
