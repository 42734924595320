import React from "react";
import { Button } from "reactstrap";
import api from "../Api";


class GoogleLoginButton extends React.Component {
    handleGoogleLogin = () => {
        const clientId = process.env.REACT_APP_GOOGLE_CLIENT_ID;
        const redirectUri = window.location.origin;
        
        const googleAuthUrl = `https://accounts.google.com/o/oauth2/v2/auth?` + 
            `scope=openid%20profile%20email` +
            `&access_type=offline` +
            `&prompt=consent` +
            `&include_granted_scopes=true` +
            `&response_type=code` +
            `&state=google-login` +
            `&redirect_uri=${encodeURIComponent(redirectUri)}` +
            `&client_id=${clientId}`;

        // Configurações da janela popup
        const width = 500;
        const height = 600;
        const left = window.screenX + (window.outerWidth - width) / 2;
        const top = window.screenY + (window.outerHeight - height) / 2.5;
        const popup = window.open(
            googleAuthUrl,
            'GoogleLogin',
            `width=${width},height=${height},left=${left},top=${top}`
        );

        // Adiciona listener para mensagem da janela popup
        window.addEventListener('message', this.handlePopupMessage);

        // Verifica se a janela foi fechada
        const checkPopup = setInterval(() => {
            if (popup.closed) {
                clearInterval(checkPopup);
                window.removeEventListener('message', this.handlePopupMessage);
            }
        }, 1000);
    };

    handlePopupMessage = async (event) => {
        if (event.origin !== window.location.origin) return;

        const { code } = event.data;
        if (code) {
            try {
                console.log('Código recebido:', code);
                
                const response = await api.loginGoogle({ 
                    token: code
                });
                
                if (response.data.success) {
                    this.props.loginSuccess(response.data.session, response.data.user);
                } else {
                    window.swal.fire({
                        title: 'Erro no Login',
                        text: response.data.msg || 'Não foi possível fazer login com esta conta Google. Verifique se o email está cadastrado no sistema.',
                        icon: 'error',
                        confirmButtonText: 'Ok'
                    });
                    console.error('Erro no login:', response.data.msg);
                }
            } catch (error) {
                console.error('Erro completo:', error);
                
                let errorMessage = 'Ocorreu um erro ao tentar fazer login com o Google.';
                if (error.response?.data?.msg === 'Email not found') {
                    errorMessage = 'Este email não está cadastrado no sistema. Por favor, utilize um email registrado.';
                }

                window.swal.fire({
                    title: 'Erro no Login',
                    text: errorMessage,
                    icon: 'error',
                    confirmButtonText: 'Ok'
                });
                
                console.error('Erro no login com Google:', error.response?.data?.msg || error.message);
            }
        } else {
            window.swal.fire({
                title: 'Erro no Login',
                text: 'Não foi possível obter as informações do Google. Tente novamente.',
                icon: 'error',
                confirmButtonText: 'Ok'
            });
            console.error('Nenhum código recebido do Google');
        }
    };

    componentDidMount() {
        // Verifica se há um código do Google na URL
        const urlParams = new URLSearchParams(window.location.search);
        const code = urlParams.get('code');
        if (code) {
            // Se estiver na janela popup, envia mensagem para a janela principal
            if (window.opener) {
                window.opener.postMessage({ code }, window.location.origin);
                window.close();
            }
        }
    }

    render() {
        return (
            <Button
                className="btn-neutral btn-icon"
                color="default"
                onClick={this.handleGoogleLogin}
            >
                <span className="btn-inner--icon">
                    <img 
                        alt="Google"
                        src="icons/google.svg"
                        style={{ width: '20px' }}
                    />
                </span>
                <span className="btn-inner--text">Google</span>
            </Button>
        );
    }
}

export default GoogleLoginButton; 