import React from 'react';
import { Button } from 'reactstrap';

export const ModalDetailedDeleteTime = ({ onConfirm, onCancel, loading }) => (
    <div className="p-4">
        <div className="text-center">
            <i className="fas fa-exclamation-triangle fa-3x text-danger mb-3"></i>
            <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
            <p>Se você prosseguir, o registro de tempo será removido</p>
            <div className="mt-4 d-flex justify-content-center">
                <Button 
                    className="btn-white mr-2" 
                    color="default" 
                    type="button" 
                    onClick={onConfirm}
                    disabled={loading}
                >
                    {loading ? 'Excluindo...' : 'Ok, Remova'}
                </Button>
                <Button 
                    className="btn-white" 
                    color="default" 
                    type="button" 
                    onClick={onCancel}
                >
                    Cancelar
                </Button>
            </div>
        </div>
    </div>
);