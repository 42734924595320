import React from 'react';
import moment from 'moment';
import { Badge, Button, Col } from 'reactstrap';

export const ModalTaskCard = ({
    task,
    isTeacher,
    showAllInfo,
    units,
    lessons,
    taskTypes,
    userTasks,
    onEdit,
    onDelete,
    onDeliveries,
    onDeliver,
    onOpen,
    deliveryCounts,
    pendingCounts,
    translate
}) => {
    const typeName = taskTypes?.find(type => type.id === parseInt(task.type))?.name || '';
    const isDelivered = userTasks?.some(userTask => userTask.task === task.id);
    const isOverdue = moment().isAfter(task.dataFinal);

    return (
        <div className="col-md-6 col-lg-4 mb-4">
            <div className={`card h-100 border-0 shadow-lg ${
                isDelivered ? 'border-left border-success' : 
                isOverdue ? 'border-left border-danger' : 
                'border-left border-primary'
            }`}
            style={{ borderLeftWidth: '4px' }}>
                <div className="card-body">
                    <div className="d-flex justify-content-between align-items-center mb-3">
                        <h5 className="card-title mb-0">{task.name}</h5>
                        {!isTeacher ? (
                            <span className={`badge badge-${
                                isDelivered ? 'success' :
                                isOverdue ? 'danger' : 'warning'
                            }`}>
                                {isDelivered ? 'Entregue' : 'Pendente'}
                            </span>
                        ) : (
                            <div className="d-flex align-items-center">
                                <span className="badge badge-primary mr-2">
                                    <i className="fas fa-users mr-1"></i>
                                    {deliveryCounts[task.id] || 0} entregas
                                </span>
                                {pendingCounts[task.id] > 0 && (
                                    <span className="badge badge-warning">
                                        <i className="fas fa-clock mr-1"></i>
                                        {pendingCounts[task.id]} 
                                    </span>
                                )}
                            </div>
                        )}
                    </div>

                    <div className="small text-muted mb-3">
                        <i className="ni ni-collection mr-2"></i>{typeName}
                    </div>

                    {showAllInfo && (
                        <div className="mb-3">
                            <div className="small text-muted">
                                <i className="ni ni-calendar-grid-58 mr-2"></i>
                                {moment(task.dataInicial).format('DD/MM/YYYY')} - {moment(task.dataFinal).format('DD/MM/YYYY')}
                            </div>
                            
                            {task.unit && (
                                <div className="small text-muted mt-1">
                                    <i className="ni ni-building mr-2"></i>
                                    {units.find(u => u.id === parseInt(task.unit))?.name || 'Unit'}
                                </div>
                            )}
                            {task.lesson && (
                                <div className="small text-muted mt-1">
                                    <i className="ni ni-book-bookmark mr-2"></i>
                                    {(() => {
                                        const lessonId = task.lesson.toString();
                                        const lesson = lessons?.find(l => l.id.toString() === lessonId);
                                        return lesson ? lesson.name : `Lesson ${task.lesson}`;
                                    })()}
                                </div>
                            )}
                        </div>
                    )}

                    <p className="card-text text-muted small">
                        {task.obs && task.obs.length > 50 ? (
                            <>
                                {task.obs.substring(0, 50)}...
                                <button
                                    className="btn btn-link btn-sm p-0 ml-1"
                                    onClick={() => onOpen && onOpen(task.obs)}
                                >
                                    {translate?.ver_mais || "Ver mais"}
                                </button>
                            </>
                        ) : task.obs}
                    </p>

                    <div className="mt-3">
                        {isTeacher ? (
                            <div className="btn-group">
                                <Button
                                    outline
                                    color="primary"
                                    size="sm"
                                    className="border-primary"
                                    onClick={() => onEdit(task)}
                                >
                                    <i className="fas fa-pencil-alt"></i>
                                </Button>
                                <Button
                                    outline
                                    color="info"
                                    size="sm"
                                    className="border-info"
                                    onClick={() => onDeliveries(task)}
                                >
                                    <i className="fas fa-users"></i>
                                </Button>
                                <Button
                                    outline
                                    color="danger"
                                    size="sm"
                                    className="border-danger"
                                    onClick={() => onDelete(task)}
                                >
                                    <i className="fas fa-trash"></i>
                                </Button>
                            </div>
                        ) : (
                            <Button
                                color={isDelivered ? 'success' : 'primary'}
                                size="sm"
                                className="w-100"
                                onClick={() => onDeliver(task)}
                            >
                                {isDelivered ? (
                                    <>
                                        <i className="fas fa-check mr-2"></i>
                                        Tarefa Entregue
                                    </>
                                ) : (
                                    'Entregar Tarefa'
                                )}
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ModalTaskCard;