import React from 'react';
import Main from './views/Main.js'
import api from './Api.js';
import $ from 'jquery';
import { connect } from 'react-redux';
import ErrorPage from "./views/pages/ErrorPage.jsx"; 
//import LogRocket from 'logrocket';
import Cookies from 'universal-cookie'; 

window.swal = require('sweetalert2');

if(window.location.origin !== "http://192.168.15.200:3000"){
 
  //LogRocket.init('ejs9so/cs-plus-school');

}

const cookies = new Cookies();

class App extends React.Component {

  constructor(props){

    super(props);
 
    var session = cookies.get('session');
    var userId = cookies.get('userId');

    if(userId !== undefined && session !== undefined){

      sessionStorage.setItem('userId',userId);
      sessionStorage.setItem('session',session);

    }
    

   

    //Para verificar se tem algum acesso pedido na url
    var url = new URL(window.location.href);
    var get_session = url.searchParams.get("session");
    var get_user = url.searchParams.get("userId");

    //caso o acesso seja por url
    if(get_session !== null && get_user !== null){

      session = get_session;
      userId = get_user;

      sessionStorage.setItem('userId',userId);
      sessionStorage.setItem('session',session);

      window.location.href="/";

    }
    
    //caso não tenha, o session é vazio, isso faz o cara ir para página de login
    if(session === null || userId === null){ session = ""; userId = ""; }
 
    this.state = {preloader:true,confs:{},systemOK:false,userId:userId,session:session,carregouIp:false,menuMin:false,lessonsMinified:true,languageSelected:"pt-br",languages:[],languageTexts:{}}; 
    
    this.verificationInitial = this.verificationInitial.bind(this);
    this.getCurrentLanguage = this.getCurrentLanguage.bind(this);
    this.language = this.language.bind(this);
    this.removeSession = this.removeSession.bind(this);
    
     
    
    window.app = this;

  }
  
  //retorna os testos da linguagem atual
  language(term){ if(term === undefined){ return this.state.languageTexts; }else{ return this.state.languageTexts[term]; } }
  
  //Verifica o backend
  async verificationInitial(){

      var ip = await api.verifyBackend().then((res)=>{ /*console.log(res.data);*/ return res.data; }).catch(error=>{ console.log(error.data); console.log(error); console.log(error.response); return false; });
 
      if(ip === false){ console.log('Erro:01'); return false; }
      if(ip.url === undefined){ console.log('Erro:02'); return false; } 

      //var backend = await $.ajax({url: ip.url,async: true}).done(function(data){return data.responseJSON;}).fail(function(){ console.log('falha ao acessar:'+ip.url); return false;}.bind(this)).catch(function(){console.log('falha ao acessar: '+ip.url);return false;}.bind(this));
      var backend = await api.verifyBackend2(ip.url).then((res)=>{ /*console.log(res.data);*/ return true; }).catch(error => { console.log(error.data);  console.log(error); console.log(error.response); return false;  });

      if(backend){

        sessionStorage.setItem('backend', ip.url);
        sessionStorage.setItem('verify',ip.verify);
        sessionStorage.setItem('centerBackend',ip.centerBackend)

        //Para buscar as configurações do sistema
        var confs = await api.loadConfig().then((res)=>{ 

          //console.log(res.data); 
          return res.data; 

        }).catch(error => { console.log(error.data);  console.log(error); console.log(error.response); return false;  });


        if(confs !== false){ 

          this.setState({confs:confs}); 

          //Para setar o css
          $("#customcss").html(confs.customcss);


        }else{ 

          console.log('Erro:04 load conf'); 
          return false; 

        }

        setTimeout(function(){ this.setState({carregouIp:true}); this.verify(1); }.bind(this), 100);
        return true;

      }else{ console.log('O backend retornou false.'); return false; }

  }
  
  //Parar deslogar o usuario do sistema
  removeSession() {
    // Primeiro faz o logout no servidor
    return api.logout()
        .then(() => {
            // Depois limpa os dados locais
            console.log('removeu tudo');
            sessionStorage.removeItem('session');
            sessionStorage.removeItem('userId');
            delete window.app.state.dataUser;
            delete window.app.state.user;
            this.setState({session:'', userId:''});
        })
        .catch(error => {
            // Se der erro no logout, limpa os dados locais mesmo assim
            console.warn('Erro no logout:', error);
            sessionStorage.removeItem('session');
            sessionStorage.removeItem('userId');
            delete window.app.state.dataUser;
            delete window.app.state.user;
            this.setState({session:'', userId:''});
        });
  }

  //para verificar no servidor se ele esta logado.
  verify(pass){

    if(this.state.session !== "" && this.state.carregouIp === true){ pass = true; }
    
    if(pass === true  && sessionStorage.getItem('verify') === "true"){
      api.verifyAcess({}).then((res)=>{

        if(this.state.user === undefined){ 

          this.setState({user:res.data}); 
          this.props.dispatch({type:"SET_DATA_USER",data:res.data});
          this.getLanguageTexts(res.data.preference.language+'.json',res.data.preference.language);

          if(window.location.origin !== "http://192.168.15.200:3000"){

          //  LogRocket.identify('csplus_school_'+res.data.id,{name: res.data.name+' '+res.data.lastName,email:res.data.email,subscriptionType:'pro',location:window.location.origin});

          }
          
        
        }
        
      }).catch(error=>{
          console.log(error.data);
          console.log(error);
          console.log(error.response);
          if(error.response !== undefined){if(error.response.status !== undefined){if(error.response.status === 401){  this.removeSession();  }}}
      });

    }

    setTimeout(function(){ this.verify(); }.bind(this), 6000);

  }


  //Para carregas as linguagens disponíveis no sistema.
  async getAllLanguage(){
    var language = await api.listLanguages().then((res) => {

      this.setState({languages:res.data})
      this.props.dispatch({type:"SET_LIST_LANGUAGE",data:res.data});
      return true;

    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return language;
  }


  //para retornar os dados da linguagem atual selecionaand
  getCurrentLanguage(){
    var language;  for(let i = 0; i < this.state.languages.length; i++){ if(this.state.languages[i].id === this.state.languageSelected){ language = this.state.languages[i]; }} return language;
  }

  //para buscar o arquivo da linguagem no sistema
  async getLanguageTexts(nameFile,id){


    this.props.dispatch({type:"SET_DATA_SET_PREFERENCE_LANGUAGE",data:id});
    
    //this.setState({languageSelected:id});
    var a = await api.languageText(nameFile).then((res) => {

      //this.setState({languageTexts:res.data});
      this.props.dispatch({type:"SET_DATA_TRANSLATE",data:res.data});
      
      return true;

    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    return a;
  }

  //Para pegar checar a licença
  async checkLicense(){

    var a = await api.checkLicense().then((res) => {
      
      //console.log(res.data);
      return true;

    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });

    return a;

  } 

  //Para pegar as permissões
  async getLevelAccess(){

    var a = await api.getLevelAcess().then(async (res) => {
      
      await this.setState({permitions:res.data});
      this.props.dispatch({type:"SET_LEVEL_ACCESS",data:res.data});

      return true;

    }).catch(error => {
      console.log(error.data);
      console.log(error);
      console.log(error.response);
      return false;
    });
    
    return a;

  }


  async componentDidMount(){

    //para verificar o backend
    var a = await this.verificationInitial();

    //para pegar as linguagens disponíveis no sistema 
    var b = await this.getAllLanguage();

    //para carregar a linguagem padrão
    var c = await this.getLanguageTexts('pt-br.json','pt-br');

    //para verificar licença
    var d = await this.checkLicense();

    //para buscar todas as permissões
    var e = await this.getLevelAccess();
 
    //caso esteja tudo correto, exibe a página
    if(a === true && b === true && c === true && d === true && e === true){

      this.setState({preloader:false});

    }else{

      console.log("Verificação do brancked: "+a);
      console.log("Verificação do languages: "+b);
      console.log("Baixou arquivo de linguagem: "+c);
      console.log("Verificou licença: "+d);
      console.log("Verificou permissões: "+e);

      this.setState({erroSystem:true});

      var errors = {};
      errors.a = a;
      errors.b = b;
      errors.c = c;
      errors.d = d;
      errors.e = e;

      this.setState({errors:errors,preloader:false});

    }

  }

  render() {

     console.log('deu render');

    //Verifica se esta minimizado
    if(window.app.state.user !== undefined){

      if(parseInt(window.app.state.user.preference.minimizeMenu) === 1){

        $('body').removeClass('g-sidenav-show');
        $('body').removeClass('g-sidenav-pinned');
        $('body').addClass('g-sidenav-hidden');
        $('.sidenav-toggler').removeClass('active');

      }else{

        $('body').addClass('g-sidenav-show');
        $('body').addClass('g-sidenav-pinned');
        $('body').removeClass('g-sidenav-hidden');
        $('.sidenav-toggler').addClass('active');

      }

    }


    console.log('this.state.preloader:');
    console.log(this.state.preloader);
    
    console.log('this.state.errors:');
    console.log(this.state.errors);
    


    return (

      <React.Fragment>

        
        {
          
          (this.state.preloader === false && this.state.errors === undefined) &&
          <React.Fragment>

            <Main loading={ (n) => this.loading(n) }  setAppState={ (n) => this.setState(n) }  getAppState={ () => this.state } />
    
          </React.Fragment>
        
        }
        {
          /* preload od sistema 
          */
          //<Sugar customLoading={this.state.preloader} color="#9f102d" background="linear-gradient(rgb(255, 255, 255) 0%, rgb(255, 255, 255) 100%)"/>
          
        }
        
        {/*tela de erro caso exista*/}

        { (this.state.errors !== undefined) &&

           <ErrorPage errors={this.state.errors}/>

        }


      </React.Fragment>

    );
  }


}

export default connect()(App);