import React from "react";
// react library for routing
import { Route, Switch } from "react-router-dom";
import { connect } from 'react-redux';

// core components
import AdminNavbar from "./../components/Navbars/AdminNavbar.jsx";
import AdminFooter from "./../components/Footers/AdminFooter.jsx";
import Sidebar from "./../components/Sidebar/Sidebar.jsx";
import routesNew from "./../routesNew.js";
import ListClassroom from "./../views/pages/ListClassroom.jsx";
import License from "./../views/pages/License.jsx";
import Users from "./../views/pages/Users.jsx";
import Classroom from "./../views/pages/Classroom.jsx";
import Calendar from "./../views/pages/Calendar.jsx";
import Company from "./../views/pages/Company.jsx";
import TaskTypes from "./../views/pages/TaskTypes.jsx";
import Engine from "./../views/pages/Engine.jsx";
import $ from 'jquery';
import api from "./../Api.js";
import DashboardClassroom from "./../views/pages/Dashboard_classroom.jsx";
import Appearance from "./../views/pages/Appearance.jsx";
import ChangePhoto from "./../views/pages/modal/ChangePhoto.jsx";
import ChangePassword from "./../views/pages/modal/ChangePassword.jsx";
import Socketio from "./../components/Socketio.jsx";
import DashBoardStudent from "./../views/pages/dashboards/Student.jsx";
import DashBoardTeacher from "./../views/pages/dashboards/Teacher.jsx";
import SwitDash from "./../views/pages/dashboards/SwitDash.jsx";
import Profile from "./../views/pages/Profile.jsx";
import Chat from "./../chat/index.jsx";
import UserAPI from "./../views/UserAPI.jsx";
import Comment from "./Comment.jsx";

import ReportClassRoom from "./../views/report/ClassRoom.jsx";


//import LogRocket from 'logrocket';
import reduxHelper from "./../helper/index.js";
import NotificationBell from '../views/pages/modal/NotificationBell.jsx';

class Admin extends React.Component {

  state = {sidenavOpen: true};

  constructor(props) {
    
    super(props);
    
    this.state = {
      courses:[],
      classrooms:[],
      loadingCourses:false,
      loadingClassRoom:false,
      loadingAll:false,
      openModalChangePhoto:false,
      unitConsult:[],
      routes:[],
      location:window.location.pathname
    };
    
    this.getCourseState = this.getCourseState.bind(this);
    this.getClassRoomState = this.getClassRoomState.bind(this);
    this.setPermission = this.setPermission.bind(this);
    this.getRoutes = this.getRoutes.bind(this);
    this.getMyCompanys = this.getMyCompanys.bind(this);
    this.helper = new reduxHelper(this.props);
    this.getAllCompanys = this.getAllCompanys.bind(this);

    window.admin = this;

  }
   
  getBrandText = path => { for (let i = 0; i < this.state.routes.length; i++) { if ( this.props.location.pathname.indexOf( this.state.routes[i].layout + this.state.routes[i].path ) !== -1 ) { return this.state.routes[i].name; } } return "Brand"; };

  //Para abrir e fechar o menu da esquerda
  toggleSidenav = async e => { 
 
    var user = window.app.state.user;
    console.log(user.preference.minimizeMenu);
    if(parseInt(user.preference.minimizeMenu) === 0){ user.preference.minimizeMenu = '1'; }else{ user.preference.minimizeMenu = '0'; }
    await window.app.setState({user:user});
    this.savePreference({minimizeMenu:user.preference.minimizeMenu});

    $('body').addClass('g-sidenav-show'); 

  };

  //Para pegar todos os cursos disponíveis na licença
  async getCourses(){

    var a = await api.getAllcourses().then((res) => {

        this.setState({courses:res.data,loadingCourses:true});
        return true;

    }).catch(error => { 

        console.log(error.data);
        console.log(error);
        console.log(error.response); 
        return false; 

    });

    return a;

  }

  //Para salvar alguma preferencia do usuario
  async savePreference(obj){

    var a = await api.savePreference(obj).then((res) => {

        console.log('salvou preferencia');

    }).catch(error => { 

        console.log(error.data);
        console.log(error);
        console.log(error.response); 
        return false; 

    });

    return a;

  }

  //Para pegar todas as turmas que eu tenho acesso
  async getMyClassRoom(){

    var a = await api.getMyClassRooms().then((res) => {

      this.setState({classrooms:res.data,loadingClassRoom:true});
      return true;

    }).catch(error => { 

        console.log(error.data);
        console.log(error);
        console.log(error.response); 
        return false; 

    });

    return a;

  }
 
  //Para pegar o curso do state
  getCourseState(id){ for(var i = 0; i < this.state.courses.length; i++){ if(parseInt(this.state.courses[i].id) === parseInt(id)){ return this.state.courses[i]; } } return false; }
  
  //Para pegar a turma do state
  getClassRoomState(id){ for(var i = 0; i < this.state.classrooms.length; i++){ if(parseInt(this.state.classrooms[i].id) === parseInt(id)){ return this.state.classrooms[i]; }} return false; }

  //Para pegar as units de um curso
  async getUnitsCourse(course){

    //Verifico se existe no state atual
    if(this.state.unitConsult[course] !== undefined && this.state.unitConsult[course] !== null){ return this.state.unitConsult[course]; }

    //busca as units la no servidor
    var a = await api.getUnitsCourse(course).then((res) => {

        var unitConsult = this.state.unitConsult;
        unitConsult[course] = res.data;
        this.setState({unitConsult:unitConsult,user:res.data});

        return res.data;

    }).catch(error => { 

      this.setState({user:false});
      console.log(error.data);
      console.log(error);
      console.log(error.response); 
      return false; 

    });

    return a;

  }

  //Para setar as permissões do usuario
  setPermission(){

    var user = this.props.user;
    var accesslevel = this.props.accesslevel;
    var index = accesslevel.map(function(x) {return x.id; }).indexOf(user.accessLevel);

    if(accesslevel[index] !== undefined){
      
      var permissions = accesslevel[index].permissions;
      this.props.dispatch({type:"SET_PERMISSION",data:permissions});

    }

    

  }

  //Para pegar as companias que eu tenho acesso
  async getMyCompanys(){

    var companys = await this.helper.company().getMyCompanys();
    if(companys !== false){

      if(companys.status === 200){

        //console.log(companys.data);
        await this.props.dispatch({type:"SET_MY_COMPANYS",data:companys.data});
        await this.setState({loadCompany:true});

      }
      
      if(companys.status !== 200){ console.log("Não foi possível obeter os dados de company"); console.log(companys); }

    }

  }

  //Para mostrar as rotas do menu
  getRoutes(){
 
    var routes = routesNew(this.props.permission,this.props.translate);
    return routes;

  }

  async getAllCompanys(){

    var data = await api.getListCompany();
    console.log('data getAllCompanys');
    window.allCompanys = data.data;
    //console.log(data.data);

  }

 
  async componentDidMount(){

    //LogRocket.init('ejs9so/cs-plus-school');
    //LogRocket.identify('csplus_school_'+this.props.user.id,{name:this.props.user.name+' '+this.props.user.lastName,email:this.props.user.email,location:window.location.hostname});

    this.getUsersOnline();
    await this.getCourses();
    await this.getMyClassRoom();
    await this.getMyCompanys();
    await this.setPermission();
    await this.getAllCompanys();
    
    this.setState({loadingAll:true});

  }

  componentWillUnmount(){

    window.ioInfoPosition = false;
    
  }


  componentDidUpdate(prevProps) {

 
		if (this.state.location !== window.location.pathname) {
		  this.onRouteChanged();
		}
	}

	onRouteChanged() {
    
    this.setState({location:window.location.pathname});
    window.socket.emit('changeroute', window.location.pathname);

  }
  
  //Para pegar os usuarios online
  async getUsersOnline(){

    var usersOnline = await this.helper.chat().getUsersOnline();
    if(usersOnline.status === 200){

      this.props.dispatch({type:"SET_USERS_ONLINE",data:usersOnline.data});

    } 

  }





  render(){
    const {classroom, unit, lesson} = this.props.match.params;
    //Verifica se esta na classerom
    var url = window.location.pathname; 
    var temClassRoom = url.search("/myclassroom/");

    var numsStr = url.split('/')[2]
    var showClassRom = false;
 
    if(parseInt(numsStr) && temClassRoom !== -1){ showClassRom = true; }
    
    var dash = <React.Fragment></React.Fragment>;
    var switDash = <React.Fragment></React.Fragment>;
    
    //caso seja diretor
    if(this.props.user.accessLevel === 5){ switDash = <SwitDash setState={ (data) => this.setState(data) } state={this.state} />; }

    var dashBoardStudent = <React.Fragment>{switDash}<DashBoardStudent history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /></React.Fragment>;
    var dashBoardTeacher = <React.Fragment>{switDash}<DashBoardTeacher history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /></React.Fragment>

     

    //aluno
    if(this.props.user.accessLevel === 4){ dash = dashBoardStudent; }

    //professor
    if(this.props.user.accessLevel === 3){ dash = dashBoardTeacher; }
    
    //Diretor
    if(this.props.user.accessLevel === 5){ dash = dashBoardTeacher; }
    
    //Verificar o swit
    if(this.props.user.accessLevel === 5){

      if(this.state.viewDashBoard === "Diretor"){ }
      if(this.state.viewDashBoard === "Estudante"){ dash = dashBoardStudent; }
      if(this.state.viewDashBoard === "Professor"){ dash = dashBoardTeacher; }
 
    }


    if(window.mt !== undefined){

      if(window.location.href !== window.sendmt){

        var obj = {email:window.app.state.user.email,firstname:window.app.state.user.name,lasname:window.app.state.user.lastName};

        //mt('send', 'pageview', {companyemail:'mautic@example.com',companydescription:'description of company',companywebsite:'https://example.com',tags:'addThisTag,-removeThisTag',utm_campaign:'Some Campaign'});
        console.log(JSON.stringify(obj));
 
        //window.mt('send','pageview',obj);

        
          
        console.log('dispaou pageview');
        window.sendmt = window.location.href;

      }

    }

    return (
      <React.Fragment>

        <Socketio {...this.props} />

        <Comment
          user={this.props.user}
          classroom={classroom}
          unit={unit}
          lesson={lesson}
        />

        <Sidebar {...this.props} routes={this.getRoutes()} toggleSidenav={this.toggleSidenav} sidenavOpen={true} logo={{ innerLink: "/", imgSrc:' ', imgAlt: "..." }} />
       

        <div className="main-content" ref="mainContent" onClick={this.closeSidenav} >

          <AdminNavbar {...this.props} state={this.state} setState={ (e) => this.setState(e) } theme="dark" toggleSidenav={this.toggleSidenav} sidenavOpen={this.state.sidenavOpen} brandText={this.getBrandText(this.props.location.pathname)} />
            
            { 

              (this.state.loadingCourses === true && this.state.loadingClassRoom === true && this.state.loadingAll === true && showClassRom === true) &&
              <Classroom idClass={numsStr} history={   this.props.history } getMainState={ () => this.state } getUnitsCourse={ (id) => this.getUnitsCourse(id) } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } />

            }
            {

              (this.state.loadingCourses === true && this.state.loadingClassRoom === true && showClassRom === false && this.state.loadCompany === true) &&
              <React.Fragment>
              <Switch>

                <Route exact={true} path="/classroom/dashboard/:classroom/:unit" render={ (props) => <DashboardClassroom {...props} getUnitsCourse={ (e) => this.getUnitsCourse(e) } /> }/>
                <Route exact={true} path="/classroom/" component={ListClassroom} />
                <Route exact={true} path="/calendar/" component={Calendar} />

                <Route exact={true} path="/configuration/license" component={License} />
                <Route exact={true} path="/configuration/appearance" component={Appearance} />
                <Route exact={true} path="/configuration/company" component={Company} />
                <Route exact={true} path="/configuration/tasks" component={TaskTypes} />
                
                <Route exact={true} path="/profile/:user" component={Profile} />
                
                <Route exact={true} path="/users/" render={ () => <Users state={this.state} setState={ (e) => this.setState(e) } /> } />
                {
                  /*
                  
                    <Route exact={true} path="/" render={ () => <DashBoardStudent history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /> }  />
                    <Route exact={true} path="/" render={ () => <DashBoardTeacher history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /> }  />
                    <Route exact={true} path="/" render={ () => <DashBoardTeacher history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /> }  />
        
                  */
                }
                {/*
                  
                  <Route exact={true} path="/" render={ () => <DashBoardTeacher history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /> }  />
                  <Route exact={true} path="/" render={ () => <DashBoardStudent history={ () => this.props.history } getMainState={ () => this.state } getCourseState={ (id) => this.getCourseState(id) } getClassRoomState={ (id) => this.getClassRoomState(id) } /> }  />
                  
                */}

                <Route exact={true} path="/engine/" render={ () => <Engine /> } />
                <Route exact={true} path="/report/classroom/" render={ () => <ReportClassRoom /> } />
                
                { dash }
                
              </Switch>

              <AdminFooter/>
                
              </React.Fragment>

            }

        </div>

        {this.state.sidenavOpen ? ( <div className="backdrop d-xl-none" onClick={this.toggleSidenav} /> ) : null}

        <ChangePhoto state={this.state} setState={ (e) => this.setState(e) } />
        <ChangePassword state={this.state} setState={ (e) => this.setState(e) } />
          
        {

          (this.props.classroom_option.map(function(x){ return x.name; }).indexOf('hidden_chat') === -1) &&
          <Chat/>
        
        }
        

        <UserAPI/>
      </React.Fragment>
    );
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  classroom_option: state.classroom_option,

}))(Admin);