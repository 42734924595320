import React from 'react';
import { Input, Button, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap';
import './styles/ModalTaskFilters.css';

export const ModalTaskFilters = ({ 
    showFilters, 
    selectedUnit, 
    selectedLesson,
    units,
    lessons,
    onUnitChange,
    onLessonChange,
    onToggleFilters,
    onToggleShowAll,
    showAllInfo,
    isTeacher,
    onNewTask,
    onShowTemplates,
    onShowUserTasks,
    translate,
    searchQuery,
    onSearch
}) => {
    return (
        <div className="mb-4">
            <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                    <div className="dropdown mr-3">
                        <button
                            className="btn btn-sm btn-secondary dropdown-toggle"
                            type="button"
                            onClick={onToggleFilters}
                        >
                            <i className="fas fa-filter mr-1"></i>
                            {translate?.filtros || "Filtros"}
                        </button>
                        
                        {showFilters && (
                            <div className="dropdown-menu show" style={{
                                position: 'absolute',
                                transform: 'translate3d(0px, 32px, 0px)',
                                top: '0px',
                                left: '0px',
                                willChange: 'transform',
                                backgroundColor: 'white',
                                padding: '0.75rem',
                                boxShadow: '0 0 10px rgba(0,0,0,0.1)',
                                borderRadius: '0.375rem',
                                minWidth: '200px',
                                zIndex: 1000
                            }}>
                                <div className="mb-2">
                                    <label className="form-control-label d-flex align-items-center small">
                                        <i className="ni ni-building mr-2"></i>
                                        Unit
                                    </label>
                                    <Input
                                        type="select"
                                        value={selectedUnit}
                                        onChange={onUnitChange}
                                        className="form-control-alternative form-control-sm"
                                        bsSize="sm"
                                    >
                                        <option value="">Todas as Units</option>
                                        {units.map(unit => (
                                            <option key={unit.id} value={unit.id}>
                                                {unit.name}
                                            </option>
                                        ))}
                                    </Input>
                                </div>
                                
                                <div className="mb-2">
                                    <label className="form-control-label d-flex align-items-center small">
                                        <i className="ni ni-book-bookmark mr-2"></i>
                                        Lesson
                                    </label>
                                    <Input
                                        type="select"
                                        value={selectedLesson}
                                        onChange={onLessonChange}
                                        className="form-control-alternative form-control-sm"
                                        bsSize="sm"
                                        disabled={!selectedUnit}
                                    >
                                        <option value="">Todas as Lessons</option>
                                        {lessons.map(lesson => (
                                            <option key={lesson.id} value={lesson.id}>
                                                {lesson.name}
                                            </option>
                                        ))}
                                    </Input>
                                </div>

                                {(selectedUnit || selectedLesson) && (
                                    <Button
                                        color="link"
                                        size="sm"
                                        className="text-muted p-0 small"
                                        onClick={() => {
                                            onUnitChange({ target: { value: '' } });
                                            onLessonChange({ target: { value: '' } });
                                        }}
                                    >
                                        <i className="fas fa-times mr-1"></i>
                                        Limpar filtros
                                    </Button>
                                )}
                            </div>
                        )}
                    </div>

                    <button
                        className={`btn btn-sm ${showAllInfo ? 'btn-primary' : 'btn-outline-primary'} mr-3`}
                        onClick={onToggleShowAll}
                    >
                        <i className={`fas fa-${showAllInfo ? 'compress-alt' : 'expand-alt'} mr-1`}></i>
                        {showAllInfo ? 'Mostrar menos' : 'Mostrar mais'}
                    </button>

                    {(selectedUnit || selectedLesson) && (
                        <div className="d-flex align-items-center">
                            {selectedUnit && (
                                <span className="badge badge-primary badge-sm mr-2">
                                    <i className="ni ni-building mr-1"></i>
                                    {units.find(u => u.id === parseInt(selectedUnit))?.name}
                                </span>
                            )}
                            {selectedLesson && (
                                <span className="badge badge-info badge-sm">
                                    <i className="ni ni-book-bookmark mr-1"></i>
                                    {lessons.find(l => l.id === parseInt(selectedLesson))?.name}
                                </span>
                            )}
                        </div>
                    )}
                </div>

                <div>
                    {isTeacher ? (
                        <div className="d-flex">
                            <button 
                                onClick={onNewTask}
                                className="btn btn-primary btn-sm"
                            >
                                <i className="fas fa-plus mr-1"></i> {translate?.nova_tarefa || "Nova Tarefa"}
                            </button>
                            
                            <button
                                className="btn btn-primary btn-sm ml-2"
                                onClick={onShowTemplates}
                            >
                                <i className="fas fa-copy mr-1"></i> {translate?.usar_template || "Usar Template"}
                            </button>
                        </div>
                    ) : (
                        <button 
                            onClick={onShowUserTasks}
                            className="btn btn-primary btn-sm"
                        >
                            <i className="fas fa-tasks mr-1"></i> Minhas tarefas enviadas
                        </button>
                    )}
                </div>
            </div>

            <div className="filters-container mt-3">
                <InputGroup className="mb-3">
                    <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                            <i className="fas fa-search" />
                        </InputGroupText>
                    </InputGroupAddon>
                    <Input
                        placeholder="Pesquisar tarefas..."
                        value={searchQuery}
                        onChange={onSearch}
                        className="form-control-alternative"
                    />
                </InputGroup>
            </div>
        </div>
    );
};

export default ModalTaskFilters;