import React from 'react';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import api from '../../../Api';
import moment from 'moment';
import 'moment/locale/pt-br';
import { connect } from 'react-redux';

class NotificationBell extends React.Component {
    state = {
        notifications: [],
        unreadCount: 0,
        isPaneOpen: false
    };

    MAX_NOTIFICATIONS = 50;
    
    getStorageKey = (key) => {
        const userId = sessionStorage.getItem('userId');
        const userType = sessionStorage.getItem('userType');
        return `notifications_${userId}_${userType}_${key}`;
    };

    componentDidMount() {
        this.clearOldNotifications();

        const savedNotifications = JSON.parse(sessionStorage.getItem(this.getStorageKey('list')) || '[]');
        this.setState({
            notifications: savedNotifications,
            unreadCount: savedNotifications.filter(n => !n.read).length
        });

        this.checkNewActivity();
        this.interval = setInterval(this.checkNewActivity, 30000);

        const highlightCommentId = sessionStorage.getItem('highlightCommentId');
        const shouldOpenCommentsTab = sessionStorage.getItem('openCommentsTab');
        
        if (highlightCommentId && shouldOpenCommentsTab) {
            const storedClassroom = sessionStorage.getItem('highlightCommentClassroom');
            const storedUnit = sessionStorage.getItem('highlightCommentUnit');
            const storedLesson = sessionStorage.getItem('highlightCommentLesson');

            const currentPath = window.location.pathname;
            const expectedPath = `/myclassroom/${storedClassroom}` + 
                (storedUnit ? `/${storedUnit}` : '') +
                (storedLesson ? `/${storedLesson}` : '');

            if (currentPath === expectedPath) {
                if (window.comment) {
                    window.comment.open({
                        classroom: storedClassroom,
                        unit: storedUnit,
                        lesson: storedLesson
                    });
                    
                    setTimeout(() => {
                        const commentElement = document.getElementById(`comment-${highlightCommentId}`);
                        if (commentElement) {
                            commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                            commentElement.classList.add('highlight-comment');
                            setTimeout(() => {
                                commentElement.classList.remove('highlight-comment');
                            }, 3000);
                        }
                        
                        sessionStorage.removeItem('highlightCommentId');
                        sessionStorage.removeItem('openCommentsTab');
                        sessionStorage.removeItem('highlightCommentClassroom');
                        sessionStorage.removeItem('highlightCommentUnit');
                        sessionStorage.removeItem('highlightCommentLesson');
                    }, 1000);
                }
            }
        }

        const highlightTaskId = sessionStorage.getItem('highlightTaskId');
        const shouldOpenTasksTab = sessionStorage.getItem('openTasksTab');
        
        if (highlightTaskId && shouldOpenTasksTab) {
            const storedClassroom = sessionStorage.getItem('highlightTaskClassroom');
            const currentPath = window.location.pathname;
            const expectedPath = `/myclassroom/${storedClassroom}`;

            if (currentPath === expectedPath) {
                if (window.classroom && window.classroom.toggleModal) {
                    window.classroom.setState({ taskId: highlightTaskId }, () => {
                        window.classroom.toggleModal();
                        
                        setTimeout(() => {
                            const taskElement = document.querySelector(`.task-item-${highlightTaskId}`);
                            if (taskElement) {
                                taskElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                taskElement.classList.add('highlight-comment');
                                setTimeout(() => {
                                    taskElement.classList.remove('highlight-comment');
                                }, 3000);
                            }
                            
                            sessionStorage.removeItem('highlightTaskId');
                            sessionStorage.removeItem('openTasksTab');
                            sessionStorage.removeItem('highlightTaskClassroom');
                        }, 1000);
                    });
                }
            }
        }
    }

    componentWillUnmount() {
        if (this.interval) {
            clearInterval(this.interval);
        }
    }

    saveNotifications = (notifications) => {
        sessionStorage.setItem(this.getStorageKey('list'), JSON.stringify(notifications));
    };

    removeNotification = (e, notificationId) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState(prevState => {
            const newNotifications = prevState.notifications.filter(n => n.id !== notificationId);
            const newUnreadCount = newNotifications.filter(n => !n.read).length;

            this.saveNotifications(newNotifications);
            
            return {
                notifications: newNotifications,
                unreadCount: newUnreadCount
            };
        });
    };

    checkNewActivity = async () => {
        try {
            const lastCheck = sessionStorage.getItem(this.getStorageKey('lastCheck')) || '0';
            const { user, permission = [] } = this.props;
            const adjustedLastCheck = lastCheck === '0' ? 
                new Date(Date.now() - 24 * 60 * 60 * 1000).toISOString() : 
                lastCheck;

            // Fetch notifications
            const [commentsResponse, deliveriesResponse] = await Promise.all([
                api.getNewComments(adjustedLastCheck),
                api.getNewDeliveries(adjustedLastCheck)
            ]);

            // Get unique classrooms
            const uniqueClassrooms = new Set([
                ...commentsResponse.data.map(c => c.classroom),
                ...deliveriesResponse.data.map(d => d.classroom_id)
            ]);

            const classroomPermissions = {};
            const permissionsTable = [];

            // Get permissions for each classroom
            for (const classroomId of uniqueClassrooms) {
                try {
                    const response = await api.getAcessoClassRoom(classroomId, user.id);
                    
                    if (response.data?.inclassroom?.type) {
                        const perm = window.app.state.permitions.find(p => 
                            p.id === response.data.inclassroom.type
                        );
                        
                        if (perm) {
                            classroomPermissions[classroomId] = perm.permissions;
                            permissionsTable.push({
                                classroom: classroomId,
                                type: response.data.inclassroom.type,
                                permissions: perm.permissions,
                                canComment: perm.permissions.includes(35),
                                canViewDeliveries: perm.permissions.includes(46),
                                isInClassroom: true
                            });
                        }
                    } else {
                        permissionsTable.push({
                            classroom: classroomId,
                            type: null,
                            permissions: [],
                            canComment: false,
                            canViewDeliveries: false,
                            isInClassroom: false
                        });
                    }
                } catch (error) {
                    console.error(`Error fetching permissions for classroom ${classroomId}:`, error);
                }
            }

            // Process new notifications
            const newNotifications = [
                // Process comments
                ...this.processComments(commentsResponse.data, classroomPermissions),
                // Process deliveries
                ...this.processDeliveries(deliveriesResponse.data, classroomPermissions)
            ];

            // Merge with existing notifications
            const savedNotifications = JSON.parse(
                sessionStorage.getItem(this.getStorageKey('list')) || '[]'
            );
            
            const uniqueNotifications = this.mergeNotifications(
                savedNotifications, 
                newNotifications
            );

            // Update state and storage
            this.saveNotifications(uniqueNotifications);
            this.setState({
                notifications: uniqueNotifications,
                unreadCount: uniqueNotifications.filter(n => !n.read).length
            });

            sessionStorage.setItem(this.getStorageKey('lastCheck'), new Date().toISOString());
        } catch (error) {
            console.error('Error checking new activity:', error);
        }
    };

    processComments = (comments, permissions) => {
        return comments
            .filter(comment => permissions[comment.classroom]?.includes(35))
            .map(comment => ({
                id: `comment-${comment.id}`,
                type: 'comment',
                message: comment.resp_comment 
                    ? `${comment.username} respondeu ao seu comentário`
                    : `${comment.username} fez um novo comentário`,
                date: comment.createDate,
                data: {
                    ...comment,
                    id: comment.id,
                    classroom: comment.classroom,
                    unit: comment.unit || null,
                    lesson: comment.lesson || null,
                    comment: comment.comment
                },
                read: false,
                isNew: true
            }));
    };

    processDeliveries = (deliveries, permissions) => {
        return deliveries
            .filter(delivery => permissions[delivery.classroom_id]?.includes(46))
            .map(delivery => ({
                id: `delivery-${delivery.id}`,
                type: 'delivery',
                message: `Nova entrega na tarefa ${delivery.taskName}`,
                date: delivery.sendDate,
                data: {...delivery},
                read: false,
                isNew: true
            }));
    };

    mergeNotifications = (existing, newItems) => {
        return [...new Map([...existing, ...newItems]
            .map(item => [item.id, item]))
            .values()]
            .sort((a, b) => new Date(b.date) - new Date(a.date))
            .slice(0, this.MAX_NOTIFICATIONS);
    };

    handleNotificationClick = async (e, notification) => {
        e.preventDefault();
        e.stopPropagation();

        console.log('Notificação clicada:', notification);

        this.setState(prevState => {
            const updatedNotifications = prevState.notifications.map(n => 
                n.id === notification.id ? { ...n, read: true } : n
            );
            
            this.saveNotifications(updatedNotifications);
            
            return {
                notifications: updatedNotifications,
                unreadCount: updatedNotifications.filter(n => !n.read).length
            };
        });

        if (notification.type === 'comment') {
            // Debug the notification data
            console.group('🔍 Comment Notification Debug');
            console.log('Raw notification:', notification);
            console.log('Classroom ID:', notification.data.classroom); 
            console.log('Unit:', notification.data.unit);
            console.log('Lesson:', notification.data.lesson);
            
            const route = `/myclassroom/${notification.data.classroom}` + 
                (notification.data.unit ? `/${notification.data.unit}` : '') +
                (notification.data.lesson ? `/${notification.data.lesson}` : '');
            
            console.log('Generated route:', route);
            console.log('Current path:', window.location.pathname);
            
            // Update storage with correct IDs
            sessionStorage.setItem('highlightCommentId', notification.data.id);
            sessionStorage.setItem('openCommentsTab', 'true');
            sessionStorage.setItem('highlightCommentClassroom', notification.data.classroom); // Changed from classroomId
            sessionStorage.setItem('highlightCommentUnit', notification.data.unit || '');
            sessionStorage.setItem('highlightCommentLesson', notification.data.lesson || '');
            
            const currentPath = window.location.pathname;
            
            if (currentPath === route) {
                if (window.comment) {
                    try {
                        window.comment.open({
                            classroom: notification.data.classroom,
                            unit: notification.data.unit,
                            lesson: notification.data.lesson
                        });

                        const checkCommentsLoaded = () => {
                            const commentsContainer = document.querySelector('.comments-section');
                            return commentsContainer && commentsContainer.children.length > 0;
                        };

                        const scrollToComment = () => {
                            console.log('Verificando se os comentários foram carregados...');
                            
                            if (checkCommentsLoaded()) {
                                console.log('Comentários carregados, procurando elemento específico');
                                
                                const commentElement = document.querySelector(`#comment-${notification.data.id}`);
                                
                                if (commentElement) {
                                    console.log('Comentário encontrado, executando scroll');
                                    
                                    setTimeout(() => {
                                        try {
                                            commentElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                            commentElement.classList.add('highlight-comment');
                                            
                                            setTimeout(() => {
                                                commentElement.classList.remove('highlight-comment');
                                            }, 3000);
                                            
                                            sessionStorage.removeItem('highlightCommentId');
                                            sessionStorage.removeItem('openCommentsTab');
                                            sessionStorage.removeItem('highlightCommentClassroom');
                                            sessionStorage.removeItem('highlightCommentUnit');
                                            sessionStorage.removeItem('highlightCommentLesson');
                                        } catch (scrollError) {
                                            console.error('Erro durante o scroll:', scrollError);
                                        }
                                    }, 100);
                                } else {
                                    console.log('Comentário específico não encontrado, tentando novamente...');
                                    setTimeout(scrollToComment, 500);
                                }
                            } else {
                                console.log('Comentários ainda não carregados, aguardando...');
                                setTimeout(scrollToComment, 500);
                            }
                        };

                        setTimeout(scrollToComment, 1000);
                        
                    } catch (error) {
                        console.error('Erro ao abrir o painel de comentários:', error);
                    }
                } else {
                    console.error('Instância do comentário não encontrada (window.comment não existe)');
                }
            } else {
                console.log('Redirecionando para:', route);
                window.location.href = route;
            }
            console.groupEnd();
        } else if (notification.type === 'delivery') {
            try {
                const taskId = notification.data.task_id;
                console.log('Buscando detalhes da tarefa:', taskId);

                const taskResponse = await api.getTask(taskId);
                const classroomId = taskResponse.data.class_room;

                if (classroomId) {
                    const route = `/myclassroom/${classroomId}`;
                    const currentPath = window.location.pathname;

                    if (currentPath === route) {
                        // Se já estiver na página correta
                        if (window.classroom && window.classroom.toggleModal) {
                            window.classroom.setState({ taskId }, () => {
                                window.classroom.toggleModal();
                                
                                setTimeout(() => {
                                    const taskElement = document.getElementById(`task-${taskId}`);
                                    if (taskElement) {
                                        taskElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                                        taskElement.classList.add('highlight-comment');
                                        setTimeout(() => {
                                            taskElement.classList.remove('highlight-comment');
                                        }, 3000);
                                    }
                                }, 500);
                            });
                        }
                    } else {
                        // Se não estiver na página correta
                        sessionStorage.setItem('highlightTaskId', taskId);
                        sessionStorage.setItem('openTasksTab', 'true');
                        sessionStorage.setItem('highlightTaskClassroom', classroomId);
                        window.location.href = route;
                    }
                }
            } catch (error) {
                console.error('Erro ao buscar detalhes da tarefa:', error);
            }
        }
    };

    markAllAsRead = () => {
        this.setState(prevState => {
            const updatedNotifications = prevState.notifications.map(n => ({ ...n, read: true, isNew: false }));
            this.saveNotifications(updatedNotifications);
            return {
                notifications: updatedNotifications,
                unreadCount: 0
            };
        });
    };

    togglePane = () => {
        this.setState(prevState => ({
            isPaneOpen: !prevState.isPaneOpen
        }));
    };

    markAsRead = (e, notification) => {
        e.preventDefault();
        e.stopPropagation();

        this.setState(prevState => {
            const updatedNotifications = prevState.notifications.map(n => 
                n.id === notification.id ? { ...n, read: true, isNew: false } : n
            );
            this.saveNotifications(updatedNotifications);
            return {
                notifications: updatedNotifications,
                unreadCount: updatedNotifications.filter(n => !n.read).length
            };
        });
    };

    renderNotificationItem = (notification, inSliding = false) => {
        return (
            <div 
                className={`d-flex align-items-center py-3 ${notification.read ? 'text-muted' : 'font-weight-bold'}`}
                onClick={(e) => this.handleNotificationClick(e, notification)}
                style={{ cursor: 'pointer' }}
            >
                <div className="mr-3">
                    <i className={`ni ${notification.type === 'comment' ? 'ni-chat-round' : 'ni-paper-diploma'} 
                                  ${notification.type === 'comment' ? 'text-primary' : 'text-success'}`} />
                </div>
                <div style={{ minWidth: 0, flex: 1 }}>
                    <div className="text-truncate">
                        {notification.message}
                    </div>
                    {notification.data.comment && (
                        <div className="text-truncate text-muted small">
                            "{notification.data.comment}"
                        </div>
                    )}
                    <small className="text-muted">
                        {moment(notification.date).fromNow()}
                    </small>
                </div>
                <div className="d-flex align-items-center">
                    {!notification.read && (
                        <button
                            className="btn btn-link text-primary p-0 mr-2"
                            onClick={(e) => this.markAsRead(e, notification)}
                            title="Marcar como lida"
                            style={{ fontSize: '1rem' }}
                        >
                            <i className="ni ni-check-bold" />
                        </button>
                    )}
                    <button
                        className="btn btn-link text-danger p-0"
                        onClick={(e) => this.removeNotification(e, notification.id)}
                        title="Remover notificação"
                        style={{ fontSize: '1.2rem' }}
                    >
                            <i className="ni ni-fat-remove" />
                    </button>
                </div>
            </div>
        );
    };

    render() {
        const { notifications, unreadCount, isPaneOpen } = this.state;
        const MAX_DROPDOWN_NOTIFICATIONS = 5;

        return (
            <div className="position-relative mr-3">
                <UncontrolledDropdown nav>
                    <DropdownToggle nav className="nav-link-icon position-relative">
                        <i className="ni ni-bell-55" />
                        {unreadCount > 0 && (
                            <span className="badge badge-danger badge-pill position-absolute"
                                  style={{
                                      top: '0px',
                                      right: '0px',
                                      transform: 'translate(50%, -50%)',
                                      fontSize: '0.65rem',
                                      padding: '0.35rem 0.5rem'
                                  }}>
                                {unreadCount}
                            </span>
                        )}
                    </DropdownToggle>
                    <DropdownMenu
                        className="dropdown-menu-arrow"
                        style={{
                            width: '300px',
                            maxHeight: '400px',
                            overflowY: 'auto',
                            right: 'auto',
                            left: '50%',
                            transform: 'translateX(-50%)',
                            marginTop: '10px'
                        }}
                    >
                        <div className="py-2">
                            <div className="d-flex justify-content-between align-items-center px-3 pb-2 border-bottom">
                                <h6 className="mb-0">Notificações</h6>
                                {notifications.length > 0 && (
                                    <button
                                        className="btn btn-link text-primary p-0"
                                        onClick={this.togglePane}
                                    >
                                        Ver todas ({notifications.length})
                                    </button>
                                )}
                            </div>

                            {notifications.length === 0 ? (
                                <DropdownItem className="text-center text-muted">
                                    Nenhuma notificação
                                </DropdownItem>
                            ) : (
                                <>
                                    {notifications.slice(0, MAX_DROPDOWN_NOTIFICATIONS).map(notification => (
                                        <DropdownItem tag="div" key={notification.id}>
                                            {this.renderNotificationItem(notification)}
                                        </DropdownItem>
                                    ))}
                                    
                                    {notifications.length > MAX_DROPDOWN_NOTIFICATIONS && (
                                        <div className="text-center mt-2 border-top pt-2">
                                            <small className="text-muted">
                                                Mostrando {MAX_DROPDOWN_NOTIFICATIONS} de {notifications.length} notificações
                                            </small>
                                        </div>
                                    )}
                                </>
                            )}

                            {notifications.length > 0 && (
                                <DropdownItem
                                    className="text-center text-primary border-top mt-2"
                                    onClick={this.markAllAsRead}
                                >
                                    Marcar todas como lidas
                                </DropdownItem>
                            )}
                        </div>
                    </DropdownMenu>
                </UncontrolledDropdown>

                <SlidingPane
                    className="some-custom-class"
                    overlayClassName="some-custom-overlay-class"
                    isOpen={isPaneOpen}
                    title="Todas as Notificações"
                    width="400px"
                    from="right"
                    onRequestClose={this.togglePane}
                >
                    <div className="p-4">
                        {notifications.length > 0 && (
                            <button
                                className="btn btn-primary btn-block mb-4"
                                onClick={this.markAllAsRead}
                            >
                                Marcar todas como lidas
                            </button>
                        )}
                        
                        {notifications.length === 0 ? (
                            <div className="text-center text-muted p-4">
                                <i className="ni ni-bell-55 display-4 mb-3 d-block" />
                                Nenhuma notificação
                            </div>
                        ) : (
                            <div className="notification-list">
                                {notifications.map(notification => (
                                    <div key={notification.id} className="card shadow-sm mb-3">
                                        {this.renderNotificationItem(notification, true)}
                                    </div>
                                ))}
                            </div>
                        )}
                    </div>
                </SlidingPane>
            </div>
        );
    }

    clearOldNotifications = () => {
        const userId = sessionStorage.getItem('userId');
        const userType = sessionStorage.getItem('userType');
        
        for (let i = 0; i < sessionStorage.length; i++) {
            const key = sessionStorage.key(i);
            if (key.startsWith('notifications_')) {
                if (!key.includes(`${userId}_${userType}`)) {
                    sessionStorage.removeItem(key);
                }
            }
        }
    };
}

export default connect(state => ({
    languages: state.language,
    translate: state.translate,
    accesslevel: state.accesslevel,
    permission: state.permission,
    user: state.user,
}))(NotificationBell);
