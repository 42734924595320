import React from "react";
import api from "./../../Api.js";
import classnames from "classnames";
import {Input,Modal,Card,CardHeader,Row,Col,Button,Table,UncontrolledTooltip,Container,CardBody,Form,Alert,FormGroup,InputGroup,InputGroupAddon,InputGroupText} from "reactstrap";
import NotificationAlert from "react-notification-alert";
import { connect } from 'react-redux';
//import InputMask from 'react-input-mask';
import SimpleHeader from "./../../components/Headers/SimpleHeader.jsx";
import reduxHelper from "./../../helper/index.js";
import {BounceLoader,ClipLoader} from 'react-spinners';

class Users extends React.Component {

    constructor(props){

        super(props);

        this.state = {companys:[],dialog:{name:"",group:"",license:"",login_google: "0"},erroCompany:"",addUserCompany:false,searchUser:[]};
        this.helper = new reduxHelper(this.props);
        this.putCompany = this.putCompany.bind(this);
        this.postCompany = this.postCompany.bind(this);
        this.editCompany = this.editCompany.bind(this);
        this.newCompany = this.newCompany.bind(this);
        this.saveCompany = this.saveCompany.bind(this);
        this.getAllcompany = this.getAllcompany.bind(this);
        this.deleteCompany = this.deleteCompany.bind(this);
        this.deleteCompanyApi = this.deleteCompanyApi.bind(this);
        this.getDataUnidade = this.getDataUnidade.bind(this);

        this.addUserCompany = this.addUserCompany.bind(this);
        
        this.addUserCompanyApi = this.addUserCompanyApi.bind(this);
        this.removeUserCompanyApi = this.removeUserCompanyApi.bind(this);

        this.searchManagersCompanyAll = this.searchManagersCompanyAll.bind(this);
        this.searchUserCompanyTerm = this.searchUserCompanyTerm.bind(this);

        this.searchUserClassRoom = this.searchUserClassRoom.bind(this);  

        window.unidades = this;

    }

    
    async addUserCompanyApi(id){

        var add = await this.helper.company().addUserCompany(this.state.editCompany,id);

        if(add.status === 200){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">{this.props.translate['o_usuario_foi_adicinado_a_unidade']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.searchUserClassRoom();
            this.getDataUnidade(this.state.editCompany);

        }
        
        if(add.status === 400){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['ops']}.</span><span data-notify="message">{add.data.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }

    }
    
    async removeUserCompanyApi(id){
 
        var add = await this.helper.company().deleteUserCompany(this.state.editCompany,id);

        if(add.status === 200){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">{this.props.translate['o_usuario_foi_removido_da_unidade']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.searchUserClassRoom();
            this.getDataUnidade(this.state.editCompany);

        }
        
        if(add.status === 400){

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['ops']}.</span><span data-notify="message">{add.data.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

        }

    }
 

    //Para pequisar o usuário
    async searchUserClassRoom(){

        if(this.state.inputSearchUser === ""){ this.searchManagersCompanyAll(); }else{ 
            
            this.searchUserCompanyTerm(); 
        
        }

    }

    //Para adicionar usuarios no classroom
    async addUserCompany(id){ 
        
        await this.setState({addUserCompany:true,editCompany:id,inputSearchUser:""}); 
        this.searchManagersCompanyAll(); 
    
    }

    //Para buscar os alunos ja cadastrados na turma
    async searchManagersCompanyAll(){
        
        this.setState({loadingSearchUsers:true});

        await api.searchManagersCompanyAll(this.state.editCompany).then((res) => {

          this.setState({loadingSearchUsers:false,searchUser:res.data});

        }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        this.setState({loadingSearchUsers:false});
        
        });

    }

    //Para pesquisar alunos
    async searchUserCompanyTerm(){
         
        this.setState({loadingSearchUsers:true});

        await api.searchManagersCompanyTerm(this.state.editCompany,this.state.inputSearchUser).then((res) => {

          this.setState({loadingSearchUsers:false,searchUser:res.data});

        }).catch(error => {

        console.log(error.data);
        console.log(error);
        console.log(error.response);
        this.setState({loadingSearchUsers:false});
        
        });

    }


    //para atualizar os dados da unidade no banco de dados
    async putCompany(){

        var data = JSON.parse(JSON.stringify(this.state.dialog));
        delete data.id;

        var company = await this.helper.company().putCompany(this.state.editCompany,data);

        if(company.status === 200){

            this.setState({openDialogCompanyCreate:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">{this.props.translate['as_alteracoes_foram_salvas']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.getAllcompany();

        }

        if(company.status === 400){ 
            
            this.setState({erroCompany:""}); 
            this.setState({erroCompany:company.data.data.msg}); 
    
        }

    }

    //para cadastrar a unidade no banco de dados
    async postCompany(){

        var data = JSON.parse(JSON.stringify(this.state.dialog));
        delete data.id;

        var company = await this.helper.company().postCompany(data);

        if(company.status === 200){

            this.setState({openDialogCompanyCreate:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">{this.props.translate['a_unidade_foi_cadastrada']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.getAllcompany();

        }

        if(company.status === 400){ 
            
            this.setState({erroCompany:""}); 
            this.setState({erroCompany:company.data.data.msg}); 
    
        }
 
    }

    //Para abrir o modal de anteção da unidade
    async deleteCompany(id){
        
        this.setState({openDialogRemoveCompnay:true,removeCompany:id});

    }

    async deleteCompanyApi(){

        this.setState({openDialogRemoveCompnay:false});

        var company = await this.helper.company().deleteCompany(this.state.removeCompany);

        if(company.status === 200){

            this.setState({openDialogCompanyCreate:false});

            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['sucesso']}.</span><span data-notify="message">{this.props.translate['a_unidade_foi_removida']}</span></div>),type: 'success',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);

            this.getAllcompany();

        }

        if(company.status === 400){ 
           
            let options = {place: "tr",message: (<div className="alert-text"><span className="alert-title" data-notify="title">{this.props.translate['ops']}.</span><span data-notify="message">{company.data.data.msg}</span></div>),type: 'danger',icon: "ni ni-check-bold",autoDismiss: 7};
            this.refs.notificationAlert.notificationAlert(options);
            
        }

    }

    //para editar os dados da unidade
    editCompany(id){

        this.setState({erroCompany:"",textButtonDialogClassRoom:this.props.translate['salvar_alteracoes'],dialog:{name:"",groupname:"",license:""},openDialogCompanyCreate:true,txtModalCreateCompany:this.props.translate['criar_unidade']});

        this.state.companys.forEach(function(item,index,array){

            if(parseInt(item.id) === parseInt(id)){ 

            var item2 = JSON.stringify(item);
            item2 = JSON.parse(item2);

                delete item2.createDate;
                delete item2.updateDate;
                delete item2.users;
                delete item2.classrooms;
                delete item2.managers;
                
                this.setState({editCompany:id,dialog:item2,txtModalCreateCompany:this.props.translate['editar_unidade']});

            } 

        }.bind(this));

    }

    //Para cadastrar um usuário novo
    newCompany(){

        this.setState({erroCompany:"",editCompany:"",dialog:{name:"",groupname:"",license:""},textButtonDialogClassRoom:this.props.translate['cadastrar'],openDialogCompanyCreate:true,txtModalCreateCompany:this.props.translate['criar_unidade']});
        
    }

    //quando clicar no botão
    saveCompany(e){

        e.preventDefault();
        this.setState({loading:true});
        if(this.state.editCompany === ""){ this.postCompany(); }else{ this.putCompany(); }

    }



    //Para pegar todas turmdas do sistema
    async getAllcompany(){

        var company = await this.helper.company().getListCompany();

        if(company.status === 200){

            this.setState({companys:company.data});

            for (let index = 0; index < company.data.length; index++) {
                
                await this.getDataUnidade(company.data[index].id);
                
            }

        }

    }

    //para criar um novo usuario no sistem,
    async componentDidMount(){

        await this.getAllcompany();

    }
    
    //Para pegar os usuarios e as turmas
    async getDataUnidade(id){
 
        var getUsersCompany = await this.helper.company().getUsersCompany(id);
        var getClassRoomCompany = await this.helper.company().getClassRoomCompany(id);
        var getManagersCompany = await this.helper.company().searchManagersCompanyAll(id);
        var index  = this.state.companys.map(function(x) {return x.id; }).indexOf(id);

        var companys = this.state.companys;
        companys[index].users = getUsersCompany.data;
        companys[index].classrooms = getClassRoomCompany.data;
        companys[index].managers = getManagersCompany.data;   
        
        this.setState({companys:companys});

        return true;

    }
 
 
  render(){

    const handleChange = name => event => {
        var dialog = this.state.dialog;
        dialog[name] = event.target.value;
        this.setState({dialog:dialog});
    }

    return(
      <React.Fragment>
        <div className="rna-wrapper"><NotificationAlert ref="notificationAlert" /></div>
        <SimpleHeader name={this.props.translate['unidades']} way={[{name:this.props.translate['unidades'],url:"/company"}]} buttons={<React.Fragment></React.Fragment>} />
        <Container className="mt--6" fluid>
          <Card>
            <CardHeader className="border-0">
              <Row>
                <Col xs="6"><h3 className="mb-0">{this.props.translate['lista_de_unidades']}</h3></Col>
                {
                  (this.props.permission.indexOf(26) !== -1) &&
                  <React.Fragment>
                  <Col className="text-right" xs="6">
                    <Button className="btn-neutral btn-round btn-icon" color="default" onClick={() => this.newCompany()} size="sm">
                      <span className="btn-inner--icon mr-1"><i className="fas fa-plus"></i></span>
                      <span className="btn-inner--text">{this.props.translate['criar_unidade']}</span>
                    </Button>
                  </Col>
                  </React.Fragment>
                }
              </Row>
            </CardHeader>
            <Table className="align-items-center table-flush" responsive>
              <thead className="thead-light">
                <tr>
                  <th>id</th>
                  <th>{this.props.translate['nome']}</th>
                  <th>{this.props.translate['grupo']}</th>
                  <th>Licença</th>
                  <th><center>{this.props.translate['usuarios']}</center></th>
                  <th><center>{this.props.translate['turmas']}</center></th>
                  <th><center>{this.props.translate['gestores']}</center></th>
                  <th>{this.props.translate['acoes']}</th>
                </tr>
              </thead>
              <tbody>
              {

                this.state.companys.map((value,index) => {

                  var key = "user_key_"+value.id;
                  var tooltip = "tooltip_user_key_"+value.id;
 
                  return(<tr key={key}>
                      <td>{value.id}</td>
                      <td>{value.name}</td>
                      <td>{value.groupname}</td>
                      <td>{value.license}</td>
                      
                      <td>
                        <center>
                            {
                                
                                (value.users === undefined) &&
                                <ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} />

                            }
                            {
                                
                                (value.users !== undefined) &&
                                <center>{value.users.length}</center>

                            }
                        </center>
                      </td> 
                      <td>
                        <center>
                            {
                                
                                (value.classrooms === undefined) &&
                                <ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} />

                            }
                            {
                                
                                (value.classrooms !== undefined) &&
                                <center>{value.classrooms.length}</center>

                            }
                        </center>
                      </td> 
                      <td>
                        <center>
                            {
                                
                                (value.managers === undefined) &&
                                <ClipLoader sizeUnit={"px"} size={15} color={'#adb5bd'} loading={true} />

                            }
                            {
                                
                                (value.managers !== undefined) &&
                                <center>{value.managers.length}</center>

                            }
                        </center>
                      </td> 
                      <td className="table-actions">
                        
                        <font style={{cursor:"pointer"}} onClick={ () => this.editCompany(value.id)} className="table-action" id={tooltip}  ><i className="far fa-edit"></i></font>
                        <UncontrolledTooltip delay={0} target={tooltip}>{this.props.translate['editar_unidade']}</UncontrolledTooltip>

                        <font style={{cursor:"pointer"}} onClick={ () => this.addUserCompany(value.id)} className="table-action" id={tooltip+"aa"}  ><i className="fas fa-users"></i></font>
                        <UncontrolledTooltip delay={0} target={tooltip+"aa"}>{this.props.translate['gestores_da_unidade']}</UncontrolledTooltip>
                             
                        <font style={{cursor:"pointer"}} onClick={ () => this.deleteCompany(value.id)} className="table-action" id={tooltip+"qr_code"}  ><i className="far fa-trash-alt"></i></font>
                        <UncontrolledTooltip delay={0} target={tooltip+"qr_code"}>{this.props.translate['remover_unidade']}</UncontrolledTooltip>
                            
                      </td>
                    </tr>)

                  })}

              </tbody>
            </Table>
            {

                (this.state.companys.length === 0) &&
                <center><h3 style={{margin:'30px 0px'}}>{this.props.translate['nenhuma_unidade_foi_encontrada']}</h3></center>
                
            }
          </Card>
        </Container>

        <Modal className="modal-dialog-centered modal-danger" contentClassName="bg-gradient-danger" isOpen={this.state.openDialogRemoveCompnay} >
          <div className="modal-header">
            <h6 className="modal-title" id="modal-title-notification">Sua atenção é requirida.</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({openDialogRemoveCompnay:false}) }>
              <span aria-hidden={true} onClick={ () => this.setState({openDialogRemoveUser:false}) }>×</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="py-3 text-center">
              <i className="fas fa-exclamation-triangle fa-x3" style={{fontSize:'45px'}}></i>
              <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
              <p>Se você prosseguir, todos os dados da unidade serão removidos, essa ação é irreversível.</p>
            </div>
          </div>
          <div className="modal-footer">
            <Button className="btn-white" color="default" type="button" onClick={ () => this.deleteCompanyApi() }>Ok, Remova</Button>
            <Button className="text-white ml-auto" color="link" data-dismiss="modal" type="button" onClick={ () => this.setState({openDialogRemoveCompnay:false}) } >
              Cancelar e fechar
            </Button>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={this.state.openDialogCompanyCreate} >
          <div className="modal-body p-0">
            <Card className="bg-secondary border-0 mb-0">
            <div className="modal-header">
            <h6 className="modal-title" id="modal-title-default">{this.state.txtModalCreateCompany}</h6>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button"  onClick={() => this.setState({openDialogCompanyCreate:false})}><span aria-hidden={true}>×</span> </button>
          </div>
              <CardBody className="px-lg-5">
                <Form autoComplete="off" role="form" type="post" onSubmit={ (event) => this.saveCompany(event) }>
                  {
                    (this.state.erroCompany !== "") &&
                    <Alert color="danger" className="animated tada"><strong>{this.props.translate['ops']}!</strong> {this.state.erroCompany}</Alert>
                  }
                  <Row>
                  <Col md={12}>
                        <h5>{this.props.translate['nome']}</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedName})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('name')} value={ this.state.dialog.name } onFocus={() => this.setState({ focusedName: true }) } onBlur={() => this.setState({ focusedName: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <h5>{this.props.translate['grupo']}</h5>
                        <FormGroup className={classnames("mb-3",{focused:this.state.focusedGroup})} >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('groupname')} value={ this.state.dialog.groupname } onFocus={() => this.setState({ focusedGroup: true }) } onBlur={() => this.setState({ focusedGroup: false }) } />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <h5>Licença erp:</h5>
                        <FormGroup >
                          <InputGroup className="input-group-merge input-group-alternative">
                            <InputGroupAddon addonType="prepend"><InputGroupText><i className="ni ni-caps-small" /></InputGroupText></InputGroupAddon>
                            <Input type="text" onChange={handleChange('license')} value={ this.state.dialog.license }  />
                          </InputGroup>
                        </FormGroup>
                      </Col>
                      <Col md={12}>
                        <h5>Login apenas com Google:</h5>
                        <FormGroup>
                            <InputGroup className="input-group-merge input-group-alternative">
                                <InputGroupAddon addonType="prepend">
                                    <InputGroupText>
                                        <i className="fab fa-google" />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                    type="select"
                                    onChange={handleChange('login_google')}
                                    value={this.state.dialog.login_google}
                                >
                                    <option value="0">Não</option>
                                    <option value="1">Sim</option>
                                </Input>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                  </Row>
                  <div className="text-center">
                    <Button className="my-4" color="default" type="button" onClick={ () => this.setState({openDialogCompanyCreate:false})}>{this.props.translate['cancelar']}</Button>
                    <Button className="my-4" color="primary" type="submit">{this.state.textButtonDialogClassRoom}</Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Modal>

        <Modal className="modal-dialog-centered" isOpen={this.state.addUserCompany} >
          <div className="modal-header bg-secondary">
            <h5 className="modal-title" id="exampleModalLabel">{this.props.translate['adicionar_ou_remover_gestores']}</h5>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={() => this.setState({addUserCompany:false})} >
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body bg-secondary">
              <FormGroup>
              <h5>{this.props.translate['pesquise_o_nome_email_ou_usario_abaixo']}</h5>
                <InputGroup className="input-group-alternative mb-4">
                  <InputGroupAddon addonType="prepend">
                    <InputGroupText><i className="ni ni-zoom-split-in" /></InputGroupText>
                  </InputGroupAddon>
                  <Input className="form-control-alternative" placeholder={this.props.translate['procurar']} type="text" onChange={ async (event)  =>  { await this.setState({inputSearchUser:event.target.value}); this.searchUserClassRoom(); }} value={this.state.inputSearchUser} />
                </InputGroup>
              </FormGroup>
              <div className="listUserAddClassRoom">
                <center hidden>
                 <BounceLoader hidden sizeUnit={"px"} size={50} color={'#172b4d'} loading={this.state.loadingSearchUsers} />
                </center>
                {
                <React.Fragment>
                  {

                    this.state.searchUser.map((value,index) => {

                      return(<div key={'aaq_'+value.id} className="d-flex align-items-center card-header">
                              <div className="d-flex align-items-center">
                                <img style={{backgroundColor:'#adb5bd00'}} alt="..." className="avatar" src={value.photo} />
                                <div className="mx-3">
                                  <font className="text-dark font-weight-600 text-sm">{value.name} {value.lastName}</font>
                                  <small className="d-block text-muted">{value.user}</small>
                                </div>
                              </div>
                              <div className="text-right ml-auto">
                                {
                                  (value.incompany === undefined) &&
                                  <button onClick={ () => this.addUserCompanyApi(value.id) } type="button" className="btn-icon btn btn-primary btn-sm">
                                    <span className="btn-inner--icon"><i className="ni ni-fat-add"></i></span>
                                    <span className="btn-inner--text">{this.props.translate['adicionar']}</span>
                                  </button>
                                }
                                {
                                  (value.incompany !== undefined) &&
                                  <button onClick={ () => this.removeUserCompanyApi(value.id) } type="button" className="btn-icon btn btn-danger btn-sm">
                                    <span className="btn-inner--icon"><i className="ni ni-fat-remove"></i></span>
                                    <span className="btn-inner--text">{this.props.translate['remover']}</span>
                                  </button>
                                }
                              </div>
                            </div>)

                    })
                  }
                  { 

                    (this.state.searchUser.length === 0) &&
                    <center>{this.props.translate['nenhum_usuario_foi_encontrado']}</center>

                  }
                </React.Fragment>    
              }
              </div>
          </div>
          <div className="modal-footer bg-secondary">
            <Button color="default" data-dismiss="modal" type="button" onClick={() => this.setState({addUserCompany:false})}>{this.props.translate['fechar']}</Button>
          </div>
        </Modal>



        
      </React.Fragment>
    );
  }
}

export default connect(state =>({

  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,

}))(Users);