import React from 'react';
import {
    Card,
    CardBody,
    Button,
    Row,
    Col
} from "reactstrap";
import api from '../../../../Api';
import { connect } from 'react-redux';
import ModalTaskForm from './ModalTaskForm';
import moment from 'moment';
import { SharedSlidingPane } from '../../../../components/SharedSlidingPage';
import './styles/ModalTaskTemplates.css';

class ModalTaskTemplates extends React.Component {
    static defaultProps = {
        translate: {},
        units: [],
        lessons: [],
        taskTypes: []
    };

    state = {
        templates: [],
        loading: false,
        selectedTemplate: null,
        showDetails: false,
        showTaskForm: false,
        templateForForm: null,
        unitsMap: {}, 
        lessonsMap: {}, 
        taskTypes: [] 
    };

    componentDidMount() {
        this.loadTemplates();
        this.loadTaskTypes(); 
    }

    loadTemplates = async () => {
        this.setState({ loading: true });
        try {
            const response = await api.getTaskTemplates();
            console.log('Templates carregados:', response.data);
            this.setState({ templates: response.data });
        } catch (error) {
            console.error('Erro ao carregar templates:', error);
            window.swal.fire('Erro!', 'Não foi possível carregar os templates.', 'error');
        } finally {
            this.setState({ loading: false });
        }
    };

    loadTaskTypes = async () => {
        try {
            const response = await api.getAllTypeTasks();
            this.setState({ taskTypes: response.data });
        } catch (error) {
            console.error('Erro ao carregar tipos de tarefa:', error);
        }
    };

    useTemplate = async (template) => {
        try {
            console.log('Template selecionado:', template);
            console.log('Dados sendo enviados:', {
                class_room: this.props.classroom,
                name: template.name,
                dataInicial: template.dataInicial,
                dataFinal: template.dataFinal,
                obs: template.obs,
                status: template.status,
                type_send: template.type_send,
                type: template.type,
                unit: template.unit,
                lesson: template.lesson
            });

            await api.createTaskFromTemplate(template.id, {
                class_room: this.props.classroom,
                name: template.name,
                dataInicial: template.dataInicial,
                dataFinal: template.dataFinal,
                obs: template.obs,
                status: template.status,
                type_send: template.type_send,
                type: template.type,
                unit: template.unit,
                lesson: template.lesson
            });
            
            window.swal.fire('Sucesso!', 'Tarefa criada com sucesso!', 'success');
            this.props.onClose();
            this.props.onTaskCreated();
        } catch (error) {
            console.error('Erro detalhado:', error.response?.data || error);
            window.swal.fire('Erro!', 'Não foi possível criar a tarefa.', 'error');
        }
    };

    handleUseTemplate = (template) => {
        const templateTask = {
            name: `Cópia de ${template.name}`,
            dataInicial: moment(),
            dataFinal: moment().add(7, 'days'),
            obs: template.obs,
            status: 'pendente',
            type_send: template.type_send,
            type: template.type,
            isTemplate: 0,
            unit: template.unit,
            lesson: template.lesson
        };

        this.setState({ 
            showTaskForm: true,
            templateForForm: templateTask
        });
    };

    handleCreateFromTemplate = async (taskData) => {
        try {
            const response = await api.postTask({
                ...taskData,
                class_room: this.props.classroom
            });
            
            window.swal.fire('Sucesso!', 'Tarefa criada com sucesso!', 'success');
            this.setState({ showTaskForm: false });
            this.props.onClose();
            if (this.props.onTaskCreated) {
                this.props.onTaskCreated();
            }
        } catch (error) {
            console.error('Erro ao criar tarefa:', error);
            window.swal.fire('Erro!', 'Não foi possível criar a tarefa.', 'error');
        }
    };

    handleCloseTaskForm = () => {
        this.setState({ showTaskForm: false });
    };

    toggleDetails = (template = null) => {
        this.setState({ 
            selectedTemplate: template,
            showDetails: template !== null
        });
    };

    render() {
        const { translate } = this.props;
        const { templates, loading, selectedTemplate, showDetails, showTaskForm, templateForForm } = this.state;

        let content = null;

        if (showDetails) {
            content = (
                <Card className="bg-secondary border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        {selectedTemplate && (
                            <div className="template-details">
                                <div className="template-header">
                                    <i className="ni ni-single-copy-04 template-header-icon"></i>
                                    <h3 className="template-header-title">{translate?.detalhes_template || "Detalhes do Template"}</h3>
                                </div>

                                <div className="template-info-grid">
                                    <div className="template-info-full">
                                        <div className="template-details-section">
                                            <div className="template-label">{translate?.nome || "Nome"}</div>
                                            <div className="template-value">{selectedTemplate.name}</div>
                                        </div>
                                    </div>

                                    <div className="template-info-full">
                                        <div className="template-details-section">
                                            <div className="template-label">{translate?.descricao || "Descrição"}</div>
                                            <div className="template-value">{selectedTemplate.obs || "Sem descrição"}</div>
                                        </div>
                                    </div>

                                    <div className="template-details-section">
                                        <div className="template-label">{translate?.tipo || "Tipo"}</div>
                                        <div className="template-value">{selectedTemplate.type || "Não especificado"}</div>
                                    </div>

                                    <div className="template-details-section">
                                        <div className="template-label">{translate?.tipo_envio || "Tipo de Envio"}</div>
                                        <div className="template-value">{selectedTemplate.type_send || "Não especificado"}</div>
                                    </div>

                                    <div className="template-details-section">
                                        <div className="template-label">{translate?.unidade || "Unidade"}</div>
                                        <div className="template-value">{selectedTemplate.unit || "Não especificado"}</div>
                                    </div>

                                    <div className="template-details-section">
                                        <div className="template-label">{translate?.licao || "Lição"}</div>
                                        <div className="template-value">{selectedTemplate.lesson || "Não especificado"}</div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CardBody>
                </Card>
            );
        } else if (showTaskForm) {
            content = (
                <ModalTaskForm
                    initialData={templateForForm}
                    onSubmit={this.handleCreateFromTemplate}
                    onCancel={this.handleCloseTaskForm}
                    units={this.props.units}
                    lessons={this.props.lessons}
                    classroom={this.props.classroom}
                    translate={this.props.translate}
                    taskTypes={this.state.taskTypes} // Add this line
                />
            );
        } else {
            content = (
                <Card className="bg-secondary border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        {loading ? (
                            <div className="text-center py-4">
                                <i className="fas fa-spinner fa-spin fa-2x"></i>
                            </div>
                        ) : templates.length === 0 ? (
                            <div className="text-center py-4">
                                <p className="text-muted">
                                    {translate?.nenhum_template || "Nenhum template disponível"}
                                </p>
                            </div>
                        ) : (
                            <Row>
                                {templates.map(template => (
                                    <Col key={template.id} xs="12" className="mb-4">
                                        <Card className="shadow-sm">
                                            <CardBody>
                                                <div className="d-flex justify-content-between align-items-start">
                                                    <div>
                                                        <h5 className="mb-2">{template.name}</h5>
                                                        <p className="text-muted small mb-0">
                                                            {template.obs || "Sem descrição"}
                                                        </p>
                                                    </div>
                                                    <div>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            className="mr-2"
                                                            onClick={() => this.toggleDetails(template)}
                                                        >
                                                            <i className="fas fa-eye mr-1"></i>
                                                            {translate?.visualizar || "Visualizar"}
                                                        </Button>
                                                        <Button
                                                            color="primary"
                                                            size="sm"
                                                            onClick={() => this.handleUseTemplate(template)}
                                                        >
                                                            <i className="fas fa-plus mr-1"></i>
                                                            {translate?.usar_template || "Usar"}
                                                        </Button>
                                                    </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        )}
                    </CardBody>
                </Card>
            );
        }

        return content;
    }
}

export default connect(state => ({
    translate: state.translate
}))(ModalTaskTemplates);
