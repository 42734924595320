import React from "react";
import { connect } from "react-redux";
import moment from 'moment';
import 'moment/locale/pt-br';
import ClipLoader from "react-spinners/ClipLoader";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import api from "../../../../Api.js";
import { SharedSlidingPane } from '../../../../components/SharedSlidingPage.jsx';

import { ModalTaskCard } from './ModalTaskCard';
import { ModalTaskFilters } from './ModalTaskFilters';
import { ModalTaskDelete } from './ModalTaskDelete';
import ModalTaskUserSend from "./ModalTaskUserSend";
import ModalUserTasks from "./ModalUserTasks";
import ModalTaskDeliveries from "./ModalTaskDeliveries";
import ModalTaskTemplates from './ModalTaskTemplates';
import ModalTaskForm from './ModalTaskForm';

moment.locale('pt-br');

class ModalTask extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tasks: [],
            taskTypes: [],
            userTasks: [],
            units: [],
            lessons: [],
            load: false,
            dialog: {},
            openRegisterModal: false,
            openDialogRemove: false,
            showAllInfo: false,
            task: {
                name: '',
                dataInicial: moment(),
                dataFinal: moment(),
                obs: '',
                status: 'pendente',
                type_send: 'link',
                type: '',
                isTemplate: 0,
                unit: ''
            },
            error: null,
            selectedTask: null,
            openTaskUserSendModal: false,
            openUserTasksModal: false,
            openDeliveriesModal: false,
            selectedUnit: '',
            selectedLesson: '',
            showFilters: false,
            openMainModal: props.isOpen,
            taskDeliveryCounts: {},
            openTemplatesModal: false,
            pendingDeliveryCounts: {},
            openObservationModal: false,
            selectedObservation: '',
            openRegisterModal: false,
            openDeleteModal: false,
            searchQuery: '', 
        };
    }

    componentDidMount() {
        this.getTasks();
        this.getAcessoClassRoom();
        this.getAllTypeTasks();
        this.getUserTasks();
        this.getAllLessons();
    }

    getTasks = async () => {
        try {
            const response = await api.getTasksClassroom(this.props.classroom);
            this.setState({ tasks: response.data, load: true }, () => {
                this.updateAllTaskDeliveryCounts();
            });
        } catch (error) {
            console.error("Erro ao buscar tarefas:", error);
        }
    }

    getAllTypeTasks = async () => {
        try {
            const response = await api.getAllTypeTasks();
            this.setState({ taskTypes: response.data });
        } catch (error) {
            console.error("Erro ao carregar tipos de tarefa:", error);
        }
    }

    getAcessoClassRoom = () => {
        this.setState({ units: this.props.classroomState.units });
    }

    getUserTasks = async () => {
        if (!this.props.isTeacher) {
            try {
                const response = await api.getTaskUserSendByUser(this.props.user.id);
                this.setState({ userTasks: response.data });
            } catch (error) {
                console.error("Erro ao buscar tarefas do usuário:", error);
            }
        }
    }

    getAllLessons = () => {
        const allLessons = this.props.classroomState.units.reduce((acc, unit) => {
            return [...acc, ...unit.lessons];
        }, []);
        this.setState({ lessons: allLessons });
    }

    handleSubmit = async (taskData) => {
        try {
            const data = {
                ...taskData,
                class_room: this.props.classroom
            };

            if (this.state.dialog.id) {
                await api.putTask(this.state.dialog.id, data);
                window.swal.fire('Sucesso!', 'As alterações foram salvas.', 'success');
            } else {
                await api.postTask(data);
                window.swal.fire('Sucesso!', 'A tarefa foi cadastrada', 'success');
            }

            this.setState({ openRegisterModal: false });
            this.getTasks();
        } catch (error) {
            console.error("Erro ao salvar tarefa:", error);
            window.swal.fire('Ops!', error.response?.data?.msg || 'Erro ao salvar tarefa', 'error');
        }
    }

    handleDelete = async () => {
        try {
            await api.deleteTask(this.state.dialog.id);
            this.setState({ openDialogRemove: false, dialog: {} });
            this.getTasks();
            window.swal.fire('Sucesso!', 'A tarefa foi removida.', 'success');
        } catch (error) {
            window.swal.fire('Ops!', error.response?.data?.msg || 'Erro ao remover tarefa', 'error');
        }
    }

    toggleShowAllInfo = () => {
        this.setState(prevState => ({ showAllInfo: !prevState.showAllInfo }));
    }

    toggleDeliveriesModal = (task = null) => {
        if (!this.state.openDeliveriesModal || task) {
            this.setState({
                openDeliveriesModal: !this.state.openDeliveriesModal,
                selectedTask: task 
            });
        } else {
            this.setState({
                openDeliveriesModal: false,
                selectedTask: null
            });
        }
    }

    toggleTaskUserSendModal = (task = null) => {
        this.setState(prevState => ({
            openTaskUserSendModal: !prevState.openTaskUserSendModal,
            selectedTask: task
        }), () => {
            if (!task) {
                this.getTasks();
                this.getUserTasks();
            }
        });
    }

    toggleUserTasksModal = () => {
        this.setState(prevState => ({
            openUserTasksModal: !prevState.openUserTasksModal
        }));
    }

    handleUnitChange = (e) => {
        const unitId = e.target.value;
        const selectedUnit = this.state.units.find(u => u.id.toString() === unitId);
        
        this.setState({ 
            selectedUnit: unitId,
            selectedLesson: '',
        });
    }

    getFilteredLessons = () => {
        const { selectedUnit, units } = this.state;
        if (!selectedUnit) return this.state.lessons;
        
        const selectedUnitObj = units.find(u => u.id.toString() === selectedUnit);
        return selectedUnitObj ? selectedUnitObj.lessons : [];
    }

    handleLessonChange = (e) => {
        this.setState({ selectedLesson: e.target.value });
    }

    toggleFilters = () => {
        this.setState(prevState => ({ showFilters: !prevState.showFilters }));
    }

    filterAndGroupTasks = () => {
        const { tasks, selectedUnit, selectedLesson, userTasks, searchQuery } = this.state;
        
        const filteredTasks = tasks.filter(task => {
            const matchesUnit = !selectedUnit || task.unit === selectedUnit;
            const matchesLesson = !selectedLesson || task.lesson === selectedLesson;
            const matchesSearch = !searchQuery || 
                task.name.toLowerCase().includes(searchQuery.toLowerCase());
            
            if (this.props.isTeacher) {
                return matchesUnit && matchesLesson && matchesSearch;
            }

            const isDelivered = userTasks.some(userTask => userTask.task === task.id);
            return matchesUnit && matchesLesson && !isDelivered && matchesSearch;
        });

        return filteredTasks.reduce((acc, task) => {
            const unitId = task.unit || 'no-unit';
            if (!acc[unitId]) acc[unitId] = [];
            acc[unitId].push(task);
            return acc;
        }, {});
    }

    updateAllTaskDeliveryCounts = async () => {
        const { tasks } = this.state;
        for (const task of tasks) {
            await this.updateTaskDeliveryCount(task.id);
        }
    }

    updateTaskDeliveryCount = async (taskId) => {
        try {
            const response = await api.getTaskSubmissionsWithDetails(taskId);
            const submissions = response.data;
            const totalSubmissions = submissions.length;
            const pendingSubmissions = submissions.filter(submission => 
                !submission.points
            ).length;

            this.setState(prevState => ({
                taskDeliveryCounts: {
                    ...prevState.taskDeliveryCounts,
                    [taskId]: totalSubmissions
                },
                pendingDeliveryCounts: {
                    ...prevState.pendingDeliveryCounts,
                    [taskId]: pendingSubmissions
                }
            }));
        } catch (error) {
            console.error("Erro ao buscar contagem de entregas:", error);
        }
    }

    handleOpenObservation = (observation) => {
        this.setState({ 
            openObservationModal: true, 
            selectedObservation: observation 
        });
    }

    toggleMainModal = () => {
        this.setState(prevState => ({ 
            openMainModal: !prevState.openMainModal 
        }), () => {
            if (!this.state.openMainModal) {
                this.props.onClose();
            }
        });
    }

    toggleRegisterModal = (task = null) => {
        this.setState(prevState => ({
            openRegisterModal: !prevState.openRegisterModal,
            dialog: task || {},
            task: task ? {
                ...task,
                dataInicial: moment(task.dataInicial),
                dataFinal: moment(task.dataFinal)
            } : {
                name: '',
                dataInicial: moment(),
                dataFinal: moment(),
                obs: '',
                status: 'pendente',
                type_send: 'link',
                type: '',
                isTemplate: 0,
                unit: '',
                lesson: ''
            }
        }));
    }

    toggleDeleteModal = (task = null) => {
        this.setState(prevState => ({
            openDeleteModal: !prevState.openDeleteModal,
            dialog: task || {}
        }));
    }

    toggleTemplatesModal = () => {
        this.setState(prevState => ({ 
            openTemplatesModal: !prevState.openTemplatesModal 
        }));
    }

    handleSearch = (e) => {
        this.setState({ searchQuery: e.target.value });
    }

    renderTaskGroups = () => {
        const { load, units, showAllInfo, taskTypes, userTasks } = this.state;

        if (!load) {
            return <center style={{ marginTop: '20px' }}><ClipLoader color={'#000'} loading={true} /></center>;
        }

        return Object.entries(this.filterAndGroupTasks()).map(([unitId, unitTasks]) => {
            const unit = units.find(u => u.id === parseInt(unitId));
            const unitName = unit ? unit.name : 'Sem Unit';

            return (
                <div key={unitId} className="mb-5">
                    <h4 className="mb-4 d-flex align-items-center">
                        <i className="ni ni-building mr-2"></i>
                        {unitName}
                        <span className="ml-2 badge badge-primary">{unitTasks.length}</span>
                    </h4>
                    
                    <div className="row">
                        {unitTasks.map((task, index) => (
                            <ModalTaskCard
                                key={index}
                                task={task}
                                isTeacher={this.props.isTeacher}
                                showAllInfo={showAllInfo}
                                units={units}
                                lessons={this.state.lessons}
                                taskTypes={taskTypes}
                                userTasks={userTasks}
                                deliveryCounts={this.state.taskDeliveryCounts}
                                pendingCounts={this.state.pendingDeliveryCounts}
                                onEdit={(task) => this.toggleRegisterModal(task)}
                                onDelete={(task) => this.toggleDeleteModal(task)}
                                onDeliveries={this.toggleDeliveriesModal}
                                onDeliver={this.toggleTaskUserSendModal}
                                translate={this.props.translate}
                                onOpen={this.handleOpenObservation}
                            />
                        ))}
                    </div>
                </div>
            );
        });
    }

    render() {
        const { 
            tasks,
            taskTypes,
            load,
            dialog,
            error,
            showAllInfo,
            showFilters,
            selectedUnit,
            selectedLesson,
            units,
            lessons,
            selectedTask,
            selectedObservation,
            openMainModal,
            openRegisterModal,
            openDeleteModal,
            openTaskUserSendModal,
            openUserTasksModal,
            openDeliveriesModal,
            openTemplatesModal,
            openObservationModal,
            task
        } = this.state;

        const { 
            translate, 
            isTeacher,
            permissions = [] 
        } = this.props;

        return (
            <React.Fragment>
                {/* Painel de tarefas principal */}
                <SharedSlidingPane
                    isOpen={openMainModal}
                    title="Tarefas"
                    width="70%"
                    loading={!load}
                    onClose={this.toggleMainModal}
                >
                    <ModalTaskFilters
                        showFilters={showFilters}
                        selectedUnit={selectedUnit}
                        selectedLesson={selectedLesson}
                        units={units}
                        lessons={lessons}
                        onUnitChange={this.handleUnitChange}
                        onLessonChange={this.handleLessonChange}
                        onToggleFilters={this.toggleFilters}
                        onToggleShowAll={this.toggleShowAllInfo}
                        showAllInfo={showAllInfo}
                        isTeacher={isTeacher}
                        onNewTask={() => this.toggleRegisterModal()}
                        onShowTemplates={this.toggleTemplatesModal}
                        onShowUserTasks={this.toggleUserTasksModal}
                        translate={translate}
                        searchQuery={this.state.searchQuery}
                        onSearch={this.handleSearch}
                        canCreate={permissions.indexOf(46) !== -1}  
                        canEdit={permissions.indexOf(47) !== -1}   
                        canDelete={permissions.indexOf(48) !== -1}  
                    />
                    <div className="mt-4">
                        {this.renderTaskGroups()}
                    </div>
                </SharedSlidingPane>

                {(permissions.indexOf(46) !== -1 || permissions.indexOf(47) !== -1) && (
                    <SharedSlidingPane
                        isOpen={openRegisterModal}
                        title={dialog.id ? "Editar Tarefa" : "Nova Tarefa"}
                        onClose={() => this.toggleRegisterModal(null)}
                    >
                        <ModalTaskForm
                            initialData={task}
                            onSubmit={this.handleSubmit}
                            onCancel={() => this.toggleRegisterModal(null)}
                            units={units}
                            lessons={lessons}
                            translate={translate}
                            error={error}
                            taskTypes={taskTypes}
                            isEditing={!!dialog.id}
                            canSubmit={dialog.id ? permissions.indexOf(47) !== -1 : permissions.indexOf(46) !== -1}
                        />
                    </SharedSlidingPane>
                )}

                {/* Painel de deletar tarefa */}
                {permissions.indexOf(48) !== -1 && (
                    <SharedSlidingPane
                        isOpen={openDeleteModal}
                        title="Remover Tarefa"
                        width="400px"
                        onClose={() => this.toggleDeleteModal(null)}
                    >
                        <ModalTaskDelete
                            isOpen={openDeleteModal}
                            onClose={() => this.toggleDeleteModal(null)}
                            onConfirm={this.handleDelete}
                        />
                    </SharedSlidingPane>
                )}

                {/* Painel de enviar tarefa*/}
                <SharedSlidingPane
                    isOpen={openTaskUserSendModal}
                    title="Enviar Tarefa"
                    onClose={this.toggleTaskUserSendModal}
                >
                    <ModalTaskUserSend
                        isOpen={openTaskUserSendModal}
                        onClose={this.toggleTaskUserSendModal}
                        task={selectedTask}
                        translate={translate}
                    />
                </SharedSlidingPane>

                {/* Painel de tarefas do usuário */}
                <SharedSlidingPane
                    isOpen={openUserTasksModal}
                    title="Minhas Tarefas"
                    onClose={this.toggleUserTasksModal}
                >
                    <ModalUserTasks
                        isOpen={openUserTasksModal}
                        onClose={this.toggleUserTasksModal}
                        onTaskUndelivered={this.getTasks}
                        user={this.props.user}
                        translate={translate}
                        tasks={this.state.tasks}
                    />
                </SharedSlidingPane>

                {/* Painel de entrega de tarefa */}
                <SharedSlidingPane
                    isOpen={openDeliveriesModal}
                    title={selectedTask ? `Entregas - ${selectedTask.name}` : 'Entregas'}
                    onClose={() => this.toggleDeliveriesModal(null)}
                >
                    {selectedTask && (
                        <ModalTaskDeliveries
                            isOpen={openDeliveriesModal}
                            onClose={() => this.toggleDeliveriesModal(null)}
                            task={selectedTask}
                            translate={translate}
                        />
                    )}
                </SharedSlidingPane>

                {/* Painel de templates */}
                <SharedSlidingPane
                    isOpen={openTemplatesModal}
                    title="Templates"
                    onClose={this.toggleTemplatesModal}
                >
                    <ModalTaskTemplates
                        isOpen={openTemplatesModal}
                        onClose={this.toggleTemplatesModal}
                        onTaskCreated={this.getTasks}
                        classroom={this.props.classroom}
                        translate={translate}
                        units={units}
                        lessons={lessons}
                    />
                </SharedSlidingPane>

                {/* Painel de observação */}
                <SharedSlidingPane
                    isOpen={openObservationModal}
                    title="Observação"
                    width="400px"
                    onClose={() => this.setState({ 
                        openObservationModal: false,
                        selectedObservation: '' 
                    })}
                >
                    <p>{selectedObservation}</p>
                </SharedSlidingPane>
            </React.Fragment>
        );
    }
}

export default connect(state => ({
    user: state.user,
    permissions: state.permission || []
}))(ModalTask);
