import React from "react";
import { Button, Card, CardBody, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Alert } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import moment from 'moment';
import 'moment/locale/pt-br'; 
import api from "../../../../Api.js";

moment.locale('pt-br'); 

class ModalTaskUserSend extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            obs: '',
            link: '',
            file: null,
            fileName: '',
            error: null,
            isPaneOpen: props.isOpen,
            isSubmitting: false,
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isPaneOpen: this.props.isOpen });
        }
    }

    handlePaneClose = () => {
        this.setState({ isPaneOpen: false });
        setTimeout(() => {
            this.props.onClose();
        }, 300);
    };

    handleChange(e) {
        const { name, value, type, files } = e.target;
        const val = type === 'file' ? files[0] : value;
        const fileName = type === 'file' ? files[0].name : '';
        console.log(`handleChange - ${name}:`, val);
        this.setState({ [name]: val, fileName });
    }

    isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        
        try {
            const { obs, link, file, fileName } = this.state;
            const { task } = this.props;
            
            // Validação para tarefas que exigem arquivo
            if (task.type_send === 'arquivo' && !file) {
                window.swal.fire({
                    icon: 'error',
                    title: 'Erro',
                    text: 'Esta tarefa exige o envio de um arquivo. Por favor, anexe um arquivo antes de enviar.',
                });
                return;
            }

            // Validação para tarefas que exigem link
            if (task.type_send === 'link') {
                if (!link || !this.isValidUrl(link)) {
                    window.swal.fire({
                        icon: 'error',
                        title: 'Erro',
                        text: 'Por favor, insira uma URL válida (exemplo: https://www.exemplo.com)',
                    });
                    return;
                }
            }

            this.setState({ isSubmitting: true });
            
            // Verifica se a entrega está em atraso
            const currentDate = moment();
            const endDate = moment(task.dataFinal);
            const status = currentDate.isAfter(endDate) ? 'ATRASADO' : 'Entregue';

            const formData = new FormData();
            formData.append('sendDate', moment().format('YYYY-MM-DD HH:mm:ss'));
            formData.append('obs', obs);
            formData.append('link', link);
            formData.append('file', file);
            formData.append('fileName', fileName);
            formData.append('task', task.id);
            formData.append('status', status);

            console.log('handleSubmit - FormData:', formData);

            await api.postTaskUserSend(formData);
            
            let successMessage = 'Tarefa enviada com sucesso!';
            if (status === 'ATRASADO') {
                successMessage = 'Tarefa enviada com sucesso, mas fora do prazo!';
            }

            window.swal.fire({
                icon: status === 'ATRASADO' ? 'warning' : 'success',
                title: 'Sucesso!',
                text: successMessage
            });
            
            this.props.onClose();
        } catch (error) {
            this.setState({ error: error.response?.data?.msg });
            window.swal.fire('Erro!', error.response?.data?.msg || 'Erro ao enviar tarefa', 'error');
        } finally {
            this.setState({ isSubmitting: false });
        }
    };

    render() {
        const { isOpen, onClose, translate, task } = this.props;
        const { obs, link, error, fileName, isPaneOpen } = this.state;

        if (!task) {
            return null;
        }

        return (

                <Card className="bg-secondary border-0 mb-0">
                    <CardBody className="px-lg-5 py-lg-5">
                        <Form onSubmit={this.handleSubmit} className="needs-validation">
                            {error && (
                                <Alert color="danger" className="alert-dismissible fade show">
                                    <span className="alert-inner--text">{error}</span>
                                </Alert>
                            )}
                            
                            <FormGroup className="mb-4">
                                <label className="form-control-label">{translate?.data_envio || "Data de Envio"}</label>
                                <InputGroup className="input-group-alternative shadow-sm">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="bg-primary text-white">
                                            <i className="ni ni-calendar-grid-58" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        name="sendDate"
                                        value={moment().format('YYYY-MM-DD HH:mm:ss')}
                                        disabled
                                        className="form-control-alternative"
                                    />
                                </InputGroup>
                            </FormGroup>
                            <FormGroup>
                                <label>{translate?.status || "Status"}</label>
                                <InputGroup className="input-group-alternative mb-3 shadow-sm">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="bg-primary text-white">
                                            <i className="ni ni-check-bold" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="text"
                                        name="status"
                                        value={task.status}
                                        disabled
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="mb-4">
                                <label className="form-control-label">{translate?.descricao_tarefa || "Descrição da Tarefa"}</label>
                                <InputGroup className="input-group-alternative shadow-sm">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="bg-primary text-white">
                                            <i className="ni ni-align-left-2" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="textarea"
                                        value={task.obs || (translate?.sem_descricao || "Nenhuma descrição disponível")}
                                        disabled
                                        className="form-control-alternative"
                                        rows="4"
                                        style={{ resize: 'none', whiteSpace: 'pre-wrap' }}
                                    />
                                </InputGroup>
                            </FormGroup>

                            <FormGroup className="mb-4">
                                <label className="form-control-label">{translate?.observacao || "Sua Observação"}</label>
                                <InputGroup className="input-group-alternative shadow-sm">
                                    <InputGroupAddon addonType="prepend">
                                        <InputGroupText className="bg-primary text-white">
                                            <i className="ni ni-align-left-2" />
                                        </InputGroupText>
                                    </InputGroupAddon>
                                    <Input
                                        type="textarea"
                                        name="obs"
                                        value={obs}
                                        onChange={this.handleChange}
                                        required
                                        className="form-control-alternative"
                                        rows="4"
                                        style={{ resize: 'none' }}
                                    />
                                </InputGroup>
                            </FormGroup>
                            {task.type_send === 'link' && (
                                <FormGroup className="mb-4">
                                    <label className="form-control-label">
                                        {translate?.link || "Link"}
                                        <span className="text-danger ml-1">*</span>
                                    </label>
                                    <InputGroup className="input-group-alternative shadow-sm">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText className="bg-primary text-white">
                                                <i className="ni ni-world" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <Input
                                            type="url"
                                            name="link"
                                            value={link}
                                            onChange={this.handleChange}
                                            required
                                            pattern="https?://.*"
                                            placeholder="https://www.exemplo.com"
                                            className={`form-control-alternative ${link && !this.isValidUrl(link) ? 'is-invalid' : ''}`}
                                        />
                                        {link && !this.isValidUrl(link) && (
                                            <div className="invalid-feedback">
                                                Por favor, insira uma URL válida começando com http:// ou https://
                                            </div>
                                        )}
                                    </InputGroup>
                                </FormGroup>
                            )}
                            {task.type_send === 'arquivo' && (
                                <FormGroup className="mb-4">
                                    <label className="form-control-label">
                                        {translate?.arquivo || "Arquivo"}
                                        <span className="text-danger ml-1">*</span>
                                    </label>
                                    <InputGroup className="input-group-alternative shadow-sm">
                                        <InputGroupAddon addonType="prepend">
                                            <InputGroupText className="bg-primary text-white">
                                                <i className="ni ni-folder-17" />
                                            </InputGroupText>
                                        </InputGroupAddon>
                                        <div className="custom-file">
                                            <Input
                                                type="file"
                                                className="custom-file-input"
                                                id="customFileLang"
                                                name="file"
                                                onChange={this.handleChange}
                                                required
                                            />
                                            <label className="custom-file-label text-muted" htmlFor="customFileLang">
                                                {fileName || (translate?.escolha_arquivo || "Escolha o arquivo")}
                                            </label>
                                        </div>
                                    </InputGroup>
                                </FormGroup>
                            )}
                            <div className="text-center mt-5">
                                <Button 
                                    className="mr-3 px-5" 
                                    color="primary" 
                                    type="submit"
                                    size="lg"
                                >
                                    {translate?.entregar || "Entregar"}
                                </Button>
                                <Button 
                                    className="px-5" 
                                    color="secondary" 
                                    type="button" 
                                    onClick={onClose}
                                    size="lg"
                                >
                                    {translate?.cancelar || "Cancelar"}
                                </Button>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
        );
    }
}

export default ModalTaskUserSend;