function generateRoutes(permissions,translate){

  //console.log(translate);

  var routes = [];

  routes.push({path:"/",name:"Dashboard",icon:"ni ni-shop text-primary",layout:"/admin"});

  if(permissions.indexOf(24) !== -1){ routes.push({path:"/classroom",name:translate.gerenciar_turmas,icon:"fas fa-graduation-cap text-info",layout:"/admin"}); }

  if(permissions.indexOf(26) !== -1){ routes.push({path:"/users",name:translate.usuarios,icon:"far fa-user text-green",layout:"/admin"}); }
 
  if(permissions.indexOf(27) !== -1 || permissions.indexOf(28) !== -1 || permissions.indexOf(29) !== -1){

    routes.push({path:"/calendar",name:translate.calendario,icon:"ni ni-calendar-grid-58",layout:"/admin"});

  }
  
  routes.push({path:"/engine",name:"Game Engine",icon:"fas fa-gamepad",layout:"/engine"});

  if(permissions.indexOf(36) !== -1){

    routes.push({
      collapse: true,
      name: 'Relatórios',
      icon: "fas fa-chart-pie",
      state: "dashboardsCollapse23",
      views: [
        {
          path: "/report/classroom",
          name: 'Turma',
          layout: "/admin"
        }
      ]
    });

  }


  if(permissions.indexOf(21) !== -1){ 

    routes.push({
      collapse: true,
      name: translate.configuracoes,
      icon: "ni ni-shop text-primary",
      state: "dashboardsCollapse",
      views: [
        {
          path: "/configuration/appearance",
          name: translate.aparencia,
          layout: "/admin"
        },
        {
          path: "/configuration/company",
          name: translate.unidades,
          layout: "/admin"
        },/**/
        {
          path: "/configuration/tasks",
          name: translate.tipos_de_tarefa,
          layout: "/admin"
        }/**/
      ]
    });

  }

  return routes;

}
   
export default generateRoutes;
