import React from 'react'
import moment from 'moment'
import { ClipLoader } from 'react-spinners'
import api from '../../../../Api'
import { Button, Alert, Table } from 'reactstrap'
import { SharedSlidingPane } from '../../../../components/SharedSlidingPage'
import ScrollContainer from 'react-indiana-drag-scroll'
import { ModalDetailedTime } from './ModalDetailedTime'
import { ModalDetailedDeleteTime } from './ModalDetailedDeleteTime'
import { ModalDetailedTimeForm } from './ModalDetailedTimeForm'

class Detailed extends React.Component {
  constructor (props) {
    super(props)
    this.altDefault = 55
    this.altMultiplayer = 26
    window.dashdetailed = this

    this.state = {
      editingTime: null,
      newTime: '00:00:00', 
      loading: false,
      alert: {
        isOpen: false,
        color: 'danger',
        message: ''
      },

      isDeleteModalOpen: false,
      isEditModalOpen: false,
      isAddModalOpen: false,
      selectedRecord: null,
      isSlidingPageOpen: false,
      selectedUserData: null,
      selectedLessonData: null,
      openDeletePane: false,
      openEditPane: false,
      openAddPane: false,
      selectedDate: moment().format('DD/MM/YYYY'), // Today's date as default
      dateTime: moment().format('HH:mm') // Adicionado para o horário
    }
  }

  showAlert = (message, color = 'danger') => {
    this.setState({
      alert: {
        isOpen: true,
        color,
        message
      }
    })
    setTimeout(() => {
      this.setState({
        alert: {
          isOpen: false,
          color: 'danger',
          message: ''
        }
      })
    }, 5000)
  }

  async componentDidMount () {}

  //Para somar todos os times
  sumAllTime (times) {
    var total = 0

    for (var i = 0; i < times.length; i++) {
      var hms = times[i].time
      var a = hms.split(':')

      var seconds = +a[0] * 60 * 60 + +a[1] * 60 + +a[2]
      total += seconds
    }

    var t = moment('00:00:00', 'HH:mm:ss')
      .add(total, 'seconds')
      .format('HH:mm:ss')
    return t
  }

  componentDidUpdate () {}

  handleTimeUpdate = async newTime => {
    const { editingTime, selectedDate, dateTime } = this.state
    if (!editingTime) return

    if (!moment(newTime, 'HH:mm:ss', true).isValid()) {
      throw new Error('Formato de tempo inválido. Use HH:mm:ss')
    }
    const combined = moment(`${selectedDate} ${dateTime}`, 'DD/MM/YYYY HH:mm')
    const formattedDate = combined.format('YYYY-MM-DD HH:mm:ss')

    this.setState({ loading: true })
    const data = {
      user: parseInt(editingTime.userId),
      lesson: parseInt(editingTime.lessonId),
      classRoom: parseInt(this.props.state.classRoom.id),
      newTime: newTime,
      newDate: formattedDate
    }

    await api.updateProgressCompound(data)

    const updatedProgress = { ...this.props.state.progress }
    const userProgress = updatedProgress.users.find(u => u.id === editingTime.userId)
    if (userProgress) {
      const lessonProgress = userProgress.progress[editingTime.lessonId]
      const recordIndex = lessonProgress.findIndex(r => r.time === editingTime.oldTime)
      if (recordIndex !== -1) {
        lessonProgress[recordIndex] = {
          ...lessonProgress[recordIndex],
          time: newTime,
          createDate: formattedDate
        }
      }
    }
    this.props.state.progress = updatedProgress
    this.setState({
      editingTime: null,
      loading: false,
      isEditModalOpen: false,
      openEditPane: false
    })
    this.showAlert('Tempo atualizado com sucesso!', 'success')
  }

  handleTimeDelete = async () => {
    const { selectedRecord } = this.state
    if (!selectedRecord) return

    try {
      this.setState({ loading: true })

      const data = {
        user: parseInt(selectedRecord.userId),
        lesson: parseInt(selectedRecord.lessonId),
        classRoom: parseInt(this.props.state.classRoom.id)
      }

      await api.deleteProgressCompound(data)

      // Atualiza o estado local
      const updatedProgress = { ...this.props.state.progress }
      const userProgress = updatedProgress.users.find(
        u => u.id === selectedRecord.userId
      )

      if (userProgress) {
        const lessonProgress = userProgress.progress[selectedRecord.lessonId]
        // Encontra o índice do registro mais recente com o tempo especificado
        const recordIndex = lessonProgress
          .map((record, index) => ({ ...record, index }))
          .filter(record => record.time === selectedRecord.time)
          .sort((a, b) =>
            moment(b.createDate).diff(moment(a.createDate))
          )[0]?.index

        if (recordIndex !== undefined) {
          // Remove apenas o registro mais recente
          lessonProgress.splice(recordIndex, 1)
        }
      }

      // Atualiza o estado do componente pai
      this.props.state.progress = updatedProgress
      this.setState({
        isDeleteModalOpen: false,
        selectedRecord: null,
        loading: false,
        openDeletePane: false 
      })
      this.showAlert('Registro excluído com sucesso!', 'success')
    } catch (error) {
      console.error('Erro ao excluir registro:', error)
      this.showAlert(
        'Erro ao excluir o registro: ' +
          (error.response?.data?.msg || error.message)
      )
      this.setState({ loading: false })
    }
  }

  handleTimeAdd = async () => {
    const { selectedRecord, newTime, selectedDate, dateTime } = this.state
    if (!selectedRecord) return

    try {
      if (!moment(newTime, 'HH:mm:ss', true).isValid()) {
        throw new Error('Formato de tempo inválido. Use HH:mm:ss')
      }

      if (!moment(selectedDate, 'DD/MM/YYYY', true).isValid()) {
        throw new Error('Formato de data inválido. Use DD/MM/YYYY')
      }

      const combined = moment(`${selectedDate} ${dateTime}`, 'DD/MM/YYYY HH:mm')
      const formattedDate = combined.format('YYYY-MM-DD HH:mm:ss')

      this.setState({ loading: true })

      const data = {
        user: parseInt(selectedRecord.userId),
        lesson: parseInt(selectedRecord.lessonId),
        classRoom: parseInt(this.props.state.classRoom.id),
        newTime: newTime,
        newDate: formattedDate  
      }

      await api.addProgressCompound(data)

      // Atualiza o estado local
      const updatedProgress = { ...this.props.state.progress }
      const userProgress = updatedProgress.users.find(
        u => u.id === selectedRecord.userId
      )
      if (userProgress) {
        const newRecord = {
          time: newTime,
          createDate: formattedDate 
        }
        userProgress.progress[selectedRecord.lessonId].push(newRecord)
      }

      // Atualiza o estado do componente pai
      this.props.state.progress = updatedProgress
      this.setState({
        isAddModalOpen: false,
        selectedRecord: null,
        newTime: '',
        loading: false,
        openAddPane: false 
      })
      this.showAlert('Tempo adicionado com sucesso!', 'success')
    } catch (error) {
      console.error('Erro ao adicionar tempo:', error)
      this.showAlert('Erro ao adicionar o registro: ' + error.message)
      this.setState({ loading: false })
    }
  }

  handleTimeEdit = (userId, lessonId, oldTime) => {
    this.setState({
      editingTime: {
        userId,
        lessonId,
        oldTime
      },
      newTime: oldTime
    })
  }

  toggleDeleteModal = (userId, lessonId, time) => {
    this.setState(state => ({
      isDeleteModalOpen: !state.isDeleteModalOpen,
      selectedRecord: state.isDeleteModalOpen
        ? null
        : { userId, lessonId, time }
    }))
  }

  toggleEditModal = (userId, lessonId, oldTime) => {
    this.setState(state => ({
      isEditModalOpen: !state.isEditModalOpen,
      editingTime: state.isEditModalOpen
        ? null
        : {
            userId,
            lessonId,
            oldTime
          },
      newTime: oldTime || ''
    }))
  }

  toggleAddModal = (userId, lessonId) => {
    this.setState(state => ({
      isAddModalOpen: !state.isAddModalOpen,
      selectedRecord: state.isAddModalOpen ? null : { userId, lessonId },
      newTime: ''
    }))
  }

  toggleDeletePane = (userId, lessonId, time) => {
    this.setState(state => ({
      openDeletePane: !state.openDeletePane,
      selectedRecord: state.openDeletePane ? null : { userId, lessonId, time }
    }))
  }

  toggleEditPane = (userId, lessonId, oldTime, oldDateTime) => {
    const recordDate = oldDateTime ? moment(oldDateTime).format('DD/MM/YYYY') : moment().format('DD/MM/YYYY');
    const recordTime = oldDateTime ? moment(oldDateTime).format('HH:mm') : moment().format('HH:mm');

    this.setState(state => ({
      openEditPane: !state.openEditPane,
      editingTime: state.openEditPane
        ? null
        : {
            userId,
            lessonId,
            oldTime
          },
      newTime: oldTime || '00:00:00',
      selectedDate: recordDate,
      dateTime: recordTime
    }))
  }

  toggleAddPane = (userId, lessonId) => {
    this.setState(state => ({
      openAddPane: !state.openAddPane,
      selectedRecord: state.openAddPane ? null : { userId, lessonId },
      newTime: '00:00:00' 
    }))
  }

  handleDateChange = (e) => {
    this.setState({ selectedDate: e.target.value });
  };

  handleDateTimeChange = (e) => {
    this.setState({ dateTime: e.target.value })
  }

  render () {
    const {
      isDeleteModalOpen,
      isEditModalOpen,
      isAddModalOpen,
      loading,
      newTime,
      alert,
      openDeletePane,
      openEditPane,
      openAddPane
    } = this.state

    return (
      <React.Fragment>
        {alert.isOpen && (
          <div
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              zIndex: 9999,
              minWidth: '300px'
            }}
          >
            <Alert
              color={alert.color}
              isOpen={alert.isOpen}
              toggle={() =>
                this.setState({ alert: { ...alert, isOpen: false } })
              }
            >
              {alert.message}
            </Alert>
          </div>
        )}
        <div className='row'>
          {this.props.state.loadProgres !== true && (
            <React.Fragment>
              <div
                className='col-md-12'
                style={{ marginTop: '40px', marginBottom: '30px' }}
              >
                <center>
                  <ClipLoader
                    sizeUnit={'px'}
                    size={60}
                    color={'#adb5bd'}
                    loading={true}
                  />
                </center>
              </div>
            </React.Fragment>
          )}

          <div
            className='col-md-2 box_left_detailed'
            style={{ boxShadow: '8px 0px 14px -20px #000' }}
          >
            {this.props.state.loadProgres === true && (
              <React.Fragment>
                <div
                  className='dashboard_class_user'
                  style={{ borderTop: '1px solid #ffffff00', height: '28px' }}
                ></div>
                {this.props.state.progress.users.map((value, index) => {
                  var tot = this.props.maxArrayReturn(value.id)
                  //console.log(tot);

                  var height = tot * this.altMultiplayer
                  if (height === 0 || height < 55) {
                    height = this.altDefault + height
                  }
                  var sty = { height: height + 'px' }

                  if (this.props.state.openTimes[value.id] === true) {
                    sty = { height: height + 'px' }
                  } else {
                    sty = { height: this.altDefault + 'px' }
                  }

                  var css = { cursor: 'pointer', display: 'flex' }
                  if (value.userInClass === false) {
                    css.opacity = '0.5'
                  }

                  return (
                    <React.Fragment key={value.id}>
                      <div
                        className='dashboard_class_user dashboard_class_user_5'
                        style={sty}
                      >
                        <div className='name_user'>
                          <div
                            onClick={() => this.props.openChartUser(value)}
                            style={css}
                          >
                            <img
                              alt='...'
                              className='avatar rounded-circle mr-3'
                              src={value.photo}
                            />
                            <input
                              style={{ cursor: 'pointer', width: '130px' }}
                              readOnly
                              className='inputViwerName_classroom '
                              value={'' + value.name + ' ' + value.lastName}
                            />
                          </div>
                          {this.props.state.openTimes[value.id] !== true && (
                            <button
                              onClick={() => this.props.openUser(value.id)}
                              type='button'
                              className='buttonOpenDashBoard btn-icon-only rounded-circle btn btn-secondary'
                            >
                              <span className='btn-inner--icon'>
                                <i className='fas fa-chevron-down'></i>
                              </span>
                            </button>
                          )}
                          {this.props.state.openTimes[value.id] === true && (
                            <button
                              onClick={() => this.props.closeUser(value.id)}
                              type='button'
                              className='buttonOpenDashBoard btn-icon-only rounded-circle btn btn-secondary'
                            >
                              <span className='btn-inner--icon'>
                                <i className='fas fa-chevron-up'></i>
                              </span>
                            </button>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )
                })}
              </React.Fragment>
            )}
          </div>

          {this.props.state.loadProgres === true && (
            <div className='col-md-10' style={{ paddingLeft: '0px' }}>
              <ScrollContainer
                id='over_mouse_t'
                className='scroll-container'
                style={{ overflow: 'auto' }}
              >
                <div
                  className='listLessonsTop'
                  style={{
                    backgroundColor: '#fff',
                    borderRadius: '0px',
                    boxShadow: '0px 5px 8px -11px #000'
                  }}
                >
                  {this.props.state.progress.lessons.map((value, index) => {
                    return (
                      <React.Fragment key={value.id}>
                        <div className='lessonTop'>
                          <i className={window.iconsLesson[value.icon]}></i>{' '}
                          <font style={{ fontWeight: '700' }}>
                            {value.name}
                          </font>
                        </div>
                      </React.Fragment>
                    )
                  })}
                </div>

                {this.props.state.progress.users.map((value, index) => {
                  var tot = this.props.maxArrayReturn(value.id)
                  var sty
                  //console.log(tot);

                  var height = tot * this.altMultiplayer
                  if (height === 0 || height < 55) {
                    height = this.altDefault + height
                  }

                  if (this.props.state.openTimes[value.id] === true) {
                    sty = { height: height + 'px' }
                  } else {
                    sty = { height: this.altDefault + 'px' }
                  }

                  if (index === 0) {
                    sty.borderTop = '1px solid #e9ecef00'
                  }

                  return (
                    <React.Fragment key={index}>
                      <div
                        className='dashboard_class_userList_user'
                        style={sty}
                      >
                        <React.Fragment>
                          {this.props.state.progress.lessons.map(
                            (value2, index2) => {
                              var cl

                              if (
                                this.props.state.openTimes[value.id] === true
                              ) {
                                cl = 'resto_progresso'
                                height = 20
                                sty = {}
                              } else {
                                cl = 'resto_progresso hidden_progress'
                                sty = {}
                              }

                              return (
                                <div
                                  key={index2}
                                  className='listTime_dashboard_class_room'
                                  style={{ ...sty, cursor: 'pointer' }}
                                  onClick={() =>
                                    this.setState({
                                      isSlidingPageOpen: true,
                                      selectedUserData: value,
                                      selectedLessonData: value2 
                                    })
                                  }
                                >
                                  <div
                                    user={value.id}
                                    lesson={value2.id}
                                    className='rrasta animated'
                                    style={{
                                      cursor: 'grab',
                                      padding: '15px 0px 0px 0px',
                                      height: '35px'
                                    }}
                                  >
                                    <font className='time_progress'>
                                      {this.sumAllTime(
                                        value.progress[value2.id]
                                      )}
                                    </font>
                                    <font className='tot_register'>
                                      {value.progress[value2.id].length}
                                    </font>
                                  </div>
                                  <div className={cl}>
                                    {value.progress[value2.id].map(
                                      (value3, index3) => {
                                        var dd = moment(
                                          value3.createDate,
                                          'YYYY-MM-DD HH:mm:ss'
                                        )
                                        return (
                                          <div
                                            key={index3}
                                            className='outras_tentativas d-flex align-items-center'
                                          >
                                            <div className='d-flex align-items-center'>
                                              <span className='date mr-3'>
                                                {dd.format('DD/MM/YYYY')} as{' '}
                                                {dd.format('HH:mm')}
                                              </span>
                                              <span className='timer'>
                                                {value3.time}
                                              </span>
                                            </div>
                                          </div>
                                        )
                                      }
                                    )}
                                  </div>
                                </div>
                              )
                            }
                          )}
                        </React.Fragment>
                      </div>
                    </React.Fragment>
                  )
                })}
              </ScrollContainer>
            </div>
          )}
        </div>

        <SharedSlidingPane
          isOpen={this.state.isSlidingPageOpen}
          title={`${this.state.selectedUserData?.name} ${this.state.selectedUserData?.lastName}`}
          width='500px'
          onClose={() => this.setState({ isSlidingPageOpen: false })}
        >
          <ModalDetailedTime
            selectedUserData={this.state.selectedUserData}
            selectedLessonData={this.state.selectedLessonData}
            onEdit={this.toggleEditPane}
            onDelete={this.toggleDeletePane}
            onAdd={this.toggleAddPane}
            sumAllTime={this.sumAllTime}
          />
        </SharedSlidingPane>

        {/* Painel de deletar */}
        <SharedSlidingPane
          isOpen={openDeletePane}
          title='Confirmação de Exclusão'
          width='400px'
          onClose={this.toggleDeletePane}
        >
          <ModalDetailedDeleteTime
            onConfirm={this.handleTimeDelete}
            onCancel={this.toggleDeletePane}
            loading={loading}
          />
        </SharedSlidingPane>

        {/* Painel de adicionar/remover */}
        <SharedSlidingPane
          isOpen={openEditPane || openAddPane}
          title={openEditPane ? 'Editar Tempo' : 'Adicionar Tempo'}
          width='400px'
          onClose={openEditPane ? this.toggleEditPane : this.toggleAddPane}
        >
          <ModalDetailedTimeForm
            isEdit={openEditPane}
            value={newTime}
            date={this.state.selectedDate}
            dateTime={this.state.dateTime}
            onChange={e => this.setState({ newTime: e.target.value })}
            onDateChange={this.handleDateChange}
            onDateTimeChange={this.handleDateTimeChange}
            onSubmit={() =>
              openEditPane
                ? this.handleTimeUpdate(newTime)
                : this.handleTimeAdd()
            }
            onCancel={openEditPane ? this.toggleEditPane : this.toggleAddPane}
            loading={loading}
          />
        </SharedSlidingPane>
      </React.Fragment>
    )
  }
}

export default Detailed
