import axios from 'axios';

//Não verifica o certificado ssl
var api = axios.create({secure:false});

sessionStorage.backend = 'https://lms.supergeeks.school/api';
window.backend = 'https://lms.supergeeks.school/api';

var apis = {
	 
	verifyBackend: () => api.get('/serverlist/'+window.location.hostname+'.json'),
	verifyBackend2: (url) => api.get(url),

	login: (data) => api.post(sessionStorage.getItem('backend')+'/auth/password',data),
	users: () => api.get(sessionStorage.getItem('backend')+'/user',{headers: {session: sessionStorage.getItem('session')}}),

	usersCompany: (company) => api.get(sessionStorage.getItem('backend')+'/user/company/'+company,{headers: {session: sessionStorage.getItem('session')}}),

	levelAcess: () => api.get(sessionStorage.getItem('backend')+'/acessLevel'),
	listLanguages: () => api.get('/language/index.json'),
	languageText: (file) => api.get('/language/'+file),

	//Para carregar as configurações do sistema
	loadConfig: (file) => api.get(sessionStorage.getItem('backend')+'/configuration/'),

	//para atualizar os dados de um usuario
	updateUser: (id,data) => api.put(sessionStorage.getItem('backend')+'/user/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Parar criar um novo usuario
	postUser: (data) => api.post(sessionStorage.getItem('backend')+'/user/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover o usuario
	deleteUser: (id) => api.delete(sessionStorage.getItem('backend')+'/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para enviar arquivo
	uploadFile: (data) => api.delete(sessionStorage.getItem('backend')+'/upload/',data,{headers: {session: sessionStorage.getItem('session')}}),
 
	//Para listar todos os cursos
	getAllcourses: () => api.get(sessionStorage.getItem('backend')+'/course/',{headers: {session: sessionStorage.getItem('session')}}),

	getAllClassRoom: () => api.get(sessionStorage.getItem('backend')+'/classroom/',{headers: {session: sessionStorage.getItem('session')}}),

	getAllClassRoomCompany: (company) => api.get(sessionStorage.getItem('backend')+'/classroom/company/'+company,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para verificar o acesso do usuario no sisstema
 	verifyAcess: (id) => api.get(sessionStorage.getItem('backend')+'/auth/verify',{headers: {session: sessionStorage.getItem('session')}}),
 	
 	//Para chegar a licença
 	checkLicense: () => api.get(sessionStorage.getItem('backend')+'/license/check',{headers: {session: sessionStorage.getItem('session')}}),

 	//Para pegar as permissões
	getLevelAcess: () => api.get(sessionStorage.getItem('backend')+'/acessLevel',{headers: {session: sessionStorage.getItem('session')}}),
  
	//Para editar uma turma
	postClassRoom: (data) => api.post(sessionStorage.getItem('backend')+'/classroom/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os dados de uma turma
	getClassRoom: (id) => api.get(sessionStorage.getItem('backend')+'/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover uma turma
	putClassRoom: (id,data) => api.put(sessionStorage.getItem('backend')+'/classroom/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover a turma
	deleteClassRoom: (id) => api.delete(sessionStorage.getItem('backend')+'/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pesquisar alunos para colocar na turma
	searchUserClassRoomTerm: (id,term) => api.get(sessionStorage.getItem('backend')+'/classroom/searchuser/'+id+'?term='+term,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os alunos que ja estão na turma
	searchUserClassRoom: (id) => api.get(sessionStorage.getItem('backend')+'/classroom/user/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para adicionar uma usuario na class
	addUserClassRoom: (data) => api.post(sessionStorage.getItem('backend')+'/classroom/user/add',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover um usuário da class
	deleteUserClassRoomApi: (classroom,user) => api.delete(sessionStorage.getItem('backend')+'/classroom/user/remove?user='+user+'&classroom='+classroom+'',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas que eu faço parte
	getMyClassRooms: () => api.get(sessionStorage.getItem('backend')+'/myclassroom/',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as turmas que eu faço parte e da compania
	getMyClassRoomsCompany: (company) => api.get(sessionStorage.getItem('backend')+'/myclassroom/company/'+company,{headers:{session:sessionStorage.getItem('session')}}),

 	//Para pegar as units de um curso
	getUnitsCourse: (id)=> api.get(sessionStorage.getItem('backend')+'/course/units/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as lessons de uma unit
	getLessonsUnit: (id) => api.get(sessionStorage.getItem('backend')+'/lesson/unit/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso do usuario em uma lesson
	getLessonsProgress: (classron,lesson,user) => api.get(sessionStorage.getItem('backend')+'/progress/'+classron+'/'+lesson+'/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso do usuario em uma lesson
	getLesson: (lesson,extra="") => api.get(sessionStorage.getItem('backend')+'/lesson/'+lesson+extra,{headers: {session: sessionStorage.getItem('session')}}),

	//Par postar os dados de um progresso
	postProgress: (data) => api.post(sessionStorage.getItem('backend')+'/progress/',data,{headers: {session: sessionStorage.getItem('session')}}),
 	
	//Para pegar tudo de uma turma
	getAcessoClassRoom: (classroom,user) => api.get(sessionStorage.getItem('backend')+'/myclassroom/progress/'+classroom+'/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso de uma turma numa unit
	getProgressUnitClassroom: (classroom,unit,all) => api.get(sessionStorage.getItem('backend')+'/progress/'+classroom+'?unit='+unit+all,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso de uma turma numa unit
	savePreference: (obj) => api.put(sessionStorage.getItem('backend')+'/user/savesetting',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para setar o qrcode do usuário
	setQRcode: (user,obj) => api.put(sessionStorage.getItem('backend')+'/user/saveqrcode/'+user,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para verificar se o usuario existe... usado na tela de login
	searchUserLogin: (user) => api.get(sessionStorage.getItem('backend')+'/auth/exist_user/'+user),

	//Para fazer o login via qrcode
	loginQRcode: (obj) => api.post(sessionStorage.getItem('backend')+'/auth/qrcode',obj),

	//Para fazer o login via qrcode
	sendRecoverPassWord: (email) => api.post(sessionStorage.getItem('backend')+'/auth/recoverpassword/'+email),

	//Para verificar o código de redifinição de senha
	verifyCodeRecover: (codigo) => api.get(sessionStorage.getItem('backend')+'/auth/verifycoderecover/'+codigo),

	//Para enviar a nova senha para o servidor
	resetPassword: (obj) => api.put(sessionStorage.getItem('backend')+'/auth/resetpassword/',obj),

	//Para enviar a nova senha para o servidor
	savePhoto: (user,obj) => api.put(sessionStorage.getItem('backend')+'/user/updatephoto/'+user,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para salvar as configurações do sistema
	saveConfiguration: (obj) => api.put(sessionStorage.getItem('backend')+'/configuration/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para alterar a senha do usuário
	changePassword: (obj) => api.put(sessionStorage.getItem('backend')+'/user/changepassowrd',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o quiz da lesson
	getQuizLesson: (quiz) => api.get(sessionStorage.getItem('backend')+'/lesson/quiz/'+quiz,{headers: {session: sessionStorage.getItem('session')}}),

	//para pegar o progresso do quiz do usuario
	getProgressQuiz: (classroom,quiz,lesson) => api.get(sessionStorage.getItem('backend')+'/quiz/getProgress/?quiz='+quiz+'&classroom='+classroom+'&lesson='+lesson,{headers: {session: sessionStorage.getItem('session')}}), 

	//Para salvar o cache do progresso do quiz
	postProgressQuiz: (obj) => api.post(sessionStorage.getItem('backend')+'/quiz/saveProgress',obj,{headers: {session: sessionStorage.getItem('session')}}), 

 	//Para pegar somente os calendario que eu tenho acesso
	getMyCalendar: (data) => api.get(sessionStorage.getItem('backend')+'/calendar/mylist',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar todos os calendarios
	getCalendar: (data) => api.get(sessionStorage.getItem('backend')+'/calendar/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para cadastrar um calendario
	postCalendar: (data) => api.post(sessionStorage.getItem('backend')+'/calendar/',data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para reditar um calendario
	putCalendar: (id,data) => api.put(sessionStorage.getItem('backend')+'/calendar/'+id,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover um calendario
	deleteCalendar: (calendar) => api.delete(sessionStorage.getItem('backend')+'/calendar/'+calendar,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o tempo investido para gerar o chat
	chartTimeInvested: (user) =>  api.get(sessionStorage.getItem('backend')+'/chart/timeinvested/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o desempenho dele no quiz
	chartQuizperFormance: (user) =>  api.get(sessionStorage.getItem('backend')+'/chart/quizperformance/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para verificar no json se esta tudo certo antes de inserir no banco de dados
	userCheckXlsx: (data) =>  api.post(sessionStorage.getItem('backend')+'/user/checkxlsx/',data,{headers: {session: sessionStorage.getItem('session')}}),

	classRoomCheckXlsx: (data) =>  api.post(sessionStorage.getItem('backend')+'/classroom/checkxlsx/',data,{headers: {session: sessionStorage.getItem('session')}}),

	getClassRoomAccessOnline: (idonline) =>  api.get(sessionStorage.getItem('backend')+'/classroom/idonlineaccess/'+idonline,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar todo o tempo nas lesson de uma unit
	chartTimeLessonsUnit: (user,classroom,unit) =>  api.get(sessionStorage.getItem('backend')+'/chart/timelessonsunit/'+user+'?unit='+unit+'&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para pegar todos os tempos na unit da turma
	chartTimeUnit: (user,classroom) =>  api.get(sessionStorage.getItem('backend')+'/chart/timeunit/'+user+'?&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar 
	chartQuizUserUnit: (user,classroom,unit) => api.get(sessionStorage.getItem('backend')+'/chart/quizavaliation/'+user+'?unit='+unit+'&classroom='+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para listar todas as unidades
	getListCompany: () =>  api.get(sessionStorage.getItem('backend')+'/company/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para cadastrar uma unidade
	postCompany: (obj) =>  api.post(sessionStorage.getItem('backend')+'/company/',obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para editar uma unidade
	putCompany: (id,obj) =>  api.put(sessionStorage.getItem('backend')+'/company/'+id,obj,{headers: {session: sessionStorage.getItem('session')}}),

	//Para remover uma unidade
	deleteCompany: (id) =>  api.delete(sessionStorage.getItem('backend')+'/company/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas de uma unidade
	getUsersCompany: (id) =>  api.get(sessionStorage.getItem('backend')+'/company/listusers/'+id,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar as turmas de uma unidade
	getClassRoomCompany: (id) =>  api.get(sessionStorage.getItem('backend')+'/company/classroom/'+id,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Para pegar todos os gestores da unidade
	searchManagersCompanyAll: (company) =>  api.get(sessionStorage.getItem('backend')+'/company/searchallmanagers/'+company,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	searchManagersCompanyTerm: (id,term) =>  api.get(sessionStorage.getItem('backend')+'/company/searchmanagers/'+id+'?term='+term,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	addUserCompany: (company,user) =>  api.post(sessionStorage.getItem('backend')+'/company/adduser/'+company,{user:user},{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os gestores de uma unidade
	deleteUserCompany: (company,user) =>  api.delete(sessionStorage.getItem('backend')+'/company/removeuser/'+company+'?user='+user,{headers: {session: sessionStorage.getItem('session')}}),
	
	//Par pegar as unidades que eu tenho acesso
	getMyCompanys: () => api.get(sessionStorage.getItem('backend')+'/company/mycompanys/',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os meus estudantes
	getMyStudents: () =>  api.get(sessionStorage.getItem('backend')+'/widget/mystudents',{headers: {session: sessionStorage.getItem('session')}}),
	 
	//Para pegar o usuários online
	getUsersOnline: () =>  api.get(sessionStorage.getItem('backend')+'/chat/usersonline',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o link de acesso a engine
	getAccessEngine:() =>  api.get(sessionStorage.getItem('backend')+'/accessengine',{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o total de tempo em tudo
	getTotalTimeUser:(user) =>  api.get(sessionStorage.getItem('backend')+'/chart/totalTime/'+user,{headers: {session: sessionStorage.getItem('session')}}),

	//Para trocar a senha de algum usuário
	changeUserPassword:(user,data) =>  api.put(sessionStorage.getItem('backend')+'/user/changeuserpassword/'+user,data,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar o progresso da turma em todas as units
	getProgressAllUnits:(classroom) =>  api.get(sessionStorage.getItem('backend')+'/totalprogress/classroom/'+classroom,{headers: {session: sessionStorage.getItem('session')}}),

	//Para pegar os usuarios para o chat
	getUsersChat:() => api.get(sessionStorage.getItem('backend')+'/chat/listMyContats',{headers: {session: sessionStorage.getItem('session')}}),

	//Para enviar uma mensagem para algum usuario
	chatSend: (data) => api.post(sessionStorage.getItem('backend')+'/chat/send',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as opções da turma
	getClassRoomOptions: (classroom) => api.get(sessionStorage.getItem('backend')+'/classroom_option/listoptions/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),

	//Para bloquear uma unit
	deleteClassRoomOption: (classroom,data) => api.delete(sessionStorage.getItem('backend')+'/classroom_option/'+classroom+'?name='+data.name+'&value='+data.value,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as opções da turma
	insertClassRoomOption: (classroom,data) => api.post(sessionStorage.getItem('backend')+'/classroom_option/'+classroom,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as mensagens do chat
	getChatMensages: (destinary,pagination) => api.get(sessionStorage.getItem('backend')+'/chat/lastmenssages/'+destinary+'?pagination='+pagination,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para pegar as minhas ultimas mensagens
	getMyLastMensagens: (destinary,pagination) => api.get(sessionStorage.getItem('backend')+'/chat/lastmenssages',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar o calendario de uma turma
	getCalendarClassroom: (classroom) => api.get(sessionStorage.getItem('backend')+'/calendar/classroom/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),

	//Para salvar algum dado no usuário
	postUserSaveData: (data) => api.post(sessionStorage.getItem('backend')+'/user/savedata/',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	//Para obter algum dado do usuario
	getUserData: (name) => api.get(sessionStorage.getItem('backend')+'/user/getdata/'+name,{headers:{session:sessionStorage.getItem('session')}}),

	//Para verificar se a lesson esta disponível para acesso
	lessonIsAccessible: (classroom,lesson) => api.get(sessionStorage.getItem('backend')+'/classroom_option/accesslesson/'+classroom+'?lesson='+lesson,{headers:{session:sessionStorage.getItem('session')}}),

	logout: () => api.delete(sessionStorage.getItem('backend')+'/auth/logout', {
		headers: {
			session: sessionStorage.getItem('session')
		}
	}).catch(error => {
		if (error.response?.status === 401) {
			console.warn('Logout 401: Sessão já expirada');
			return Promise.resolve({
				data: {},
				status: 200
			});
		}
		return Promise.reject(error);
	}),

	//para pegar as aulas de uma turma
	getSchedulesClassroom: (classroom) => api.get(sessionStorage.getItem('backend')+'/classroom/schedule/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),

	//Para cadastrar a aula
	postSchedulesClassroom: (data) => api.post(sessionStorage.getItem('backend')+'/schedule/',data,{headers:{session:sessionStorage.getItem('session')}}),
	
	getUsersByAccessLevel: (accessLevel) => api.get(sessionStorage.getItem('backend')+'/user/listusers/accesslevel/'+accessLevel,{headers:{session:sessionStorage.getItem('session')}}),
	

	//para pegar o qr code do usuário
	getQrCodeUser: (user) => api.get(sessionStorage.getItem('backend')+'/user/getqrcodeuser/'+user,{headers:{session:sessionStorage.getItem('session')}}),

	

	//para pegar todas as turmas de todas as unidades
	getAllClassRoomCompanies: (user) => api.get(sessionStorage.getItem('backend')+'/company/allclassroom/',{headers:{session:sessionStorage.getItem('session')}}),


	generateGrandProgress: (data) => api.post(sessionStorage.getItem('backend')+'/progress/grandprogress/xlsx',data,{responseType:'blob',headers:{session:sessionStorage.getItem('session')}}),


	getpublicclassroom: (id) => api.get(sessionStorage.getItem('backend')+'/publicclassroom/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	postUserpublicclassroom: (id,data) => api.post(sessionStorage.getItem('backend')+'/publicclassroom/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	
	verifyCode: (id) => api.get(sessionStorage.getItem('backend')+'/user/checkcodeverify/'+id),

	getAbaco: (id) => api.get(sessionStorage.getItem('backend')+'/abacouser/'+id,{headers:{session:sessionStorage.getItem('session')}}), 


	addUnlock: (obj) => api.post(sessionStorage.getItem('backend')+'/unlock_lesson/',obj,{headers:{session:sessionStorage.getItem('session')}}), 
	removeUnlock: (obj) => api.delete(sessionStorage.getItem('backend')+'/unlock_lesson/'+obj,{headers:{session:sessionStorage.getItem('session')}}),
	
	
	listPresencaClass: (id) => api.get(sessionStorage.getItem('backend')+'/presencelistclassroom/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	listPresenca: (id) => api.get(sessionStorage.getItem('backend')+'/presencelist/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	postListPresenca: (data) => api.post(sessionStorage.getItem('backend')+'/presencelist/',data,{headers:{session:sessionStorage.getItem('session')}}),
	putListPresenca: (id,data) => api.put(sessionStorage.getItem('backend')+'/presencelist/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),
	deleteListPresenca: (id) => api.delete(sessionStorage.getItem('backend')+'/presencelist/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	setPresenca: (list,user,type,unit) => api.post(sessionStorage.getItem('backend')+'/presencelist/'+list+'/'+user+'/'+type+'/'+unit,{},{headers:{session:sessionStorage.getItem('session')}}),

	 
	evaluationlist_unit_user_simple: (classroom) => api.get(sessionStorage.getItem('backend')+'/evaluationlist_unit_user_simple/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),
	
	evaluationlist_unit_user: (classroom,unit,user) => api.get(sessionStorage.getItem('backend')+'/evaluationlist_unit_user/'+classroom+'/'+unit+'/'+user,{headers:{session:sessionStorage.getItem('session')}}),
	
	 
	
	evaluationlist_unit: () => api.get(sessionStorage.getItem('backend')+'/evaluationlist',{headers:{session:sessionStorage.getItem('session')}}),
	post_evaluationlist_unit: (data) => api.post(sessionStorage.getItem('backend')+'/evaluation/',data,{headers:{session:sessionStorage.getItem('session')}}),

	list_evaluationlist_unit: (clasroom,unit) => api.get(sessionStorage.getItem('backend')+'/evaluationlist_unit/'+clasroom+'/'+unit+'',{headers:{session:sessionStorage.getItem('session')}}),

	report_unit: (clasroom,unit,user) => api.get(sessionStorage.getItem('backend')+'/unitreport/'+clasroom+'/'+unit+'/'+user,{headers:{session:sessionStorage.getItem('session')}}),
	report_unit_xlsx: (clasroom,unit,user) => api.get(sessionStorage.getItem('backend')+'/unitreport/'+clasroom+'/'+unit+'/'+user+'?xlsx=1',{headers:{session:sessionStorage.getItem('session')}}),
	 
	//Para pegar todas as tarefas
	getAllTasks: () => api.get(sessionStorage.getItem('backend')+'/task/',{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar uma tarefa
	getTask: (id) => api.get(sessionStorage.getItem('backend')+'/task/'+id,{headers:{session:sessionStorage.getItem('session')}}),

	//Para pegar as tarefas de uma turma
	getTasksClassroom: (classroom) => api.get(sessionStorage.getItem('backend')+'/task/classroom/'+classroom,{headers:{session:sessionStorage.getItem('session')}}),

	//Para postar uma tarefa
	postTask: (data) => api.post(sessionStorage.getItem('backend')+'/task/',data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para editar uma tarefa
	putTask: (id,data) => api.put(sessionStorage.getItem('backend')+'/task/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

	//Para deletar uma tarefa
	deleteTask: (id) => api.delete(sessionStorage.getItem('backend')+'/task/'+id,{headers:{session:sessionStorage.getItem('session')}}), 

	getUnits: (id) => api.get(sessionStorage.getItem('backend') + '/unit/'+ id, { headers: { session: sessionStorage.getItem('session') } }),

	// Lista todos os tipos de tasks
	getAllTypeTasks: () => api.get(sessionStorage.getItem('backend') + '/task-types', { headers: { session: sessionStorage.getItem('session') } }),

	// Pega o tipo de task pelo id
	getTypeTask: (id) => api.get(sessionStorage.getItem('backend') + '/task-types/' + id, { headers: { session: sessionStorage.getItem('session') } }),

	// Atualiza um tipo de task
	updateTypeTask: (id, data) => api.put(sessionStorage.getItem('backend') + '/task-types/' + id, data, { headers: { session: sessionStorage.getItem('session') } }),

	// Insere um tipo de task
	createTypeTask: (data) => api.post(sessionStorage.getItem('backend') + '/task-types', data, { headers: { session: sessionStorage.getItem('session') } }),

	// Deleta um tipo de task
	deleteTypeTask: (id) => api.delete(sessionStorage.getItem('backend') + '/task-types/' + id, { headers: { session: sessionStorage.getItem('session') } }),

	// Lista todas as tarefas de um usuário
	getAllTaskUserSend: () => api.get(sessionStorage.getItem('backend') + '/task-user-send', {headers: { session: sessionStorage.getItem('session') }}),

	// Pega uma tarefa de um usuário
    getTaskUserSend: (id) => api.get(sessionStorage.getItem('backend') + '/task-user-send/' + id, { headers: { session: sessionStorage.getItem('session') }}),

	// Insere uma tarefa de um usuário
    postTaskUserSend: (data) => api.post(sessionStorage.getItem('backend') + '/task-user-send', data, { headers: { session: sessionStorage.getItem('session') }}),

	// Atualiza uma tarefa de um usuário
    putTaskUserSend: (id, data) => api.put(sessionStorage.getItem('backend') + '/task-user-send/' + id, data, { headers: { session: sessionStorage.getItem('session') }}),

	// Deleta uma tarefa de um usuário
    deleteTaskUserSend: (id) => api.delete(sessionStorage.getItem('backend') + '/task-user-send/' + id, { headers: { session: sessionStorage.getItem('session') }}),

	getTaskUserSendByUser: (userId) => api.get(sessionStorage.getItem('backend') + '/task-user-send/user/' + userId, {headers: { session: sessionStorage.getItem('session') }}),

	
	// Para listar todos os comentários
    getAllUserComments: (obj) => api.get(sessionStorage.getItem('backend')+'/user-comments' + obj,{headers:{session:sessionStorage.getItem('session')}}),

    // Para pegar um comentário específico
    getUserComment: (id) => api.get(sessionStorage.getItem('backend')+'/user-comments/'+id,{headers:{session:sessionStorage.getItem('session')}}),

    // Para criar um novo comentário
    postUserComment: (data) => api.post(sessionStorage.getItem('backend')+'/user-comments',data,{headers:{session:sessionStorage.getItem('session')}}),

    // Para atualizar um comentário
    putUserComment: (id,data) => api.put(sessionStorage.getItem('backend')+'/user-comments/'+id,data,{headers:{session:sessionStorage.getItem('session')}}),

    // Para deletar um comentário
    deleteUserComment: (id) => api.delete(sessionStorage.getItem('backend')+'/user-comments/'+id,{headers:{session:sessionStorage.getItem('session')}}),
	
	getUserTasksWithDetails: (userId) => api.get(sessionStorage.getItem('backend')+'/task-user-send/user/'+userId+'/details',{headers: {session: sessionStorage.getItem('session')}}),

	getTaskSubmissionsWithDetails: (taskId) => api.get(sessionStorage.getItem('backend')+'/task-user-send/task/'+taskId,{headers: {session: sessionStorage.getItem('session')}}),

	// Lista todos os templates disponíveis
	getTaskTemplates: () => api.get(sessionStorage.getItem('backend') + '/task-templates', {
		headers: { session: sessionStorage.getItem('session') }
	}),

	// Cria uma nova tarefa a partir de um template
	createTaskFromTemplate: (templateId, data) => api.post(sessionStorage.getItem('backend') + '/tasks/from-template/' + templateId, data, { headers: { session: sessionStorage.getItem('session') }}),

	// Salva uma tarefa como template (atualiza isTemplate)
	saveAsTemplate: (taskId) => api.put(sessionStorage.getItem('backend') + '/tasks/' + taskId, { isTemplate: 1 },{ headers: { session: sessionStorage.getItem('session') }}),

	// Remove o status de template de uma tarefa
	removeTemplate: (taskId) => api.put(sessionStorage.getItem('backend') + '/tasks/' + taskId, { isTemplate: 0 },{ headers: { session: sessionStorage.getItem('session') }}),

	getNewComments: (lastCheck) => api.get(
        sessionStorage.getItem('backend') + '/comments/new', 
        { 
            headers: { session: sessionStorage.getItem('session') },
            params: { lastCheck }
        }
    ),

    // Busca novas entregas desde o último check
    getNewDeliveries: (lastCheck) => api.get(
        sessionStorage.getItem('backend') + '/deliveries/new',
        { 
            headers: { session: sessionStorage.getItem('session') },
            params: { lastCheck }
        }
    ),

	loginGoogle: (data) => api.post(sessionStorage.getItem('backend')+'/auth/google', data, {
        headers: {
            session: sessionStorage.getItem('session')
        }
    }),
	

	updateProgressCompound: (data) => api.put(
		sessionStorage.getItem('backend') + '/progress/compound',
		data,
		{ headers: { session: sessionStorage.getItem('session') } }
	  ),
	  
	  // Para excluir um registro de progresso usando campos compostos
	  deleteProgressCompound: (data) => api.delete(
		sessionStorage.getItem('backend') + '/progress/compound',
		{
		  headers: { session: sessionStorage.getItem('session') },
		  data: data 
		}
	  ),
	  
	  // Para adicionar um novo registro de progresso
	  addProgressCompound: (data) => api.post(
		sessionStorage.getItem('backend') + '/progress/compound',
		data,
		{ headers: { session: sessionStorage.getItem('session') } }
	  ),
}

window.apis = apis;

export default apis;