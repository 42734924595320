import React, { Component } from 'react';
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import { ClipLoader } from 'react-spinners';
import { connect } from 'react-redux';
import api from '../Api';
import { Modal, Button } from "reactstrap";
import NotificationAlert from "react-notification-alert";

class Comment extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      load: false,
      open: false,
      openDialogRemove: false,
      comments: [],
      currentComment: null,
      commentToDelete: null,
      user: {
        id: this.props.user?.id || window.app.state.user.id,
        username: this.props.user?.username || window.app.state.user.username
      },
      classroom: Number(props.classroom),
      unit: props.unit ? Number(props.unit) : null,
      lesson: props.lesson ? Number(props.lesson) : null,
      permissions: Array.isArray(props.permission) ? props.permission : [],
      users: [],
      showUsersList: false,
      filteredUsers: [],
      mentionedUsers: [],
      mentionQuery: '',
      newComment: {
        user: Number(props.user?.id || window.app.state.user.id),
        username: props.user?.username || window.app.state.user.username,
        classroom: Number(props.classroom),
        unit: props.unit ? Number(props.unit) : null,
        lesson: props.lesson ? Number(props.lesson) : null,
        comment: '',
        comment_edit: null,
        resp_comment: null,
        isPrivate: false,
        mentionedUsers: []
      },
      sortOrder: 'desc',
      filterType: 'all',
      isInstructor: false
    };
    
    // Inicializa as permissões
    this.updatePermissionsAndInstructor(this.props);
    window.comment = this;
  }

  updatePermissionsAndInstructor = (props) => {
    const permissions = Array.isArray(props.permission) ? props.permission : [];
    
    const isInstructor = permissions.indexOf(35) !== -1;
    const canComment = true;
    const canEditComment = permissions.indexOf(45) !== -1;
    const canDeleteComment = permissions.indexOf(44) !== -1;

    this.setState({ 
      permissions,
      isInstructor,
      canComment,
      canEditComment,
      canDeleteComment
    });

    console.log('Permissions updated:', {
      permissions,
      isInstructor,
      canComment: true,
      canEditComment: this.canEditComment,
      canDelete: this.canDeleteComment
    });
  }

  componentDidMount() {
    this.updatePermissionsAndInstructor(this.props);
  }

  componentDidUpdate(prevProps) {
    // Verifica mudanças nas props relevantes
    if (prevProps.permission !== this.props.permission ||
        prevProps.user !== this.props.user) {
      this.updatePermissionsAndInstructor(this.props);
      
      // Atualiza informações do usuário se necessário
      if (prevProps.user !== this.props.user && this.props.user) {
        this.setState({
          user: {
            id: this.props.user.id,
            username: this.props.user.username
          },
          newComment: {
            ...this.state.newComment,
            user: Number(this.props.user.id),
            username: this.props.user.username
          }
        });
      }
    }
  }

  buildQuery = (params) => {
    let query = "";
    
    // Sempre incluir classroom
    query += `?classroom=${params.classroom}`;
    
    // Se tiver unit, adiciona ao query
    if (params.unit) {
      query += `&unit=${params.unit}`;
      
      // Se tiver lesson, só adiciona se também tiver unit
      if (params.lesson) {
        query += `&lesson=${params.lesson}`;
      }
    }
    
    return query;
  }

  loadComments = () => {
    this.setState({ load: true });

    const queryParams = {
      classroom: this.state.classroom
    };

    if (this.state.unit) {
      queryParams.unit = this.state.unit;
      
      if (this.state.lesson) {
        queryParams.lesson = this.state.lesson;
      }
    }

    const query = this.buildQuery(queryParams);
    console.log("Query construída:", query);

    api.getAllUserComments(query)
      .then(response => {
        console.log("Comentários recebidos:", response.data);
        this.setState({
          comments: response.data,
          load: false,
          open: true
        });
      })
      .catch(error => {
        console.error('Erro ao carregar comentários:', error);
        this.setState({ 
          load: false,
          open: true
        });
      });
  };

  open = (obj) => {
    console.log('Dados recebidos no open:', obj); 
    console.log("Props recebidas:", this.props);
    console.log("Permissões brutas:", this.props.permission);
    console.log(window.app.state.user.id);
    console.log(this.state.user);


    const newState = {
      open: true,
      load: false,
      classroom: Number(obj.classroom),
      unit: obj.unit ? Number(obj.unit) : null,
      lesson: obj.unit && obj.lesson ? Number(obj.lesson) : null,
      newComment: {
        ...this.state.newComment,
        user: this.state.user.id,
        username: this.state.user.username,
        classroom: Number(obj.classroom),
        unit: obj.unit ? Number(obj.unit) : null,
        lesson: obj.unit && obj.lesson ? Number(obj.lesson) : null,
      }
    };

    this.setState(newState, () => {
      this.loadComments();
    });
  };

  close = () => {
    this.setState({ 
      open: false
    });
  };

  formatCommentWithMentions = (text) => {
    if (!text) return '';
    
    // Divide o texto em partes usando regex para identificar menções (@palavra)
    const parts = text.split(/(@\w+)/g);
    
    return parts.map((part, index) => {
      // Se a parte começa com @, é uma menção
      if (part.startsWith('@')) {
        return (
          <span key={index} style={{ color: '#007bff' }}>
            {part}
          </span>
        );
      }
      return part;
    });
  };

  montComments = (comment, sum, isResp = false) => {
    const { isInstructor } = this.state;
    const isOwner = Number(comment.user) === Number(this.state.user.id);
    const isEditing = this.state.currentComment?.id === comment.id;
    const isReplying = this.state.currentComment?.resp_comment === comment.id;
    const isHidden = Number(comment.isHidden) === 1;
    const MAX_INDENT = 3;
    const indentLevel = Math.min(sum, MAX_INDENT);

    return(
      <>
        <div 
            key={comment.id} 
            id={`comment-${comment.id}`}
            data-comment-id={comment.id}
            className={`media mb-4 ${isHidden ? 'comment-hidden' : ''}`}
            style={{
                marginLeft: `${indentLevel * 50}px`,
                transition: 'all 0.3s ease',
                padding: '15px',
                border: isHidden ? '1px dashed #5e72e4' : 'none', 
                borderRadius: '8px',
                backgroundColor: isHidden ? 'rgba(94, 114, 228, 0.1)' : 'transparent',
                position: 'relative',
                width: '450px',
                wordBreak: 'break-all'
            }}
        >
            {isHidden && isInstructor && (
                <div 
                    style={{
                        position: 'absolute',
                        top: '-10px',
                        right: '10px',
                        backgroundColor: '#5e72e4', 
                        color: '#fff', 
                        padding: '2px 8px',
                        borderRadius: '12px',
                        fontSize: '0.75rem',
                        fontWeight: 'bold',
                        display: 'flex',
                        alignItems: 'center',
                        gap: '4px'
                    }}
                >
                    <i className="fas fa-eye-slash"></i>
                    Comentário Oculto
                </div>
            )}

            <img 
                src={`https://ui-avatars.com/api/?name=${comment.username || 'User'}&background=random`}
                className="mr-3 rounded-circle"
                alt="avatar"
                style={{ 
                    width: '40px', 
                    height: '40px',
                    opacity: isHidden ? '0.7' : '1'
                }}
            />
            <div className="media-body">
              <div className="d-flex justify-content-between align-items-center">
                <h6 className="mt-0 mb-1">{comment.username}</h6>
                <small className="text-muted">
                  {new Date(comment.createDate).toLocaleString()}
                </small>
              </div>
              
              {isEditing ? (
                <div className="form-group mb-2">
                  <textarea
                    className="form-control"
                    name="comment"
                    value={this.state.currentComment.comment}
                    onChange={this.handleChange}
                    rows="3"
                  />
                  <div className="mt-2">
                    <button 
                      className="btn btn-sm btn-primary mr-2"
                      onClick={this.handleSubmit}
                    >
                      Salvar
                    </button>
                    <button 
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.setState({ currentComment: null })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              ) : (
                <>
                  <p className="mb-1">
                    {this.formatCommentWithMentions(comment.comment)}
                  </p>
                  {comment.comment_edit && (
                    <small className="text-muted d-block mb-2">
                      <em>Editado: {comment.comment_edit}</em>
                    </small>
                  )}
                </>
              )}

              {!isEditing && (
                <div className="comment-actions">
                  {(isOwner && this.state.canEditComment) && (
                    <button 
                      className="btn btn-sm btn-link mr-2 p-0"
                      onClick={() => this.handleEdit(comment)}
                    >
                      <i className="fas fa-edit mr-1"></i>Editar
                    </button>
                  )}
                  <button 
                    className="btn btn-sm btn-link mr-2 p-0"
                    onClick={() => this.handleReply(comment)}
                  >
                    <i className="fas fa-reply mr-1"></i>Responder
                  </button>
                  {(isOwner && this.state.canDeleteComment) && (
                    <button 
                      className="btn btn-sm btn-link text-danger p-0"
                      onClick={() => this.handleDelete(comment.id)}
                    >
                      <i className="fas fa-trash-alt mr-1"></i>Excluir
                    </button>
                  )}
                  
                  {isInstructor && (
                    <button
                      className={`btn btn-sm ${comment.isHidden ? 'btn-warning' : 'btn-outline-warning'} ml-2`}
                      onClick={() => this.toggleCommentVisibility(comment.id)}
                      title={comment.isHidden ? "Mostrar comentário" : "Ocultar comentário"}
                    >
                      <i className={`fas fa-${comment.isHidden ? 'eye' : 'eye-slash'}`}></i>
                      {Boolean(comment.isHidden) && (
                        <span className="ml-2" style={{ 
                            color: '#FFFFFF',  
                            fontSize: '0.75rem',
                            fontWeight: '500',
                            opacity: '0.8'
                        }}>
                            OCULTADO
                        </span>
                      )}
                    </button>
                  )}
                </div>
              )}

              {isReplying && (
                <div className="form-group mb-2 mt-3" style={{marginLeft: '20px'}}>
                  <textarea
                    className="form-control"
                    name="comment"
                    value={this.state.currentComment.comment}
                    onChange={this.handleChange}
                    rows="3"
                    placeholder="Digite sua resposta..."
                  />
                  <div className="mt-2">
                    <button 
                      className="btn btn-sm btn-primary mr-2"
                      onClick={this.handleSubmit}
                    >
                      {this.state.currentComment.resp_comment ? 'Responder' : 'Salvar'}
                    </button>
                    <button 
                      className="btn btn-sm btn-secondary"
                      onClick={() => this.setState({ currentComment: null })}
                    >
                      Cancelar
                    </button>
                  </div>
                </div>
              )}
            </div>
        </div>

        {isResp == false && 
          <React.Fragment>
            {comment.resps.map(resp => {
              return(
                <>
                  {this.montComments(resp, sum + 1)}
                </>
              )
            })}
          </React.Fragment>
        }

        {isInstructor && isHidden && (
          <div 
              className="mt-1 mb-3"
              style={{
                  marginLeft: `${(indentLevel * 50) + 50}px`,
                  color: '#5e72e4',
                  fontSize: '0.85rem',
                  display: 'flex',
                  alignItems: 'center',
                  gap: '6px'
              }}
          >
              <i className="fas fa-info-circle"></i>
              Este comentário e suas respostas estão ocultos para os alunos
          </div>
        )}
      </>
    )
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    
    // Se for o campo de comentário, use handleCommentChange
    if (name === 'comment') {
      this.handleCommentChange(e);
      return;
    }

    // Para outros campos, mantenha a lógica original
    if (this.state.currentComment) {
      this.setState(prevState => ({
        currentComment: {
          ...prevState.currentComment,
          [name]: value
        }
      }));
    } else {
      this.setState(prevState => ({
        newComment: {
          ...prevState.newComment,
          [name]: value
        }
      }));
    }
  };

  handleCommentChange = (e) => {
    const { value } = e.target;
    const lastChar = value[value.length - 1];
    
    // Verifica se existem menções no texto atual
    const mentions = value.match(/@\w+/g) || [];
    const mentionedUsernames = mentions.map(mention => mention.substring(1)); // Remove o @ do início
    
    // Filtra mentionedUsers para manter apenas os que ainda estão mencionados no texto
    const updatedMentionedUsers = this.state.newComment.mentionedUsers.filter(userId => {
        const user = this.state.users.find(u => u.id === userId);
        return user && mentionedUsernames.includes(user.username);
    });

    if (this.state.currentComment) {
        this.setState(prevState => ({
            currentComment: {
                ...prevState.currentComment,
                comment: value,
                mentionedUsers: updatedMentionedUsers
            }
        }));
    } else {
        this.setState(prevState => ({
            newComment: {
                ...prevState.newComment,
                comment: value,
                mentionedUsers: updatedMentionedUsers,
                isPrivate: updatedMentionedUsers.length > 0
            }
        }));
    }

    // Lógica para mostrar a lista de usuários
    if (lastChar === '@') {
        this.setState({ 
            showUsersList: true,
            mentionQuery: ''
        });
        this.loadClassroomUsers();
    } else if (this.state.showUsersList) {
        const mentionQuery = value.split('@').pop();
        this.setState({ 
            mentionQuery,
            filteredUsers: this.state.users.filter(user => 
                user.username.toLowerCase().includes(mentionQuery.toLowerCase())
            )
        });
    }
  };

  showNotification = (type, message) => {
    let options = {
      place: "tr",
      message: (
        <div>
          <div className="alert-text">
            <span className="alert-title" data-notify="title">
              {type === 'success' ? 'Sucesso!' : 'Erro!'}
            </span>
            <span data-notify="message">{message}</span>
          </div>
        </div>
      ),
      type: type,
      icon: type === 'success' ? "ni ni-check-bold" : "ni ni-fat-remove",
      autoDismiss: 7
    };
    this.refs.notificationAlert.notificationAlert(options);
  };

  handleSubmit = () => {
    const { currentComment, newComment } = this.state;
    const commentData = currentComment || newComment;
    const isEditing = currentComment?.id !== undefined;

    const validData = {
        ...commentData,
        user: Number(this.state.user.id),
        username: this.state.user.username,
        classroom: Number(this.state.classroom) || null,
        unit: Number(this.state.unit) || null,
        lesson: Number(this.state.lesson) || null
    };

    console.log("Dados sendo enviados:", validData);

    if (isEditing) {
        api.putUserComment(currentComment.id, validData)
            .then((response) => {
                console.log("Resposta do servidor:", response.data);
                this.showNotification('success', 'Comentário editado com sucesso!');
                this.setState({
                    currentComment: null,
                    newComment: {
                        ...newComment,
                        comment: ''
                    }
                });
                this.open({
                    classroom: Number(this.state.classroom),
                    unit: Number(this.state.unit),
                    lesson: Number(this.state.lesson)
                });
            })
            .catch(error => {
                console.error('Erro ao editar comentário:', error);
                this.showNotification('danger', 'Erro ao editar comentário');
            });
    } else {
        api.postUserComment(validData)
            .then((response) => {
                console.log("Resposta do servidor:", response.data);
                this.showNotification('success', 'Comentário publicado com sucesso!');
                this.setState({
                    currentComment: null,
                    newComment: {
                        ...newComment,
                        comment: ''
                    }
                });
                this.open({
                    classroom: Number(this.state.classroom),
                    unit: Number(this.state.unit),
                    lesson: Number(this.state.lesson)
                });
            })
            .catch(error => {
                console.error('Erro ao postar comentário:', error);
                this.showNotification('danger', 'Erro ao publicar comentário');
            });
    }
  };

  handleDelete = (commentId) => {
    this.setState({
      openDialogRemove: true,
      commentToDelete: commentId
    });
  };

  remove = () => {
    api.deleteUserComment(this.state.commentToDelete)
      .then(() => {
        this.showNotification('success', 'Comentário removido com sucesso!');
        this.setState({ 
          openDialogRemove: false,
          commentToDelete: null
        });
        
        this.open({
          classroom: this.state.classroom,
          unit: this.state.unit,
          lesson: this.state.lesson
        });
      })
      .catch(error => {
        console.error('Erro ao deletar comentário:', error);
        this.showNotification('danger', 'Erro ao remover comentário');
        this.setState({ 
          openDialogRemove: false,
          commentToDelete: null
        });
      });
  };

  handleReply = (comment) => {
    this.setState({
      currentComment: {
        user: this.state.user,
        classroom: this.state.classroom,
        unit: this.state.unit,
        lesson: this.state.lesson,
        comment: '',
        resp_comment: comment.id
      }
    });
  };

  handleEdit = (comment) => {
    this.setState({
      currentComment: {
        id: comment.id,
        user: this.state.user,
        classroom: this.state.classroom,
        unit: this.state.unit,
        lesson: this.state.lesson,
        comment: comment.comment,
        comment_edit: comment.comment
      }
    });
  };

  loadClassroomUsers = () => {
    api.searchUserClassRoom(this.state.classroom)
      .then(response => {
        this.setState({ 
          users: response.data,
          filteredUsers: response.data
        });
      })
      .catch(error => {
        console.error('Erro ao carregar usuários:', error);
      });
  };

  handleMentionSelect = (user) => {
    const { newComment } = this.state;
    const commentParts = newComment.comment.split('@');
    const updatedComment = commentParts.slice(0, -1).join('@') + `@${user.username} `;
    
    this.setState(prevState => ({
      newComment: {
        ...prevState.newComment,
        comment: updatedComment,
        isPrivate: true,
        mentionedUsers: [...prevState.newComment.mentionedUsers, user.id]
      },
      showUsersList: false
    }));
  };

  sortComments = (comments) => {
    return [...comments].sort((a, b) => {
      if (this.state.sortOrder === 'desc') {
        return new Date(b.createDate) - new Date(a.createDate);
      }
      return new Date(a.createDate) - new Date(b.createDate);
    });
  };

  filterComments = (comments) => {
    const { filterType, isInstructor } = this.state;
    console.log("Filtrando comentários:", { filterType, isInstructor, totalComments: comments.length });


    let filteredComments = comments;
    if (!isInstructor) {
        filteredComments = comments.filter(comment => !comment.isHidden);
        console.log("Comentários após filtro de visibilidade:", filteredComments.length);
    }

    switch (filterType) {
        case 'mine':
            return filteredComments.filter(comment => 
                Number(comment.user) === Number(this.state.user.id)
            );
        case 'private':
            return filteredComments.filter(comment => 
                comment.isPrivate === true
            );
        case 'hidden':
            return isInstructor ? comments.filter(comment => comment.isHidden) : [];
        default:
            return filteredComments;
    }
  };

  toggleCommentVisibility = async (commentId) => {
    try {
        const findCommentInTree = (comments, targetId) => {
            for (let comment of comments) {
                if (comment.id === targetId) {
                    return comment;
                }
                if (comment.resps && comment.resps.length > 0) {
                    const found = findCommentInTree(comment.resps, targetId);
                    if (found) return found;
                }
            }
            return null;
        };

        const comment = findCommentInTree(this.state.comments, commentId);
        console.log("Comentário antes da atualização:", comment);
        
        // Função recursiva para atualizar comentários e suas respostas
        const updateCommentAndReplies = async (commentData, newHiddenState) => {
            // Atualiza o comentário atual
            const updateData = {
                id: commentData.id,
                user: commentData.user,
                classroom: commentData.classroom,
                unit: commentData.unit,
                lesson: commentData.lesson,
                comment: commentData.comment,
                isHidden: newHiddenState,
                resp_comment: commentData.resp_comment
            };
            
            console.log(`Atualizando comentário ${commentData.id} para isHidden: ${newHiddenState}`);
            await api.putUserComment(commentData.id, updateData);
            
            // Se o comentário tem respostas, atualiza cada uma delas recursivamente
            if (commentData.resps && commentData.resps.length > 0) {
                for (const reply of commentData.resps) {
                    await updateCommentAndReplies(reply, newHiddenState);
                }
            }
        };

        // Inicia o processo de atualização com o novo estado
        const newHiddenState = !comment.isHidden;
        await updateCommentAndReplies(comment, newHiddenState);
        
        // Recarrega os comentários para atualizar a visualização
        await this.loadComments();
        
        // Mostra notificação de sucesso
        this.showNotification(
            'success', 
            `Comentário ${newHiddenState ? 'ocultado' : 'mostrado'} com sucesso!`
        );
        
    } catch (error) {
        console.error('Erro ao atualizar visibilidade:', error.response?.data || error);
        this.showNotification('danger', 'Erro ao atualizar visibilidade do comentário');
    }
  };

  render() {
    const { currentComment, newComment, load, open, comments, openDialogRemove, showUsersList, filteredUsers, isInstructor } = this.state;

    const renderText = (text) => {
        return text.split(' ').map((word, index) => {
            if (word.startsWith('@')) {
                return <span key={index}><span className="text-primary">{word}</span> </span>;
            }
            return <span key={index}>{word} </span>;
        });
    };

    return (
      <>
        <div className="rna-wrapper">
          <NotificationAlert ref="notificationAlert" />
        </div>

        <SlidingPane
          className="some-custom-class"
          overlayClassName="some-custom-overlay-class"
          isOpen={open}
          title="Comentários"
          subtitle="Discussão da unidade"
          onRequestClose={this.close}
          width="700px"
          zIndex={99999}
        >
          <div className="p-3">
            {load ? (
              <div className="d-flex justify-content-center align-items-center" style={{height:'100%'}}>
                <ClipLoader color="#000" size={30} />
              </div>
            ) : (
              <>
                <div className="d-flex justify-content-between align-items-center mb-4">
                  <div className="btn-group">
                    <button
                      className={`btn btn-sm ${this.state.filterType === 'all' ? 'btn-primary' : 'btn-outline-primary'}`}
                      onClick={() => this.setState({ filterType: 'all' })}
                    >
                      Todos
                    </button>
                    <button
                      className={`btn btn-sm ${this.state.filterType === 'mine' ? 'btn-primary' : 'btn-outline-primary'}`}
                      onClick={() => this.setState({ filterType: 'mine' })}
                    >
                      Meus Comentários
                    </button>
                    <button
                      className={`btn btn-sm ${this.state.filterType === 'private' ? 'btn-primary' : 'btn-outline-primary'}`}
                      onClick={() => this.setState({ filterType: 'private' })}
                    >
                      Privados
                    </button>
                    {isInstructor && (
                      <button
                        className={`btn btn-sm ${this.state.filterType === 'hidden' ? 'btn-primary' : 'btn-outline-primary'}`}
                        onClick={() => this.setState({ filterType: 'hidden' })}
                      >
                        <i className="fas fa-eye-slash mr-1"></i>
                        Ocultos
                      </button>
                    )}
                  </div>
                  
                  <button
                    className="btn btn-sm btn-outline-secondary"
                    onClick={() => this.setState(prev => ({
                      sortOrder: prev.sortOrder === 'desc' ? 'asc' : 'desc'
                    }))}
                  >
                    <i className={`fas fa-sort-${this.state.sortOrder === 'desc' ? 'down' : 'up'}`}></i>
                    {' '}
                    {this.state.sortOrder === 'desc' ? 'Mais recentes' : 'Mais antigos'}
                  </button>
                </div>

                <div className="mb-4">
                  <div className="form-group position-relative">
                    <label>Novo comentário:</label>
                    <textarea
                      className="form-control"
                      name="comment"
                      value={newComment.comment}
                      onChange={this.handleCommentChange}
                      rows="4"
                      placeholder="Digite seu comentário... Use @ para mencionar usuários"
                    />
                    {showUsersList && (
                      <div className="mention-list position-absolute bg-white shadow p-2" style={{
                        maxHeight: '200px',
                        overflowY: 'auto',
                        width: '100%',
                        zIndex: 1000,
                        border: '1px solid #ddd',
                        borderRadius: '4px'
                      }}>
                        {filteredUsers.map(user => (
                          <div 
                            key={user.id}
                            className="mention-item p-2 cursor-pointer hover:bg-gray-100"
                            onClick={() => this.handleMentionSelect(user)}
                          >
                            {user.username}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {newComment.mentionedUsers.length > 0 && (
                    <small className="text-muted">
                      Este será um comentário privado para os usuários mencionados
                    </small>
                  )}
                  <div className="d-flex justify-content-end mt-2">
                    <button 
                      className="btn btn-primary"
                      onClick={this.handleSubmit}
                    >
                      Publicar
                    </button>
                  </div>
                </div>

                <div className="comments-section">
                  {this.filterComments(this.sortComments(comments)).map(comment => (
                    <React.Fragment key={comment.id}>
                      {this.montComments(comment, 0, true)}
                      {this.sortComments(comment.resps).map(resp => (
                        <React.Fragment key={resp.id}>
                          {this.montComments(resp, 1)}
                        </React.Fragment>
                      ))}
                    </React.Fragment>
                  ))}
                </div>
              </>
            )}
          </div>
        </SlidingPane>

        {openDialogRemove && (
          <Modal 
            className="modal-dialog-centered modal-danger modal-comment-remove" 
            contentClassName="bg-gradient-danger" 
            isOpen={openDialogRemove}
            zIndex={1052}
          >
            <div className="modal-header">
              <h6 className="modal-title" id="modal-title-notification">
                Sua atenção é requerida.
              </h6>
              <button 
                aria-label="Close" 
                className="close" 
                data-dismiss="modal" 
                type="button" 
                onClick={() => this.setState({ openDialogRemove: false })}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="py-3 text-center">
                <i className="fas fa-exclamation-triangle fa-x3" style={{ fontSize: '45px' }}></i>
                <h4 className="heading mt-4">VOCÊ DEVE LER ISTO!</h4>
                <p>Se você prosseguir, este comentário será removido permanentemente.</p>
              </div>
            </div>
            <div className="modal-footer">
              <Button 
                className="btn-white" 
                color="default" 
                type="button" 
                onClick={this.remove}
              >
                Ok, Remova
              </Button>
              <Button 
                className="text-white ml-auto" 
                color="link" 
                data-dismiss="modal" 
                type="button" 
                onClick={() => this.setState({ openDialogRemove: false })}
              >
                Cancelar e fechar
              </Button>
            </div>
          </Modal>
        )}
      </>
    );
  }
}

export default connect(state => ({
  languages: state.language,
  translate: state.translate,
  accesslevel: state.accesslevel,
  permission: state.permission,
  user: state.user,
  unit: state.unit,
  useronline: state.useronline
}))(Comment);