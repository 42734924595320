import React from "react";
import { Button, Card, CardBody, Table, Alert, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Modal } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import api from "../../../../Api";
import moment, { max } from 'moment';
import 'moment/locale/pt-br';
import ClipLoader from "react-spinners/ClipLoader";

moment.locale('pt-br');

class ModalUserTasks extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            userTasks: [],
            loading: true,
            error: null,
            selectedTask: null, 
            deleting: false,
            selectedFileName: null,
            isPaneOpen: props.isOpen,
            sortOrder: 'desc',
            showPreviewModal: false,
            previewFile: null,
            previewLoading: false,
            previewError: null,
            searchTerm: '',
        };
        
        this.getUserTasks = this.getUserTasks.bind(this);
        this.editTask = this.editTask.bind(this);
        this.deleteTask = this.deleteTask.bind(this);
        this.handleEditChange = this.handleEditChange.bind(this);
        this.handleEditSubmit = this.handleEditSubmit.bind(this);
    }

    componentDidMount() {
        this.getUserTasks();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isPaneOpen: this.props.isOpen });
            if (this.props.isOpen) {
                this.getUserTasks();
            }
        }
    }

    getUserTasks = () => {
        this.setState({ loading: true });
        
        api.getTaskUserSendByUser(this.props.user.id)
            .then(response => {
                console.log("Resposta da API:", response.data);
                const userTasks = response.data.map(task => {
                    const taskDetails = this.props.tasks.find(t => t.id === task.task);
                    return {
                        ...task,
                        name: taskDetails ? taskDetails.name : 'Nome não encontrado',
                        startDate: taskDetails ? taskDetails.dataInicial : '',
                        endDate: taskDetails ? taskDetails.dataFinal : ''
                    };
                });
                
                console.log("Tarefas processadas:", userTasks);
                this.setState({ 
                    userTasks: userTasks || [],
                    loading: false 
                });
            })
            .catch(error => {
                console.error("Erro ao buscar tarefas:", error);
                this.setState({ 
                    error: error.response?.data?.msg || "Erro ao carregar tarefas",
                    loading: false,
                    userTasks: []
                });
            });
    }

    editTask(task) {
        this.setState({ selectedTask: task });
    }

    deleteTask(taskId) {
        if (this.state.deleting) return; 
        this.setState({ deleting: true });
        console.log(`deleteTask - taskId: ${taskId}`); 
        api.deleteTaskUserSend(taskId).then(() => {
            console.log('deleteTask - Tarefa deletada com sucesso'); 
            window.swal.fire('Sucesso!', 'A tarefa foi deletada com sucesso.', 'success');
            this.setState(prevState => ({
                userTasks: prevState.userTasks.filter(task => task.id !== taskId),
                deleting: false,
            }));
        }).catch((error) => {
            console.error("Erro ao deletar tarefa:", error);
            this.setState({ error: error.response.data.msg, deleting: false });
        });
    }

    handleEditChange(e) {
        const { name, value, files } = e.target;
        if (name === 'file' && files.length > 0) {
            this.setState({ selectedFileName: files[0].name });
        }
        this.setState(prevState => ({
            selectedTask: {
                ...prevState.selectedTask,
                [name]: value
            }
        }));
    }

    handleEditSubmit(e) {
        e.preventDefault();
        const { selectedTask } = this.state;

        const taskToSubmit = { ...selectedTask };
        // if (taskToSubmit.points === undefined || taskToSubmit.points === null) {
        //     delete taskToSubmit.points;
        // }

        console.log(taskToSubmit)
        api.putTaskUserSend(selectedTask.id, selectedTask).then(() => {
            this.setState({ selectedTask: null });
            this.getUserTasks();
        }).catch((error) => {
            console.error("Erro ao editar tarefa:", error);
            this.setState({ error: error.response.data.msg });
        });
    }

    undeliverTask = (taskId) => {
        const task = this.state.userTasks.find(t => t.id === taskId);
        
        // Verifica se a tarefa já tem nota
        if (task.points !== null && task.points !== undefined && task.points !== '') {
            window.swal.fire('Aviso', 'Não é possível desfazer a entrega de uma tarefa já corrigida', 'warning');
            return;
        }

        window.swal.fire({
            title: 'Confirmar',
            text: 'Deseja realmente Desfazer a entrega desta tarefa?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Sim, Desfazer a entrega',
            cancelButtonText: 'Cancelar',
            confirmButtonColor: '#d33',
            cancelButtonColor: '#3085d6',
        }).then((result) => {
            if (result.isConfirmed) {
                this.setState({ loading: true });
                
                api.deleteTaskUserSend(taskId)
                    .then(() => {
                        window.swal.fire('Sucesso!', 'Entrega desfeita com sucesso.', 'success');
                        this.getUserTasks();
                        if (this.props.onTaskUndelivered) {
                            this.props.onTaskUndelivered();
                        }
                        this.handlePaneClose();
                    })
                    .catch(error => {
                        console.error("Erro ao Desfazer a entrega tarefa:", error);
                        window.swal.fire('Erro!', 'Não foi possível Desfazer Entrega a tarefa.', 'error');
                    })
                    .finally(() => {
                        this.setState({ loading: false });
                    });
            }
        });
    }

    handlePaneClose = () => {
        this.setState({ isPaneOpen: false });
        setTimeout(() => {
            this.props.onClose();
        }, 300);
    };

    isValidUrl(string) {
        try {
            new URL(string);
            return true;
        } catch (_) {
            return false;
        }
    }

    handlePreviewFile = async (fileUrl, fileName) => {
        this.setState({ 
            showPreviewModal: true, 
            previewLoading: true,
            previewError: null,
            previewFile: {
                url: fileUrl,
                name: fileName,
                type: this.getFileType(fileName)
            }
        });

        try {
            const baseUrl = 'http://localhost:3041';
            const fullUrl = `${baseUrl}${fileUrl}`;
            const response = await fetch(fullUrl);
            
            if (!response.ok) throw new Error('Erro ao carregar arquivo');
            
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            
            this.setState(prevState => ({
                previewFile: {
                    ...prevState.previewFile,
                    objectUrl
                },
                previewLoading: false
            }));
        } catch (error) {
            this.setState({ 
                previewError: 'Erro ao carregar pré-visualização',
                previewLoading: false
            });
        }
    };

    getFileType = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        const imageTypes = ['jpg', 'jpeg', 'png', 'gif'];
        const pdfTypes = ['pdf'];
        const textTypes = ['txt', 'js', 'jsx', 'html', 'css', 'json'];
        
        if (imageTypes.includes(extension)) return 'image';
        if (pdfTypes.includes(extension)) return 'pdf';
        if (textTypes.includes(extension)) return 'text';
        return 'other';
    };

    closePreviewModal = () => {
        this.setState({ 
            showPreviewModal: false,
            previewFile: null
        });
    };

    renderPreviewContent = () => {
        const { previewFile, previewLoading, previewError } = this.state;

        if (previewLoading) {
            return (
                <div className="text-center p-5">
                    <ClipLoader color={'#5e72e4'} loading={true} />
                </div>
            );
        }

        if (previewError) {
            return (
                <div className="text-center p-5 text-danger">
                    <i className="fas fa-exclamation-circle fa-3x mb-3"></i>
                    <p>{previewError}</p>
                </div>
            );
        }

        if (!previewFile?.objectUrl) return null;

        switch (previewFile.type) {
            case 'image':
                return (
                    <img 
                        src={previewFile.objectUrl} 
                        alt={previewFile.name}
                        className="img-fluid"
                        style={{ maxHeight: '70vh' }}
                    />
                );
            case 'pdf':
                return (
                    <iframe
                        src={previewFile.objectUrl}
                        title={previewFile.name}
                        width="100%"
                        height="70vh"
                        style={{ border: 'none' }}
                    />
                );
            case 'text':
                return (
                    <div 
                        className="bg-secondary p-4 rounded"
                        style={{ 
                            maxHeight: '70vh',
                            overflowY: 'auto',
                            fontFamily: 'monospace'
                        }}
                    >
                        <pre className="m-0">
                            <code>{previewFile.content}</code>
                        </pre>
                    </div>
                );
            default:
                return (
                    <div className="text-center p-5">
                        <i className="fas fa-file fa-3x mb-3"></i>
                        <p>Pré-visualização não disponível para este tipo de arquivo</p>
                    </div>
                );
        }
    };

    downloadFile = (fileUrl, fileName) => {
        if (this.props.isTeacher) {
            this.handlePreviewFile(fileUrl, fileName);
        } else {
            const baseUrl = 'http://localhost:3041';
            const fullUrl = `${baseUrl}${fileUrl}`;
            window.open(fullUrl, '_blank');
        }
    };

    handleSortChange = (e) => {
        const sortOrder = e.target.value;
        this.setState(prevState => ({
            sortOrder,
            userTasks: [...prevState.userTasks].sort((a, b) => {
                if (sortOrder === 'asc') {
                    return new Date(a.sendDate) - new Date(b.sendDate);
                }
                return new Date(b.sendDate) - new Date(a.sendDate);
            })
        }));
    };

    handleSearch = (e) => {
        this.setState({ searchTerm: e.target.value });
    };

    getFilteredTasks = () => {
        const { userTasks } = this.state;
        const searchTerm = this.state.searchTerm.toLowerCase().trim();

        if (!searchTerm) return userTasks;

        return userTasks.filter(task => {
            const taskName = task.name.toLowerCase();
            return taskName.includes(searchTerm);
        });
    };

    render() {
        const { translate, tasks } = this.props;
        const { loading, error, isPaneOpen, sortOrder, searchTerm } = this.state;
        const filteredTasks = this.getFilteredTasks();

        return (
            <>
                    <Card className="bg-secondary border-0 mb-0">
                        <CardBody className="px-lg-5 py-lg-5">
                            {error && <Alert color="danger">{error}</Alert>}
                            
                            {!loading && (
                                <>
                                    <FormGroup className="mb-3">
                                        <InputGroup className="input-group-alternative shadow-sm">
                                            <InputGroupAddon addonType="prepend">
                                                <InputGroupText>
                                                    <i className="fas fa-search" />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input
                                                placeholder="Buscar tarefa..."
                                                type="text"
                                                value={searchTerm}
                                                onChange={this.handleSearch}
                                            />
                                        </InputGroup>
                                    </FormGroup>

                                    {filteredTasks.length > 0 && (
                                        <FormGroup className="mb-4">
                                            <InputGroup className="input-group-alternative shadow-sm">
                                                <InputGroupAddon addonType="prepend">
                                                    <InputGroupText className="bg-primary text-white">
                                                        <i className="ni ni-sort-70" />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input
                                                    type="select"
                                                    value={sortOrder}
                                                    onChange={this.handleSortChange}
                                                    className="form-control-alternative custom-select"
                                                    style={{
                                                        height: '45px',
                                                        appearance: 'none',
                                                        WebkitAppearance: 'none',
                                                        MozAppearance: 'none',
                                                        backgroundImage: 'url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns=\'http://www.w3.org/2000/svg\' viewBox=\'0 0 24 24\' fill=\'none\' stroke=\'currentColor\' stroke-width=\'2\' stroke-linecap=\'round\' stroke-linejoin=\'round\'%3e%3cpolyline points=\'6 9 12 15 18 9\'%3e%3c/polyline%3e%3c/svg%3e")',
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundPosition: 'right 1rem center',
                                                        backgroundSize: '1em',
                                                        paddingRight: '3rem',
                                                        cursor: 'pointer',
                                                        fontWeight: '500',
                                                        fontSize: '0.875rem',
                                                        color: '#525f7f'
                                                    }}
                                                >
                                                    <option value="desc">
                                                        {translate?.mais_recentes || "Mais recentes primeiro"}
                                                    </option>
                                                    <option value="asc">
                                                        {translate?.mais_antigas || "Mais antigas primeiro"}
                                                    </option>
                                                </Input>
                                            </InputGroup>
                                        </FormGroup>
                                    )}
                                </>
                            )}
                            
                            {loading ? (
                                <center><ClipLoader color={'#000'} loading={true} /></center>
                            ) : filteredTasks.length === 0 ? (
                                <center>
                                    {searchTerm 
                                        ? "Nenhuma tarefa encontrada com esse termo" 
                                        : translate?.nenhuma_tarefa || "Nenhuma tarefa entregue"}
                                </center>
                            ) : (
                                <div className="tasks-column">
                                    {filteredTasks.map((userTask, index) => {
                                        const taskDetails = this.props.tasks.find(t => t.id === userTask.task);
                                        if (!taskDetails) return null;

                                        return (
                                            <div className="task-card mb-4" key={index}>
                                                <div className="card border-0 shadow-lg border-left border-success"
                                                    style={{ borderLeftWidth: '4px' }}>
                                                    <div className="card-body">
                                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                                            <h5 className="card-title mb-0">{taskDetails.name}</h5>
                                                            <span className={`badge ${
                                                                userTask.status === 'ATRASADO' ? 'badge-warning' : 'badge-success'
                                                            }`}>
                                                                {userTask.status}
                                                            </span>
                                                        </div>

                                                        <div className="mb-3">
                                                            <div className="small text-muted">
                                                                <i className="ni ni-calendar-grid-58 mr-2"></i>
                                                                Entregue em: {moment(userTask.sendDate).format('DD/MM/YYYY HH:mm')}
                                                            </div>
                                                        </div>

                                                        {userTask.points && (
                                                            <div className="mb-3">
                                                                <div className="small text-muted">
                                                                    <i className="fas fa-star mr-2"></i>
                                                                    Nota: {userTask.points}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {userTask.recuse_obs && (
                                                            <div className="mb-3">
                                                                <div className="small text-danger">
                                                                    <i className="fas fa-exclamation-circle mr-2"></i>
                                                                    <strong>Observação do professor:</strong> {userTask.recuse_obs}
                                                                </div>
                                                            </div>
                                                        )}

                                                        {userTask.obs && (
                                                            <div className="delivery-obs mb-3">
                                                                <p className="text-muted mb-0">
                                                                    <i className="fas fa-comment-alt mr-2"></i>
                                                                    {userTask.obs.length > 50 ? (
                                                                        <>
                                                                            {userTask.obs.substring(0, 50)}...
                                                                            <button
                                                                                className="btn btn-link btn-sm p-0 ml-1"
                                                                                onClick={() => this.openObservationModal(userTask.obs)}
                                                                            >
                                                                                {translate?.ver_mais || "Ver mais"}
                                                                            </button>
                                                                        </>
                                                                    ) : userTask.obs}
                                                                </p>
                                                            </div>
                                                        )}

                                                        {userTask.link && (
                                                            <div className="delivery-link mb-3">
                                                                <p className="text-muted mb-0">
                                                                    <i className="fas fa-link mr-2"></i>
                                                                    <a href={userTask.link} target="_blank" rel="noopener noreferrer" title={userTask.link}>
                                                                        {userTask.link.length > 40 
                                                                            ? `${userTask.link.substring(0, 40)}...` 
                                                                            : userTask.link}
                                                                    </a>
                                                                </p>
                                                            </div>
                                                        )}

                                                        <div className="mt-3 d-flex justify-content-between">
                                                            {userTask.file && (
                                                                <button
                                                                    className="btn btn-sm btn-primary"
                                                                    onClick={() => this.downloadFile(userTask.file, `entrega_${taskDetails.name}`)}
                                                                >
                                                                    <i className="fas fa-download mr-2"></i>
                                                                    {translate?.baixar || "Baixar Arquivo"}
                                                                </button>
                                                            )}
                                                            
                                                            {(!userTask.points && userTask.points !== 0) && (
                                                                <button
                                                                    className="btn btn-sm btn-danger"
                                                                    onClick={() => this.undeliverTask(userTask.id)}
                                                                >
                                                                    <i className="fas fa-undo mr-2"></i>
                                                                    {"Desfazer Entrega"}
                                                                </button>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })}
                                </div>
                            )}
                        </CardBody>
                    </Card>

                {/* Modal Primario */}
                <Modal
                    isOpen={this.state.showPreviewModal}
                    toggle={this.closePreviewModal}
                    size="lg"
                    className="modal-dialog-centered"
                >
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <i className="fas fa-file mr-2"></i>
                            {this.state.previewFile?.name}
                        </h5>
                        <button
                            type="button"
                            className="close"
                            onClick={this.closePreviewModal}
                        >
                            <span>&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.renderPreviewContent()}
                    </div>
                    <div className="modal-footer">
                        <Button
                            color="primary"
                            onClick={() => {
                                const baseUrl = 'http://localhost:3041';
                                const fullUrl = `${baseUrl}${this.state.previewFile.url}`;
                                window.open(fullUrl, '_blank');
                            }}
                        >
                            <i className="fas fa-download mr-2"></i>
                            Baixar Arquivo
                        </Button>
                        <Button
                            color="secondary"
                            onClick={this.closePreviewModal}
                        >
                            Fechar
                        </Button>
                    </div>
                </Modal>
            </>
        );
    }
}

export default ModalUserTasks;