import React from "react";
import { Card, CardBody, Alert, Input, Button } from "reactstrap";
import SlidingPane from "react-sliding-pane";
import "react-sliding-pane/dist/react-sliding-pane.css";
import api from "../../../../Api.js";
import moment from 'moment';
import 'moment/locale/pt-br';
import ClipLoader from "react-spinners/ClipLoader";

moment.locale('pt-br');

class ModalTaskDeliveries extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            deliveries: [],
            loading: true,
            error: null,
            users: {},
            editingPoints: {},
            editingMode: {},
            editingRecuseObs: {},
            searchTerm: '',
            isPaneOpen: props.isOpen,
        };
    }

    componentDidMount() {
        this.getDeliveries();
    }

    componentDidUpdate(prevProps) {
        if (prevProps.isOpen !== this.props.isOpen) {
            this.setState({ isPaneOpen: this.props.isOpen });
        }
    }

    handlePaneClose = () => {
        this.setState({ isPaneOpen: false });
        setTimeout(() => {
            this.props.onClose();
        }, 300);
    };

    getDeliveries = () => {
        api.getTaskSubmissionsWithDetails(this.props.task.id)
            .then(response => {
                const taskDeliveries = response.data;
                
                const usersData = {};
                taskDeliveries.forEach(delivery => {
                    usersData[delivery.user] = { 
                        name: delivery.username || "Usuário não encontrado" 
                    };
                });
                
                console.log("Entregas processadas:", taskDeliveries);
                this.setState({ 
                    deliveries: taskDeliveries,
                    users: usersData,
                    loading: false 
                });
            })
            .catch(error => {
                console.error("Erro ao buscar entregas:", error);
                this.setState({ 
                    error: error.response?.data?.msg || "Erro ao carregar entregas",
                    loading: false 
                });
            });
    }

    downloadFile = (fileUrl, fileName) => {
        const baseUrl = 'http://localhost:3041';
    const fullUrl = `${baseUrl}${fileUrl}`;
    window.open(fullUrl, '_blank');
    }

    handlePointsChange = (deliveryId, value) => {
        this.setState(prevState => ({
            editingPoints: {
                ...prevState.editingPoints,
                [deliveryId]: value
            }
        }));
    }

    toggleEditMode = (deliveryId) => {
        this.setState(prevState => ({
            editingMode: {
                ...prevState.editingMode,
                [deliveryId]: !prevState.editingMode[deliveryId]
            },
            editingPoints: {
                ...prevState.editingPoints,
                [deliveryId]: prevState.editingMode[deliveryId] ? undefined : this.state.deliveries.find(d => d.id === deliveryId)?.points || ''
            }
        }));
    }

    handleRecuseObsChange = (deliveryId, value) => {
        this.setState(prevState => ({
            editingRecuseObs: {
                ...prevState.editingRecuseObs,
                [deliveryId]: value
            }
        }));
    };

    savePoints = (delivery) => {
        const points = Number(this.state.editingPoints[delivery.id]);
        if (isNaN(points)) return;

        this.setState({ loading: true });

        let newStatus = delivery.status;
        if (points !== null && points !== undefined && points !== '') {
            newStatus = delivery.status === 'ATRASADO' ? 'ATRASADO' : 'resolvido';
        } else {
            newStatus = delivery.status === 'ATRASADO' ? 'ATRASADO' : 'pendente';
        }

        const updateData = {
            id: delivery.id,
            user: delivery.user,
            points: points,
            status: newStatus,
            file: delivery.file,
            obs: delivery.obs,
            recuse_obs: this.state.editingRecuseObs[delivery.id] || '',
            sendDate: delivery.sendDate,
            taskId: delivery.taskId
        };

        console.log('Dados sendo enviados:', updateData);

        api.putTaskUserSend(delivery.id, updateData)
            .then(() => {
                this.getDeliveries();
                window.swal.fire('Sucesso!', 'Nota e status atualizados com sucesso!', 'success');
            })
            .catch(error => {
                console.error('Erro:', error);
                window.swal.fire('Erro!', 'Não foi possível salvar as alterações.', 'error');
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    }

    filterDeliveries = () => {
        const { deliveries, users, searchTerm } = this.state;
        if (!searchTerm) return deliveries;
        
        return deliveries.filter(delivery => {
            const userName = users[delivery.user]?.name || '';
            return userName.toLowerCase().includes(searchTerm.toLowerCase());
        });
    }

    handleSearch = (event) => {
        this.setState({ searchTerm: event.target.value });
    }

    render() {
        const { loading, error, users, editingPoints, editingMode, editingRecuseObs, searchTerm, deliveries } = this.state;
        const { translate, task } = this.props;
        const filteredDeliveries = this.filterDeliveries();

        const totalDeliveries = deliveries.length;
        const gradedDeliveries = deliveries.filter(d => d.points !== null && d.points !== undefined && d.points !== '').length;
        const pendingGrades = totalDeliveries - gradedDeliveries;

        return (
            <Card className="bg-secondary border-0 mb-0">
                <CardBody className="px-lg-5 py-lg-5">
                    {error && <Alert color="danger">{error}</Alert>}
                    
                    {!loading && (
                        <div className="delivery-stats mb-4">
                            <div className="row text-center">
                                <div className="col-4">
                                    <div className="card bg-gradient-primary border-0">
                                        <div className="card-body py-3">
                                            <h2 className="text-white mb-0">{totalDeliveries}</h2>
                                            <p className="text-white mb-0">
                                                <i className="fas fa-file-alt mr-2"></i>
                                                Entregas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card bg-gradient-success border-0">
                                        <div className="card-body py-3">
                                            <h2 className="text-white mb-0">{gradedDeliveries}</h2>
                                            <p className="text-white mb-0">
                                                <i className="fas fa-check-circle mr-2"></i>
                                                Corrigidas
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-4">
                                    <div className="card bg-gradient-warning border-0">
                                        <div className="card-body py-3">
                                            <h2 className="text-white mb-0">{pendingGrades}</h2>
                                            <p className="text-white mb-0">
                                                <i className="fas fa-clock mr-2"></i>
                                                Pendentes
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    <div className="mb-4">
                        <Input
                            type="text"
                            placeholder={translate?.pesquisar_usuario || "Pesquisar por usuário..."}
                            value={searchTerm}
                            onChange={this.handleSearch}
                            className="form-control-alternative"
                        />
                    </div>
                    
                    {loading ? (
                        <center><ClipLoader color={'#000'} loading={true} /></center>
                    ) : filteredDeliveries.length === 0 ? (
                        <center>{searchTerm ? (translate?.nenhum_resultado || "Nenhum resultado encontrado") : (translate?.nenhuma_entrega || "Nenhuma entrega encontrada")}</center>
                    ) : (
                        <div className="deliveries-grid">
                            {filteredDeliveries.map((delivery, index) => (
                                <Card key={index} className="delivery-card mb-4 shadow-sm">
                                    <CardBody>
                                        <div className="d-flex justify-content-between align-items-center mb-3">
                                            <h5 className="mb-0">
                                                <i className="fas fa-user-circle mr-2"></i>
                                                {users[delivery.user]?.name || "Carregando..."}
                                            </h5>
                                            <div>
                                                <span className={`badge mr-2 ${delivery.status === 'ATRASADO' ? 'badge-warning' : 'badge-success'}`}>
                                                    {delivery.status}
                                                </span>
                                                {(!delivery.points && delivery.points !== 0) && (
                                                    <span className="badge badge-warning">
                                                        {translate?.aguardando_correcao || "Aguardando correção"}
                                                    </span>
                                                )}
                                            </div>
                                        </div>

                                        <div className="d-flex mb-3">
                                            <div className="mr-4">
                                                <div className="small text-muted">
                                                    <i className="ni ni-calendar-grid-58 mr-2"></i>
                                                    Início: {moment(task.dataInicial).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            </div>
                                            <div className="mr-4">
                                                <div className="small text-muted">
                                                    <i className="ni ni-calendar-grid-58 mr-2"></i>
                                                    Fim: {moment(task.dataFinal).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="small text-muted">
                                                    <i className="fas fa-clock mr-2"></i>
                                                    Entregue: {moment(delivery.sendDate).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                            </div>
                                        </div>

                                        <div className="delivery-info mb-3">
                                            
                                            <div className="info-item mt-2">
                                                <i className="fas fa-star mr-2"></i>
                                                <div className="d-flex align-items-center">
                                                    {!editingMode[delivery.id] ? (
                                                        <>
                                                            <span className="text-muted mr-2">
                                                                Nota: {(!delivery.points && delivery.points !== 0) ? '-' : delivery.points}
                                                            </span>
                                                            {delivery.recuse_obs && (
                                                                <span className="text-danger ml-2">
                                                                    <i className="fas fa-exclamation-circle mr-1"></i>
                                                                    Observação de recusa: {delivery.recuse_obs}
                                                                </span>
                                                            )}
                                                            <Button
                                                                color="info"
                                                                size="sm"
                                                                onClick={() => this.toggleEditMode(delivery.id)}
                                                            >
                                                                <i className="fas fa-edit"></i>
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <Input
                                                                type="number"
                                                                min="0"
                                                                max="10"
                                                                step="0.1"
                                                                className="form-control-sm mr-2"
                                                                style={{ width: '80px' }}
                                                                value={editingPoints[delivery.id] !== undefined ? 
                                                                    editingPoints[delivery.id] : 
                                                                    ''}
                                                                onChange={(e) => this.handlePointsChange(delivery.id, e.target.value)}
                                                                placeholder="Nota"
                                                            />
                                                            <Input
                                                                type="textarea"
                                                                className="form-control-sm mr-2"
                                                                style={{ width: '200px' }}
                                                                value={editingRecuseObs[delivery.id] || ''}
                                                                onChange={(e) => this.handleRecuseObsChange(delivery.id, e.target.value)}
                                                                placeholder="Observação de recusa (opcional)"
                                                            />
                                                            <Button
                                                                color="success"
                                                                size="sm"
                                                                className="mr-1"
                                                                onClick={() => this.savePoints(delivery)}
                                                                disabled={editingPoints[delivery.id] === undefined}
                                                            >
                                                                <i className="fas fa-save"></i>
                                                            </Button>
                                                            <Button
                                                                color="danger"
                                                                size="sm"
                                                                onClick={() => this.toggleEditMode(delivery.id)}
                                                            >
                                                                <i className="fas fa-times"></i>
                                                            </Button>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </div>

                                        {delivery.obs && (
                                            <div className="delivery-obs mb-3">
                                                <p className="text-muted mb-0">
                                                    <i className="fas fa-comment-alt mr-2"></i>
                                                    {delivery.obs}
                                                </p>
                                            </div>
                                        )}

                                        {delivery.link && (
                                            <div className="delivery-link mb-3">
                                                <p className="text-muted mb-0">
                                                    <i className="fas fa-link mr-2"></i>
                                                    <a href={delivery.link} target="_blank" rel="noopener noreferrer" title={delivery.link}>
                                                        {delivery.link.length > 40 
                                                            ? `${delivery.link.substring(0, 40)}...` 
                                                            : delivery.link}
                                                    </a>
                                                </p>
                                            </div>
                                        )}

                                        {delivery.file && (
                                            <button
                                                className="btn btn-primary btn-sm"
                                                onClick={() => this.downloadFile(delivery.file, `entrega_${users[delivery.user]?.name}`)}
                                            >
                                                <i className="fas fa-download mr-2"></i>
                                                {translate?.baixar || "Baixar Arquivo"}
                                            </button>
                                        )}
                                    </CardBody>
                                </Card>
                            ))}
                        </div>
                    )}
                </CardBody>
            </Card>
        );
    }
}

export default ModalTaskDeliveries;
