import React from 'react';
import SlidingPane from "react-sliding-pane";
import ClipLoader from "react-spinners/ClipLoader";

export const SharedSlidingPane = ({ 
    isOpen, 
    title, 
    onClose, 
    width = "750px",
    from = "right",
    loading = false,
    children 
}) => {
    return (
        <SlidingPane
            className="some-custom-class"
            overlayClassName="some-custom-overlay-class"
            isOpen={isOpen}
            title={title}
            width={width}
            from={from}
            onRequestClose={onClose}
        >
            <div className="p-4">
                {loading ? (
                    <center>
                        <ClipLoader color={'#000'} loading={true} />
                    </center>
                ) : (
                    children
                )}
            </div>
        </SlidingPane>
    );
};